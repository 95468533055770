import { AfterViewInit, Component, ElementRef, HostListener, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { debounceTime } from 'rxjs/operators';

import * as fromGraphQl from './graphql';

import { ApolloService } from '@nexato/nx-core-module';
import { Subscription } from 'rxjs';
import { Order } from 'src/app/order-module/shared/entities/order/order';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { LicenseService } from '../../shared/services/license.service';

@Component({
  selector: "app-orders-search",
  templateUrl: "./orders-search.component.html",
  host: {
    class: 'min-w-[18.75rem]'
  },
  styleUrls: ["./orders-search.component.scss"]
})
export class OrdersSearchComponent implements AfterViewInit {

  public overLayVisible: boolean = false;
  public loading: boolean = false;
  public lastSearchTerms: string[];

  public searchQuery: QueryRef<fromGraphQl.SearchQueryResponse>;
  public searchQuerySubscription: Subscription;
  @ViewChildren('resultItem') resultItems!: QueryList<ElementRef>;
  @ViewChild('globalSearch') globalSearchInput!: ElementRef;

  @HostListener('keydown.arrowdown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    console.log(event);
    if (event.key === 'ArrowDown' && document.activeElement === this.globalSearchInput.nativeElement) {
      event.preventDefault();
      // Führen Sie hier die gewünschte Aktion aus
      console.log('ArrowDown gedrückt und Fokus auf dem Eingabefeld');
    }
  }


  searchTerm: FormControl = new FormControl();
  @ViewChild("searchField", { static: true })
  searchField: any;
  noOrderFound: boolean;
  noCustomerFound: boolean;
  noResorceFound: boolean;
  noUnitFound: boolean;
  orderSearchResult: Order[] = [];
  customerSearchResult: any[] = [];
  resourcesSearchResult: Resource[] = [];
  unitsSearchResult: any[] = [];

  constructor(
    private apollo: Apollo,
    private apolloService: ApolloService,
    private router: Router,
    public licenseService: LicenseService,
    private renderer: Renderer2
  ) {
    this.loadSearchTermsFromLocalStorage();
    this.searchTerm.valueChanges.pipe(debounceTime(400)).pipe(
      // filter((data: string) => data.length > 0)
    ).subscribe(data => {
      if (data.length >= 1) {
        this.loading = true;
        this.noOrderFound = false;
        this.noCustomerFound = false;
        this.noResorceFound = false;
        this.noUnitFound = false;
        let subscription = this.apollo.query<fromGraphQl.SearchQueryResponse>({
          query: fromGraphQl.SEARCH_QUERY,
          variables: {
            search: data,
            number: 0,
            size: 5,
            sortPropertyOrder: 'number',
            sortPropertyResource: 'name',
            sortPropertyContact: 'id',
            sortDirection: 'asc',
          },
          errorPolicy: 'all'
        }).subscribe(result => {
          console.log(result);
          this.addSerachTerm(data);
          this.loading = false;
          if (result?.data?.orders?.content?.length > 0) {
            this.orderSearchResult = Order.createOrders(result.data?.orders?.content);
          } else {
            this.orderSearchResult = [];
            this.noOrderFound = true;
          }
          if (result?.data?.resources?.content?.length > 0) {
            this.resourcesSearchResult = Resource.createResources(result.data?.resources?.content);
          }
          else {
            this.resourcesSearchResult = [];
            this.noResorceFound = true;
          }
          if (result?.data?.customers?.content?.length > 0) {
            this.customerSearchResult = Contact.createContacts(result.data.customers?.content);
          }
          else {
            this.customerSearchResult = [];
            this.noCustomerFound = true;
          }
          subscription.unsubscribe();
        });
      } else {
        this.noCustomerFound = false;
        this.noOrderFound = false;
        this.noResorceFound = false;
        this.orderSearchResult = [];
        this.customerSearchResult = [];
        this.resourcesSearchResult = [];
      }
    });
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'keydown', (event: KeyboardEvent) => {
      const isMac = navigator.userAgent.includes('Macintosh');

      if ((isMac && event.metaKey && event.key === 'k') ||
          (!isMac && event.ctrlKey && event.key === 'k')) {
        event.preventDefault();
            this.overLayVisible = true;
      }
    });
  }


  openOrder(order: any) {
    this.cleanUp();
    this.overLayVisible = false;
    let uri = "orders/order/" + order?.id;
    this.redirect(uri);
  }

  openCustomer(customer: any) {
    this.cleanUp();
    this.overLayVisible = false;
    let uri = "customers/customer/" + customer?.id;
    this.redirect(uri);
  }

  openResource(resource: any) {
    this.cleanUp();
    this.overLayVisible = false;
    let uri = "resources/resource/" + resource?.id;
    this.redirect(uri);
  }

  redirect(uri: any) {
    this.router.navigateByUrl('./', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri])});
  }

  // openUnit(unit: Unit){
  //   this.cleanUp();
  //   this.router.navigate(["./tourPlanning/units/detail/" + unit.id]);
  // }

  cleanUp() {
    this.searchTerm.setValue("");
    this.orderSearchResult = [];
    this.customerSearchResult = [];
    this.resourcesSearchResult = [];
    this.unitsSearchResult = [];
    setTimeout(()=>{
      this.searchField?.nativeElement?.blur();
    })
  }

  reset() {
    this.noOrderFound = false;
    this.noCustomerFound = false;
    this.noResorceFound = false;
    this.noUnitFound = false;
    this.orderSearchResult = [];
    this.customerSearchResult = [];
    this.resourcesSearchResult = [];
    this.searchTerm.setValue("");
  }

  addSerachTerm(term: string) {
    console.log(this.lastSearchTerms);
    if (!this.lastSearchTerms) {
      this.lastSearchTerms = [];
    }
    if (this.lastSearchTerms.length >= 5) {
      this.lastSearchTerms.pop();
      console.log(this.lastSearchTerms);
    }
    this.lastSearchTerms.unshift(term);
    console.log(this.lastSearchTerms);
    localStorage.setItem('lastSearchTerms', JSON.stringify(this.lastSearchTerms));
  }

  loadSearchTermsFromLocalStorage() {
    const storedTerms = localStorage.getItem('lastSearchTerms');
    if (storedTerms) {
      this.lastSearchTerms = JSON.parse(storedTerms);
    } else {
      this.lastSearchTerms = [];
    }
  }

  search(term: string) {
    this.searchTerm.setValue(term);
  }

  getPlatformKeyIcon(){
    return navigator.platform.indexOf('Mac') > -1 ? '⌘' : 'ctrl';
  }

  onKeyDown(event: KeyboardEvent, item: any, type: string): void {
    console.log('?')
    const elements = this.resultItems.toArray();
    switch(event.key) {
      case 'Enter':
        event.preventDefault();
        switch (type) {
          case 'search':
            this.search(item);
            this.focusInputAtEnd();
          break;
          case 'order':
            this.openOrder(item);
          break;
          case 'resource':
            this.openResource(item);
          break;
          case 'customer':
            this.openCustomer(item);
          break;
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        let nextIndex = 0;
        if(item){
          nextIndex = elements.findIndex(el => el.nativeElement === event.target) + 1;
        }
        if(nextIndex > elements.length - 1){
          this.focusInputAtEnd();
        } else {
          elements[nextIndex].nativeElement.focus();
        }

        break;
      case 'ArrowUp':
        event.preventDefault();
        let prevIndex = elements.length;
        if(item){
          prevIndex = elements.findIndex(el => el.nativeElement === event.target) - 1;
        }
        if(prevIndex == -1) {
          this.focusInputAtEnd();
        } else {
          elements[prevIndex].nativeElement.focus();
        }
        break;
    }
  }

  onArrowDown($event){
    const elements = this.resultItems.toArray();
    if(elements?.length > 0){
      elements[0].nativeElement.focus();
    }
  }

  onArrowUp($event){
    const elements = this.resultItems.toArray();
    if(elements?.length > 0){
      elements[elements.length - 1].nativeElement.focus();
    }
  }

  focusInputAtEnd(): void {
    const input = this.searchField.nativeElement;
    input.focus();
    // Small timeout to ensure focus is complete
    setTimeout(() => {
      const length = input.value.length;
      input.setSelectionRange(length, length);
    }, 0);
  }
}
