import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';

import { Protocol } from 'src/app/rent-module/shared/entities/protocol/protocol';

@Component({
  selector: 'app-protocol-view, [app-protocol-view]',
  templateUrl: './protocol-view.component.html',
  styleUrls: ['./protocol-view.component.scss']
})
export class ProtocolViewComponent {

  referenceFormTemplate: ControlTemplate;
  referenceBodyControlTemplates: ControlTemplate[];
  referenceCompletionControlTemplates: ControlTemplate[];

  _protocol: Protocol;
  @Input() set protocol(protocol: Protocol) {
    if (protocol) {
      this._protocol = protocol;
      this.referenceFormTemplate = protocol.formControlTemplate;
      this.referenceBodyControlTemplates = protocol.formControlTemplate?.body[0].controls;
      this.referenceCompletionControlTemplates = protocol.formControlTemplate?.completion?.controls;
    }
  }

  constructor(
  ) {
  }

}
