<p-multiSelect
  *ngIf="multi"
  [showClear]="false"
  [ngModel]="selectedLocations"
  [filter]="false"
  [showToggleAll]="false"
  [options]="locationOptions"
  [styleClass] = "'location-selector ' + style"
  [panelStyleClass]="'location-selector'"
  [selectedItemsLabel]="'Niederlassung: {0} gewählt'"
  (onChange)="locationChange($event)"
  optionLabel="name"
  [maxSelectedLabels]="1"
  [placeholder]="placeholder">
  <ng-template let-item pTemplate="item">
    {{item?.label ? item?.label : item?.name}}
  </ng-template>
  <ng-template let-items pTemplate="selectedItems">
    <span *ngIf="!items || items?.length === 0" class="placeholder">
      {{ placeholder }}
    </span>
    <ng-container *ngIf="items?.length === 1">
      {{ items?.[0].label ? items?.[0].label : items?.[0].name }}
    </ng-container>
    <ng-container *ngIf="items?.length > 1">
      Niederlassung: {{items?.length}} gewählt
    </ng-container>
  </ng-template>
</p-multiSelect>

<p-dropdown
  [styleClass] = "'location-selector-single ' + style"
  [ngModel]="selectedLocation"
  [options]="locationOptions"
  optionLabel="name"
  (onChange)="locationChange($event)"
  [placeholder]="placeholder"
  *ngIf="!multi">
  <ng-template let-item pTemplate="selectedItem">
    {{item?.label ? item?.label : item?.name}}
  </ng-template>
  <ng-template let-item pTemplate="item">
    {{item?.label ? item?.label : item?.name}}
  </ng-template>
</p-dropdown>
