import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-refuelInput-control-value-view, [app-refuelInput-control-value-view]',
  templateUrl: './refuelInput-control-value-view.component.html',
  styleUrls: ['./refuelInput-control-value-view.component.scss']
})
export class RefuelInputControlValueViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

  public showSuffix(): boolean {
    return this.controlTemplate?.settings?.showSuffix && this.controlTemplate?.settings?.suffix;
  }
}
