import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { EditDialogComponent, EditDialogInstance, LoadingService } from "@nexato/nx-core-module";
import { ContactPerson, ContactPersonInput } from "../../shared/entities/contactPerson/contactPerson";
import { ContactPersonService } from "../../shared/services/contactPerson/contactPerson.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
  selector: 'app-contact-person-dialog',
  templateUrl: './contact-person-dialog.component.html',
  styleUrls: ['./contact-person-dialog.component.scss']
})
export class ContactPersonDialogComponent implements EditDialogInstance {

  public id: string;
  public contactpersonFormGroup: FormGroup;
  public contactperson: ContactPerson;
  public isNew = true;
  public isName = false;
  public contactId: string;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public loadingService: LoadingService,
    private contactPersonService: ContactPersonService,
  ) {
    if (this.config.data?.contactperson) {
      this.contactperson = this.config?.data?.contactperson;
    }
    this.contactId = this.config?.data?.contactId;
    this.InitializeForm();
   }

  InitializeForm(): void {
    this.contactpersonFormGroup = new FormGroup({});
    this.contactpersonFormGroup.addControl('mobileNumber', new FormControl());
    this.contactpersonFormGroup.addControl('phoneNumber', new FormControl());
    this.contactpersonFormGroup.addControl('email', new FormControl());

      if (this.contactperson) {
        this.isNew = false;
        if (this.contactperson?.name) {
          this.isName = true;
          this.contactpersonFormGroup.addControl('name', new FormControl(undefined, Validators.required));
        } else {
          this.contactpersonFormGroup.addControl('firstName', new FormControl(undefined, Validators.required));
          this.contactpersonFormGroup.addControl('lastName', new FormControl(undefined, Validators.required));
        }
        this.contactpersonFormGroup.patchValue(this.contactperson);
      }
      else {
        this.contactpersonFormGroup.addControl('firstName', new FormControl(undefined, Validators.required));
        this.contactpersonFormGroup.addControl('lastName', new FormControl(undefined, Validators.required));
      }

      this.contactpersonFormGroup?.updateValueAndValidity();
     }


  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    this.loadingService.setLoading(true);
    let personInput = this.prepareContactPersonModel();
    if(this.contactperson){
      this.contactPersonService.updateContactPerson(personInput, (result: any) => {
        this.ref.close(result?.data?.updateContactPerson?.id);
      });
    } else {
      this.contactPersonService.createContactPerson(personInput, (result: any) => {
        this.ref.close(result?.data?.createContactPerson?.id);
      });
    }
  }

  prepareContactPersonModel(): ContactPersonInput {
    const formModel = this.contactpersonFormGroup.value;
    const contactpersonModel = new ContactPersonInput();
    if (this.contactperson) {
      contactpersonModel.id = this.contactperson.id;
    }
    contactpersonModel.firstName =formModel?.firstName;
    contactpersonModel.lastName = formModel?.lastName;
    contactpersonModel.name = formModel?.name;
    contactpersonModel.mobileNumber = formModel?.mobileNumber;
    contactpersonModel.phoneNumber = formModel?.phoneNumber;
    contactpersonModel.email = formModel?.email;
    contactpersonModel.contact = this.contactId;

    return contactpersonModel;
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.contactpersonFormGroup;
  }


}
