import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpandService } from '@nexato/nx-core-module';
import { Subscription } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { ToursPlannerTasksListComponent } from '../../components/tours-planner-tasks-list/tours-planner-tasks-list.component';
import { ToursPlannerToursComponent } from '../../components/tours-planner-tours/tours-planner-tours.component';
import { TourPlannerService } from '../../shared/services/tour/tour-planner.service';

@Component({
  selector: "app-tours-planner-page",
  templateUrl: "./tours-planner-page.component.html",
  styleUrls: ["./tours-planner-page.component.scss"]
})
export class ToursPlannerPageComponent implements OnInit, OnDestroy {

  public activeView: 'singleDay' | 'multiDay' = 'singleDay';
  private storageKey = 'ToursPlannerPageComponent';

  public isTourComponentExpanded = true;
  public isTourComponentExpandedSubscription: Subscription;

  @ViewChild('tasklist') public tasklist: ToursPlannerTasksListComponent;
  @ViewChild('tourList') public tourList: ToursPlannerToursComponent;

  // new <----
  public selectedDate = new Date();
  public selectedLocation: LocationEntity;
  // new ---->
  

  constructor(
    public expandService: ExpandService,
    public tourPlannerService: TourPlannerService
  ) {
    // this.isTourComponentExpandedSubscription = tourPlannerService.isTourComponentExpanded$
    //   .subscribe( isTourExpanded => this.isTourComponentExpanded = isTourExpanded);
    let activeViewFromSessionStorage = sessionStorage.getItem(this.storageKey + '.activeView') as 'singleDay' | 'multiDay' || 'singleDay';
    if(activeViewFromSessionStorage === 'multiDay'){
      this.switchToMultiDayView();
    } else {
      this.switchToSingleDayView();
    }
    let isTourComponentExpandedFromSessionStorage = sessionStorage.getItem(this.storageKey + '.isTourComponentExpanded') === 'true';
    if(isTourComponentExpandedFromSessionStorage){
      this.expandTourComponent();
    } else {
      this.reduceTourComponent();
    }
  }

  ngOnInit() {
    this.expandService.setExpandState(true); // move to layout component and watch route
  }

  ngOnDestroy(): void {
    this.expandService.setExpandState(false); // move to layout component and watch route
    this.isTourComponentExpandedSubscription?.unsubscribe();
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
  }

  locationsChanged(locations: LocationEntity[]) {
    if(locations && locations.length > 0){
      this.selectedLocation = locations[0];
    } else {
      this.selectedLocation = undefined;
    }
  }

  switchToSingleDayView($event?: any) {
    $event?.preventDefault();
    this.activeView = 'singleDay';
    sessionStorage.setItem(this.storageKey+ '.activeView', this.activeView);
  }

  switchToMultiDayView($event?: any) {
    $event?.preventDefault();
    this.tourPlannerService?.reduceTourComponent()
    this.activeView = 'multiDay';
    sessionStorage.setItem(this.storageKey + '.activeView', this.activeView);
  }

  expandTourComponent() {
    this.isTourComponentExpanded = true;
    sessionStorage.setItem(this.storageKey + '.isTourComponentExpanded', 'true');
  }

  reduceTourComponent() {
    this.isTourComponentExpanded = false;
    sessionStorage.setItem(this.storageKey + '.isTourComponentExpanded', 'false');
  }

  onResizeEnd(event: any) {
    this.tasklist?.onResize();
  }

}

