<!-- <section class="w-30rem border-1 surface-border border-round-lg"> -->
  <div class="flex align-items-center justify-content-between">
    <div class="searchbar flex flex-row flex-grow bg-white rounded-nx-default">
      <span class="w-full text-base ml-1">
          <input type="text" pInputText placeholder="Suche Aufträge, Ressourcen, Kunden..." class="w-full  border-none shadow-none outline-none globalSearch px-2 py-1 overflow-hidden whitespace-nowrap text-ellipsis" (click)="overLayVisible = true">  
      </span>
      <span class="border-1 p-1 pt-[7px] text-[#9ca3af] surface-border border-round surface-100 mr-2 text-600 text-sm hidden md:block w-12">{{getPlatformKeyIcon()}} + k</span>
    </div>
  </div>
  
  <p-dialog
    [(visible)]="overLayVisible"
    [position]="'top'" 
    (onHide)="cleanUp()"
    [dismissableMask]="true"
    [modal]="true"
    [showHeader]="false"
    [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{'width': '50vw', 'max-width': '600px'}"
    contentStyleClass="noScrollbar rounded-md p-2"
    appendTo="body"
    (onShow)="searchField.focus();"
    (onHide)="overLayVisible = false;">
    <div class="p-input-icon-left w-full flex flex-col form mb-[12px]">
      <i class="iconTesting icon-search"></i>
      <input
        (keydown.arrowdown)="onArrowDown($event)"
        (keydown.arrowup)="onArrowUp($event)"
        #searchField [formControl]="searchTerm" type="text" pInputText placeholder="Suche Aufträge, Ressourcen, Kunden..." class="!pl-[30px] flex w-full p-inputtext"/> 
    </div>

    <!-- last searchTerms -->
    <div
      *ngIf="!searchTerm?.value">
      <div class="text-base font-medium mx-[8px]">Zuletzt</div>
      <ul class="list-none m-0 p-0 text-base text-[#848488] font-light leading-3">
        <ng-container *ngFor="let item of lastSearchTerms; let last = last">
          <li (keydown)="onKeyDown($event, item, 'search')" #resultItem tabindex="0" class="p-2 border-0 rounded-md hover:bg-[#f3f4f6] cursor-pointer hover:text-black result-item focus:bg-[#f3f4f6] focus-visible:bg-[#f3f4f6]" [ngClass]="{ 'mb-[2px]' : !last}" (click)="search(item)">{{item}}</li>
        </ng-container>
        <ng-container *ngIf="lastSearchTerms === undefined || lastSearchTerms?.length === 0">
          <li class="">Keine letzten Suchen gefunden.</li>
        </ng-container>
      </ul>
    </div>

    <div
      *ngIf="searchTerm?.value">
      <div class="text-base font-medium mx-[8px]">Aufträge ({{orderSearchResult?.length}})</div>
      <ul class="list-none m-0 p-0 text-base text-[#848488] font-light leading-3">
        <ng-container *ngFor="let item of orderSearchResult; let last = last">
          <li (keydown)="onKeyDown($event, item, 'order')"  #resultItem tabindex="0"
            class="focus:bg-[#f3f4f6] focus-visible:bg-[#f3f4f6] p-2 border-0 rounded-md hover:bg-[#f3f4f6] cursor-pointer hover:text-black result-item" [ngClass]="{ 'mb-[2px]' : !last}"
            (click)="openOrder(item)">{{ item?.number }} / {{item?.customer?.getFullName()}}</li>
        </ng-container >
        <ng-container *ngIf=noOrderFound>
          <li class="p-2 font-light leading-3">Keine Aufträge gefunden.</li>
        </ng-container>
      </ul>
    </div>

    <div
      *ngIf="searchTerm?.value">
      <div class="text-base font-medium mx-[8px]">Ressourcen ({{resourcesSearchResult?.length}})</div>
      <ul class="list-none m-0 p-0 text-base text-[#848488] font-light leading-3">
        <ng-container *ngFor="let item of resourcesSearchResult; let last = last">
          <li (keydown)="onKeyDown($event, item, 'resource')"  #resultItem tabindex="0"
          class="focus:bg-[#f3f4f6] focus-visible:bg-[#f3f4f6] p-2 border-0 rounded-md hover:bg-[#f3f4f6] cursor-pointer hover:text-black result-item" [ngClass]="{ 'mb-[2px]' : !last}"
            (click)="openResource(item)">{{ item?.number }} / {{ item?.name }}</li>
        </ng-container >
        <ng-container *ngIf=noResorceFound>
          <li class="p-2 font-light leading-3">Keine Ressourcen gefunden.</li>
        </ng-container>
      </ul>
    </div>

    <div
      *ngIf="searchTerm?.value">
      <div class="text-base font-medium mx-[8px]">Kunden ({{customerSearchResult?.length}})</div>
      <ul class="list-none m-0 p-0 text-base text-[#848488] font-light leading-3">
        <ng-container *ngFor="let item of customerSearchResult; let last = last">
          <li (keydown)="onKeyDown($event, item, 'customer')"  #resultItem tabindex="0"
          class="focus:bg-[#f3f4f6] focus-visible:bg-[#f3f4f6] p-2 border-0 rounded-md hover:bg-[#f3f4f6] cursor-pointer hover:text-black result-item" [ngClass]="{ 'mb-[2px]' : !last}"
            (click)="openCustomer(item)">{{ item?.number }} / {{item.getFullName()}}</li>
        </ng-container >
        <ng-container *ngIf=noCustomerFound>
          <li class="p-2 font-light leading-3">Keine Kunden gefunden.</li>
        </ng-container>
      </ul>
    </div>

    <div class="flex flex-row border-t pt-2 labels">
      <div class="flex flex-row ml-3">
        <kbd class="pr-1 label"><svg width="15" height="15" aria-label="Enter key" role="img"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"><path d="M12 3.53088v3c0 1-1 2-2 2H4M7 11.53088l-3-3 3-3"></path></g></svg></kbd>
        <div class="labelText">auswählen</div>
      </div>
      <div class="flex flex-row ml-3">
        <kbd class="label"><svg width="15" height="15" aria-label="Arrow down" role="img"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"><path d="M7.5 3.5v8M10.5 8.5l-3 3-3-3"></path></g></svg></kbd>
        <kbd class="pr-1 label"><svg width="15" height="15" aria-label="Arrow up" role="img"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"><path d="M7.5 11.5v-8M10.5 6.5l-3-3-3 3"></path></g></svg></kbd>
        <div class="labelText">navigieren</div>
      </div>
      <div class="flex flex-row ml-3">
        <kbd class="pr-1 label"><svg width="15" height="15" aria-label="Escape key" role="img"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"><path d="M13.6167 8.936c-.1065.3583-.6883.962-1.4875.962-.7993 0-1.653-.9165-1.653-2.1258v-.5678c0-1.2548.7896-2.1016 1.653-2.1016.8634 0 1.3601.4778 1.4875 1.0724M9 6c-.1352-.4735-.7506-.9219-1.46-.8972-.7092.0246-1.344.57-1.344 1.2166s.4198.8812 1.3445.9805C8.465 7.3992 8.968 7.9337 9 8.5c.032.5663-.454 1.398-1.4595 1.398C6.6593 9.898 6 9 5.963 8.4851m-1.4748.5368c-.2635.5941-.8099.876-1.5443.876s-1.7073-.6248-1.7073-2.204v-.4603c0-1.0416.721-2.131 1.7073-2.131.9864 0 1.6425 1.031 1.5443 2.2492h-2.956"></path></g></svg></kbd>
        <div class="labelText">schließen</div>
      </div>
    </div>


</p-dialog>
<!-- </section> -->
