import { Injectable } from '@angular/core';
import { AutoCompleteResult, AutoCompleteResultSource } from '../../../shared-module/shared/entities/address/AutoCompleteResult';
import { Address, AddressType } from '../../../shared-module/shared/entities/address/address';



declare var google: any;

/**
 * The tour service.
 */
@Injectable()
export class GoogleService{

  private autocompleteService: any;
  private geocoderService: any;

  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocoderService = new google.maps.Geocoder();
  }

  public getSessionToken(): any {
    return new google.maps.places.AutocompleteSessionToken();
  }

  public searchAddress(query: string, sessionToken: string, callback: (results: AutoCompleteResult[]) => void) {
    let request  = {
      input: query,
      language : "de-DE",
      region: "eu",
      sessionToken: sessionToken
    };

    this.autocompleteService.getPlacePredictions(request, (predictions: any, status: any) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        let results: AutoCompleteResult[] = [];
        predictions.forEach((prediction: any) => {
          results.push(new AutoCompleteResult(AutoCompleteResultSource.GOOGLE, prediction.description, prediction));
        })

        callback(results);
      }
    });
  }

  getPlaceDetails(placeId: string, callback: (result: any) => void) {
    this.geocoderService.geocode({ placeId: placeId, language: 'de' }, (result: any) => {
      if(!result || result.length == 0) {
        callback(undefined);
      }
      // take the first element as the final result - we will see if this makes sense
      callback(this.createAddressFromLookupResult(result[0]));
    });
  }

  createAddressFromLookupResult(result: any): Address {
    const address = new Address();
    const addressComponents = result.address_components;
    for (const component of addressComponents) {

      if (this.contains(component.types, 'country')) {
        address.country = component.long_name;
      } else if (this.contains(component.types, 'locality')) {
        address.city = component.long_name;
      } else if (this.contains(component.types, 'postal_code')) {
        address.postalCode = component.long_name;
      } else if (this.contains(component.types, 'route')) {
        address.street = component.long_name;
      } else if (this.contains(component.types, 'street_number')) {
        address.houseNumber = component.long_name;
      }
    }
    // set longitude and latitude
    if (result.geometry && result.geometry.location) {
      address.lat = result.geometry.location.lat();
      address.lng = result.geometry.location.lng();
    }
    // the result is always "complete"
    address.type = AddressType.COMPLETE;

    return address;
  }

  contains(types: string[], type: string): boolean {
    if(types && types.length > 0) {
      return types.indexOf(type) > -1;
    }
    return false;
  }


}
