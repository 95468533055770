import { Injectable } from '@angular/core';
import { RolesService } from '@nexato/nx-core-module';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  constructor(public rolesService: RolesService) { }


  public isLicenceUser():boolean {
    const isAppUser = this.rolesService.hasRole('framework_licencing_app_user');
    const isFullUser = this.rolesService.hasRole('framework_licencing_full_user');

    return (isFullUser);
  }
}
