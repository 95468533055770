import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export const UPDATE_LOCATION_MUTATION = gql`
mutation updateLocation($locationInput: LocationInput) {
  updateLocation(input: $locationInput) {
    id
  }
}
`;

export const CREATE_LOCATION_MUTATION = gql`
mutation createLocation($locationInput: LocationInput) {
  createLocation(input: $locationInput) {
    id
    name
  }
}
`;

export const DELETE_LOCATION_MUTATION = gql`
mutation deleteLocation($locationId: UUID) {
  deleteLocation(id: $locationId)
}
`;

export const GET_LOCATIONS_QUERY = gql`
query locations{
  getLocations(filter:{}){
    id
    name
  }
}
`;

export interface GetLocationsResponse {
  getLocations: LocationEntity[];
  loading: boolean;
}