import { gql } from 'apollo-angular';

export const ACCEPPT_ADDRESS= gql`
mutation acceptAddress($addressId: UUID) {
  acceptAddress(addressId: $addressId) {
    id
  }
}
`;

export const UPDATE_ADDRESS= gql`
mutation updateAddress($addressInput: AddressInput) {
  updateAddress(addressInput: $addressInput) {
    id
  }
}
`;