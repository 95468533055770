<div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
  <div class="basis-1/3 flex flex-row items-center ml-3 font-medium">
    Zugewiesene Aufgaben
  </div>
  <div class="basis-1/3 flex flex-row justify-center items-center">
    <nx-default-button
      (click)="selectAllTasks()"
      [disabled]="selectedTasks?.length === dataSource?.data?.length"
      [style]="'light'"
      [label]="'Alle auswählen'">
    </nx-default-button>
    <div *ngIf="!selectedTasks || selectedTasks?.length === 0"
      class="bg-white text-base pt-[0.25rem] pb-[0.188rem] px-[0.313rem] opacity-50 rounded-md ml-2">
      Keine ausgewählt
    </div>
    <div *ngIf="selectedTasks?.length > 0"
      class="bg-white text-base pt-[0.25rem] pb-[0.188rem] px-[0.313rem] rounded-md ml-2 flex flex-row">
      <div>
        {{selectedTasks?.length}} ausgewählt
      </div>
      <div
        *ngIf="selectedTasks?.length > 0"
        (click)="clearSelection()"
        class="clear ml-1 cursor-pointer">
      +
      </div>
    </div>
    <nx-default-button
      [tooltip]="'Zuweisung aufheben'"
      [disabled]="!selectedTasks || selectedTasks?.length === 0"
      (click)="unassignTasks()"
      [style]="'light'"
      [label]="'aufheben'"
      class="ml-2">
    </nx-default-button>
  </div>
  <div class="basis-1/3 flex flex-row justify-end items-center">
    <input
      pInputText
      type="text"
      class="p-inputtext p-component p-element mr-3"
      (input)="onSearchInput($event.target?.['value'])"
      placeholder="Aufgabe suchen" />
  </div>
</div>
<div class="flex flex-row px-[0.75rem] py-[0.4rem] leading-[1rem] border-b bg-white">
  <div class="basis-2/3 flex flex-row gap-2">
    <nx-filter-multiSelect
      [className]="'table-filter'"
      [filter]="false"
      [showToggleAll]="false"
      [showHeader]="false"
      [appendTo]="'body'"
      [(ngModel)] = "typesModel"
      [options]="typeOptions"
      [showClear]="true"
      placeholder="Typ"
      (onClear)="filter('type', $event)"
      (onChange)="filter('type', $event.value)"
      optionLabel="label">
        <ng-template let-option pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <span class="ml-1 mt-1">{{ option.name }}</span>
          </div>
        </ng-template>
    </nx-filter-multiSelect>
  </div>
  <div class="basis-1/3 flex flex-row justify-end">
    <p-dropdown
      placeholder="Sortierung"
      [styleClass]="'sort'"
      appendTo="body"
      [ngModel] = "sortModel"
      [options]="sortOptions"
      optionLabel="label"
      [showClear]="false"
      (onChange)="sort($event)">
    </p-dropdown>
  </div>
</div>
<div class="grid grid-cols-[1fr,auto,auto]">
  <div
    (click)="selectTask(task)"
    *ngFor="let task of dataSource?.data; let isLast = last"
    class="contents">
      <div 
      task-row-wide
      [columns]="displayedColumns"
      class="child flex flex-row task items-center basis-full relative border-b border-r border-gray cursor-pointer"
      [ngClass]="{'border-b' : !isLast, 'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}"
      [task]=task>
    </div>
    <div
      class="child border-b border-gray bg-white px-2 py-1 text-base flex justify-end items-center max-w-[15.625rem] truncate cursor-pointer"
      [ngClass]="{'border-b' : !isLast, 'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}"><span><app-task-assignment-strategy [assignment]="task?.assignment"/></span>
    </div>
    <div
      class="child border-b border-gray bg-white px-2 py-1 flex justify-center items-center cursor-pointer"
      [ngClass]="{'border-b' : !isLast, 'bg-[#f3f4f6]' : isSelected(task), 'bg-white' : !isSelected(task)}">
        <nx-default-button
          (click)="unassignTask(task); $event.stopPropagation()"
          [tooltip]="'Zuweisung aufheben'"
          [style]="'dark'"
          [label]="'Zuweisung aufheben'">
        </nx-default-button>
    </div>
  </div>
</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Aufgaben gefunden.'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageNumber * pageSize"
  [rows]="pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[15, 20, 50]">
</p-paginator>

<!-- <p-table
  #assignedTasksTable 
  [lazy]="true" 
  (onStateRestore)="restoreState($event)"
  (onLazyLoad)="loadData($event)"
  (onStateSave)="saveState($event)"
  [value]="dataSource?.data" 
  [scrollable]="true"
  [paginator]="true" 
  [rows]="15" 
  [loading]="loading" 
  [rowsPerPageOptions]="[15, 20, 50]"
  [totalRecords]="dataSource?.totalElements" 
  scrollHeight="flex" 
  [selectionPageOnly]="true"
  [selectionMode]="'multiple'"
  [sortField]="sortProperty" 
  [sortOrder]="translateSortDirectionToSortOrder(sortDirection)"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  paginatorStyleClass="rounded-b-md" 
  [stateStorage]="stateStorage"
  [(selection)]="selectedTasks"
  dataKey="id"
  [stateKey]="tableStateKey">

  <ng-template pTemplate="header">

    <tr style="margin-top: -8px;position: relative;top: -1px;">
      <th style="width: 2rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th style="min-width:3.75rem; width:3.75rem;max-width:3.75rem;text-align: center;" >Typ</th>
      <th style="min-width: 10rem">Auftragsnummer</th>
      <th style="min-width: 8rem">Fälligkeit</th>
      <th style="width: 100%">Beschreibung</th>
      <th style="min-width: 10rem">Kunde</th>
      <th style="min-width: 10rem">Zuweisung</th>
      <th style="min-width:8em"></th>
      <th style="min-width:8em"></th>
    </tr>

  </ng-template>
  <ng-template pTemplate="body" let-task>
    <tr>
      <td>
        <p-tableCheckbox [value]="task"></p-tableCheckbox>
      </td>
      <td style="padding-left: 0">
        <div class="flex flex-row h-[40px]">
          <div [ngStyle]="{ 'background-color': task?.getBackgroundColor() }" class="w-[5px] "></div>
          <div class="pl-[10px]"><i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i></div>
        </div>
      </td>
      <td>
        <a [routerLink]="['/orders/order/', task?.order?.id ]" class="jump">
          {{task?.order?.number}}
          <i [className]="'icon icon-jump-to'"></i>
        </a>
      </td>
      <td>{{taskService.getDeliveryDateFull(task?.dueDateTimePeriod)}}&nbsp;{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}}</td>
      <td>{{task.description}}</td>
      <td>{{getContactName(task?.order?.customer) ? getContactName(task?.order?.customer): '-'}}</td>
      <td>
        <app-task-assignment-strategy [assignment]="task?.assignment"/>
      </td>
      <td class="text-right">
        <ng-template #filesOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <app-order-files [order]="task.order?.id" [style]="'overlay'"/>
          </div>
        </ng-template>
        <nx-default-button
          #filesTriggerButton
          [tooltip]="'Anhänge anzeigen'"
          [style]="'light'"
          [badgeValue]="task?.order?.fileCount"
          class="mr-2"
          (click)="openOverlay(filesOverlayContent, filesTriggerButton)"
          [icon]="'icon-file-attachment'">
        </nx-default-button>
        <ng-template #commentsOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <div>
              <nx-headline-box
                [label]="'Auftrag'"></nx-headline-box>
              <nx-default-box
                class="mb-2"
                [label]="'Hinweis'">{{task?.order?.note}}</nx-default-box>
              <app-order-comments-list [order]="task?.order?.id" [style]="'overlay'" />
            </div>
          </div>
        </ng-template>
        <nx-default-button
          #commentsTriggerButton
          [tooltip]="'Hinweis/Kommentare anzeigen'"
          [style]="'light'"
          [badgeValue]="task.calculateCommentsAndNoteBadgeCount()"
          (click)="openOverlay(commentsOverlayContent, commentsTriggerButton)"
          [icon]="'icon-annotation'">
        </nx-default-button>
      </td>
      <td class="text-right">
        <nx-default-button
          (click)="unassignTask(task)"
          [tooltip]="'Bearbeiten und zuweisen'"
          [style]="'dark'"
          [label]="'aufheben'">
        </nx-default-button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="9" class="emptyMessage">Keine Aufgaben gefunden.</td>
    </tr>
  </ng-template>
</p-table> -->

<!-- <app-task-assignment-general
  [settings]="{'type':'open', 'displayedColumns':displayedColumns, 'states': states, 'selectedLocation':selectedLocation, 'taskType':taskType, 'date':date, 'filterValue': filterValue}">
</app-task-assignment-general> -->



