<ng-container *ngIf="controlTemplate?.settings?.value; else noValue">
    <div class="flex flex-row border-b border-solid border-gray-300 last:border-b-0">
        <input type="checkbox" [checked]="checked()" disabled>
    </div>
    <div class="flex flex-row border-b border-solid border-gray-300 last:border-b-0" *ngIf="showText()">
        {{controlTemplate?.settings?.value?.text}}
    </div>
</ng-container>
<ng-template #noValue>
    -
</ng-template>