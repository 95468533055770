import { CdkDragRelease, CdkDragStart } from '@angular/cdk/drag-drop';
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditDialogService, LoadingService, RolesService } from '@nexato/nx-core-module';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Panel } from 'primeng/panel';
import { Subscription } from 'rxjs';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { NxDragDrop } from '../../shared/dragDrop/evetns';
import { NxDropList } from '../../shared/dragDrop/nx-drop-list.directive';
import { Tour } from '../../shared/entities/tour';
import { TourPlannerService } from '../../shared/services/tour/tour-planner.service';
import { TourService } from '../../shared/services/tour/tour.service';
import { TourSettingsDialogComponent } from '../tour-settings-dialog/tour-settings-dialog.component';

@Component({
  
  selector: "div[tours-planner-tours-list], app-tours-planner-tours-list",
  templateUrl: "./tours-planner-tours-list.component.html",
  styleUrls: ["./tours-planner-tours-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// https://github.com/angular/components/issues/18673
export class ToursPlannerToursListComponent implements AfterViewChecked, OnDestroy {
castPanel(panel: Panel): NxDropList<any> {
  return (panel as any) as NxDropList<any>;
}

  @ViewChild('panel') public panel: Panel;
  @ViewChild('dropContainer') dropContainer!: ElementRef;
  
  isDropListDisabled = false;
  displayNoSignUp = true;
  _tours = new Array<Tour>();
  dragActive = false;
  isCollapsed = true

  @Input() loading: boolean;
  @Input() date: any;
  @Input() driverEntities: any;
  @Input()
  set tours(tours: Tour[]) {
    this._tours = tours;
  }
  @Input() isTourFull: boolean;
  @Input() _headline: string;
  @Input() tourState: string;

  @Input() openPanels = new Array<any>();
  @Input() sortBy: string;

  @Output() setVehicle = new EventEmitter();
  @Output() removeVehicle = new EventEmitter();
  @Output() addTaskToTour = new EventEmitter();
  @Output() addTaskToTourAtPosition = new EventEmitter();
  @Output() removeTaskFromTour = new EventEmitter();
  @Output() setDriver = new EventEmitter();
  @Output() removeDriver = new EventEmitter();
  @Output() changeOrder = new EventEmitter();
  @Output() dragStart = new EventEmitter();
  @Output() dragEnd = new EventEmitter();
  @Output() addTimes = new EventEmitter();
  @Output() addDescription = new EventEmitter();
  @Output() refetch = new EventEmitter();

  public draggingSource: any = undefined;
  public mouseoverPanel: { id: any; } = undefined;
  public isDraggingSubscription: Subscription;

  dialogRef: DynamicDialogRef | undefined;

  constructor(
    private changeDetector : ChangeDetectorRef,
    private taskService: TasksService,
    private router: Router,
    private loadingService: LoadingService,
    public dialogService: EditDialogService,
    public dialog: MatDialog,
    public rolesService: RolesService,
    public tourService: TourService,
    public tourPlannerService: TourPlannerService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.tourPlannerService.setToursPlannerToursListComponent(this);
    this.isDraggingSubscription = this.tourPlannerService.isDragging$.subscribe((isDragging) => {
      this.dragActive = isDragging;
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.isDraggingSubscription?.unsubscribe();
  }

  mouseEnter($event: any, panel: Panel, tourId: any){
    if(panel.collapsed){
      this.mouseoverPanel = panel;
      panel.toggle($event);
    }
  }

  trackByIndex = (index: any) => index;

  showHide(){
    this.displayNoSignUp = !this.displayNoSignUp;
  }

  onAddTimes(tour: Tour){
    this.addTimes.emit(tour);
  }

  onAddDescription(event: { stopPropagation: () => void; }, tour: Tour){
    event.stopPropagation();
    this.addDescription.emit(tour);
  }
  onAddTimesNew(event: { stopPropagation: () => void; }, tour: Tour){
    event.stopPropagation();
    this.addTimes.emit(tour);
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  isTourButtonDisabled(tour: Tour){
    if(tour.state === 'FINISHED' || tour.state === 'RUNNING' || tour.state === 'PAUSED'){
      return true;
    }
    return false;
  }

  isDropDisabled(tour: Tour): boolean {
    return this.tourPlannerService.isDropDisabled(tour); // Call service method
  };

  /**
   * Checks, if its allowed to drag the task. A task can only be dragged if its not 'done'
   * @param task
   * @returns
   */
  isDragDisabled(task: Task) {
    return this.tourPlannerService.isDragDisabled(task);
  }

  dragDropped(event: NxDragDrop<Task, Tour> | any) {
    this.tourPlannerService.dragDropped(event);
  }

  dragStarted(event: CdkDragStart<Task>) {
    this.tourPlannerService.dragStarted(event);
  }

  dragReleased(event: CdkDragRelease<Task>) {
    this.tourPlannerService.dragReleased(event);
  }

  getTourDepartureTime(tour: Tour): string{
    if (tour.state === 'NEW') {
      return tour?.scheduledDepartureDateTime;
    }
    else {
      return tour?.actualDepartureDateTime;
    }
  }

  getTourArrivalTime(tour: Tour): string{
    if (tour.state === 'FINISHED' || tour.state === 'ARCHIVED') {
      return tour?.actualArrivalDateTime;
    }
    else {
      return tour?.scheduledArrivalDateTime;
    }
  }

  openSettings(tour: Tour) {
    if (tour.state !== 'FINISHED') {
      this.dialogRef = this.dialogService.open(TourSettingsDialogComponent, {
        header: 'Tour bearbeiten',
        data: {
          tour: tour,
        },
      });
      this.dialogRef.onClose.subscribe(dialog=>{
        console.log('Dialog', dialog);
        if (dialog) {
          this.tourService.updateTourInputAndExecute(dialog, () => this.refetch.emit());
        }
      });
    }
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  getPanelStyleClass(tour: Tour){
    let styleClass = 'mb-1';
    if(tour?.tasks?.length === 0 ){
      styleClass += ' empty';
    }
    if(tour?.state){
      styleClass += ' ' + tour?.state?.toLowerCase();
    }
    return styleClass?.trim();
  }

  togglePanel($event, panel: Panel){
    panel.toggle($event);
  }

  collapsedChange($event, tour: Tour){
    if(tour?.id && $event){
      sessionStorage.setItem('ToursPlannerToursListComponent.panelExpanded.'+ tour?.id, 'true');
    } else {
      sessionStorage.removeItem('ToursPlannerToursListComponent.panelExpanded.'+ tour?.id);
    }
  }

  checkOpen(tour: Tour){
    if(tour?.id){
      let isOpen = sessionStorage.getItem('ToursPlannerToursListComponent.panelExpanded.'+ tour?.id);
      if(isOpen){
        return true;
      }
    }
  }

  public commandMenuItems: any[] = [
    {
      label: 'Tour bearbeiten',
      command: 'editTour',
      disabled: function() : boolean {
        return this.data?.state === 'FINISHED' || this.data?.state === 'ARCHIVED';
      },
      role: 'nexcore_tour_update'
    },
    {
      label: 'Tour löschen',
      disabled: function() : boolean {
        return this.data?.state === 'FINISHED' || this.data?.state === 'ARCHIVED' || this.data?.tasks?.length > 0;
      },
      command: 'deleteTour',
      role: 'nexcore_tour_delete'
    },
    {
      label: 'Tour beenden',
      command: 'finishTour',
      disabled: function() : boolean {
        // return this.data?.address?.geocodingResultGrade !== 'YELLOW'}
        return true;
      },
      role: 'nexcore_tour_update'
    }
  ];

  getCommandMenuItems(){
    return this.commandMenuItems.filter(item => this.rolesService.hasRole(item.role));
  }

  executeCommand($event){
    console.log('executeCommand', $event);
    if($event.command === 'editTour'){
      this.openSettings($event.data);
    }
    if($event.command === 'deleteTour'){
      this.tourPlannerService.deleteTour($event.data);
    }
    if($event.command === 'finishTour'){
      console.log('finishTour', $event.data);
    }
  }

}
