
export class ControlTemplate {

  public key: string;
  public type: string;
  public settings: any;
  public path: string;
  public controls: ControlTemplate[];
  public validators: ControlTemplate[];
  public body: ControlTemplate[];
  public completion: ControlTemplate;
  public submit: ControlTemplate;


  constructor(payload?: any, path?: string) {
    if (payload) {
      this.key = payload.key;
      this.type = payload.type;
      this.settings = payload.settings;
      if(payload.controls){
        this.controls = new Array<ControlTemplate>();
        payload.controls.map((controlTemplate: any) => this.controls.push(new ControlTemplate(controlTemplate, this.path)));
      }
      if(payload.validators){
        this.validators = new Array<ControlTemplate>();
        payload.validators.map((validatorTemplate: any) => this.validators.push(new ControlTemplate(validatorTemplate)));
      }
      if(payload.submit){
        this.submit = new ControlTemplate(payload.submit, "submit");
      }
      if(payload.body){
        this.body = new Array<ControlTemplate>();
        payload.body.map((controlTemplate: any) => this.body.push(new ControlTemplate(controlTemplate, "body")));
      }
      if(payload.completion){
        this.completion = new ControlTemplate(payload.completion, "completion");
      }
    }
    if(path) {
      this.path = path + "." + this.key;
    }

  }

  public getSetting(key: string) {
    if(this.settings && this.settings[key]){
      return this.settings[key];
    }
  }

}
