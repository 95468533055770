import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpandService } from '@nexato/nx-core-module';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { TaskAssignmentListAssignedComponent } from '../task-assignment-list-assigned/task-assignment-list-assigned.component';
import { TaskAssignmentListCompletedComponent } from '../task-assignment-list-completed/task-assignment-list-completed.component';
import { TaskAssignmentListUnassignedComponent } from '../task-assignment-list-unassigned/task-assignment-list-unassigned.component';

@Component({
  selector: 'app-task-assignment',
  templateUrl: './task-assignment.component.html',
  styleUrls: ['./task-assignment.component.scss']
})
export class TaskAssignmentComponent implements OnInit {

  public selectedLocations: LocationEntity[];
  public selectedDate = new Date();
  public activeIndex;
  private storageKey = 'TaskAssignmentComponent.tabActiveIndex';

  @ViewChild(TaskAssignmentListUnassignedComponent) taskAssignmentListUnassignedComponent!: TaskAssignmentListUnassignedComponent;
  @ViewChild(TaskAssignmentListAssignedComponent) taskAssignmentListAssignedComponent!: TaskAssignmentListAssignedComponent;
  @ViewChild(TaskAssignmentListCompletedComponent) taskAssignmentListCompletedComponent!: TaskAssignmentListCompletedComponent;

  constructor(
    private expandService: ExpandService
  ) {
    this.getActiveIndex();
  }

  ngOnInit(): void {
    this.expandService.setExpandState(false);
    
  }

  ngOnDestroy(): void {
    this.expandService.setExpandState(false);
  }

  dateChanged($event: any) {
    this.selectedDate = $event;
  }

  locationsChanged($event: any) {
    this.selectedLocations = $event;
  }

  onTabChange(event: any) {
    if (event.index === 0) {
        this.taskAssignmentListUnassignedComponent.refetchloadTaskList(true);
    } else if (event.index === 1) {
        this.taskAssignmentListAssignedComponent.refetchloadTaskList(true);
    } else if (event.index === 2) {
      this.taskAssignmentListCompletedComponent.refetchloadTaskList();
    }
    this.activeIndex = event.index;
    this.saveActiveIndex();
  }

  saveActiveIndex() {
    sessionStorage.setItem(this.storageKey, this.activeIndex);
  }

  getActiveIndex() {
    console.log(this.activeIndex);
    let activeIndex = sessionStorage.getItem(this.storageKey);
    if (activeIndex === '1' || activeIndex === '2' || activeIndex === '3') {
      this.activeIndex = Number(activeIndex);
    } else {
      sessionStorage.removeItem(this.storageKey);
      this.activeIndex = 0;
    }
  }

}
