<div class="flex flex-col">
  <div class="text-lg font-medium mb-2">Benachrichtigungen</div>
  <div>
    <div class="flex flex-row gap-2">
      <ng-container
        *ngIf="unreadNotifications$ | async as notifications">
        <nx-default-button
          *ngIf="notifications.length > 0"
          [style]="'dark'"
          (click)="markAllAsRead(notifications); $event.stopPropagation()"
          [width]="'full'"
          [label]="notifications.length + ' als gelesen markieren'"
          class="pr-2 basis-1/2">
        </nx-default-button>
        <div class="basis-1/2"></div>
      </ng-container>
    </div>
  </div>
  <div class="flex flex-row gap-2 mt-2 sections">
    <nx-default-button
      [disabled]="activeSection === 'unread'"
      (click)="activeSection = 'unread'"
      [style]="activeSection === 'unread' ? 'dark' : 'light'"
      [label]="'Ungelesen'"
      [width]="'full'"
      class="pr-2 pt-1 basis-1/2">
    </nx-default-button>
    <nx-default-button
      [disabled]="activeSection === 'all'"
      (click)="activeSection = 'all'"
      [style]="activeSection === 'all' ? 'dark' : 'light'"
      [label]="'Alle'"
      [width]="'full'"
      class="pr-2 pt-1 basis-1/2">
    </nx-default-button>
    <!-- <div
      class="basis-1/2 border border-gray rounded-md py-1 px-2 text-[14px]"
      [ngClass]="[activeSection === 'unread' ? 'bg-blue-500' : 'bg-gray-500', 'text-white']">
      Ungelesen
    </div>
    <div
      class="basis-1/2 border border-gray rounded-md py-1 px-2 text-[14px]">
      Alle
    </div> -->
  </div>

  <div class="flex flex-col  mt-2">
    <ng-container *ngIf="(activeSection === 'unread' ? (unreadNotifications$ | async) : (allNotifications$ | async)) as notifications">
      <ng-container *ngFor="let notification of notifications">
        <app-notification
          class="border-b border-gray last:border-none"
          [notification]="notification">
        </app-notification>
      </ng-container>
      <div
        *ngIf="notifications.length > 0"
        class="text-base font-light mt-2">
        {{ notifications.length }} von {{ notifications.length }}{{activeSection === 'unread' ? ' ungelesenen' : ''}} Benachrichtigungen
      </div>
      <nx-empty-box
        *ngIf="notifications.length === 0"
        [label]="'Du bist auf dem neuesten Stand'">
    </nx-empty-box>
    </ng-container>
    
    <!-- Template für den Fall, dass keine Benachrichtigungen vorhanden sind -->
    <ng-template #noNotifications>

    </ng-template>
  </div> 
</div>