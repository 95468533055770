import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EditDialogService, ExpandService, LoadingService, RolesService, ToastService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { FilterMetadata } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { take } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { ResourceDialogComponent } from '../../components/resource-dialog-component/resource-dialog.component';
import { Resource } from '../../shared/entities/resource/resource';
import { LocationService } from '../../shared/services/location/location.service';
import { ResourceDataSource } from './resourceDataSource';

interface DynmaicTableComponentState{
  displayedColumns: string[],
}
@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss']
})
export class ResourcesListComponent implements  OnDestroy{

  public text = '';

  public locations: LocationEntity[];
  public locationsList: LocationEntity[];

  public locationModel: any;
  public functions: any;
  public functionModel: any;
  public dataSource: ResourceDataSource;
  private sortProperty = 'id';
  private sortDirection = 'asc';
  public pageNumber = 0;
  public pageSize = 15;
  public loading = false;
  public Resource = Resource;
  dialogRef: DynamicDialogRef | undefined;


  public sortField = 'id';
  public sortOrder = 1;
  @Input() withMainHeadline = true;

  // TODO to Resource object
  //  From FResource object static method not working
  public functionsArray = [{
    id: 'TRANSPORT',
    name: 'Transport',
    label: 'Transport'
  }
];

public sortOptions = [
  { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
  { label: 'Nummer aufsteigend', fieldName: 'number', direction: 1 },
  { label: 'Nummer absteigend', fieldName: 'number', direction: -1 },
  { label: 'Name aufsteigend', fieldName: 'name', direction: 1 },
  { label: 'Name absteigend', fieldName: 'name', direction: -1}
];

  constructor(
    private router: Router,
    private apollo: Apollo,
    public loadingService: LoadingService,
    public rolesService: RolesService,
    public expandService: ExpandService,
    private cdRef:ChangeDetectorRef,
    private dialogService: EditDialogService,
    private toastService: ToastService,
    private locationService: LocationService
  ) {
    let ng = this;
    this.setTableDataSource();
  }
  ngOnInit() {
    this.locationService.getLocations().pipe(
      take(1)
    ).subscribe((locations) => {
      this.locationsList = locations;
    });
    this.expandService.setExpandState(false);
  }
  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  setTableDataSource(): void{
    this.dataSource = new ResourceDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadresources({
      number: 0,
      size: 15,
      sortProperty: 'id',
      sortDirection: 'asc',
      locationIds : this.locations,
      text: this.text
    });
  }

  refetchResources(force = false): void {
    console.log('Refetch', force);
    this.dataSource.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      locationIds : this.locations,
      functions: this.functions? this.functions: null,
      text: this.text
    }, force);
  }

  selectionChanges() {
    this.refetchResources();
  }

  createResource(): void {
    this.dialogRef = this.dialogService.open(ResourceDialogComponent, {
      header: 'Ressource anlegen',
      data: {
        locations: this.locationsList
      }
    });
    this.dialogRef.onClose.subscribe((result) => {
      // result is true or false, indiciating if it should refetch
      if (result){
        this.refetchResources(true);
        this.loadingService.setLoading(false);
        this.toastService.addToastWithMessage('Resource erfolgreich angelegt.');
      }
      this.loadingService.setLoading(false);
    });
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  loadData(event: TableLazyLoadEvent) {
    console.log(event);
    this.loading = true;
    this.text = event?.globalFilter?.toString();

    // sorting
    if(event?.sortField !== undefined && typeof event?.sortField  == 'string') {
      this.sortProperty = event.sortField;
    }
    if(event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }
    // filtering
    // if(event?.filters.name !== undefined) {
    //   this.name = (event.filters.name as FilterMetadata)?.value;
    // }
    // if(event?.filters.number !== undefined) {
    //   this.number = (event.filters.number as FilterMetadata)?.value;
    // }
    if (event?.filters?.function !== undefined) {
      let filter = event.filters.function as FilterMetadata;
      if (filter?.value === undefined
        || filter?.value === null) {
        this.functionModel = null;
        this.functions = null;
      }
    }
    if (this.functionModel !== undefined
      && this.functionModel !== null
      && this.functionModel.length > 0
    ) {
      const values = this.functionModel;
      this.functions = values?.map((item: any) => item.id);
    } else {
      this.functions = null;
    }
    if (event?.filters?.location !== undefined) {
      let filter = event.filters.location as FilterMetadata;
      if (filter?.value === undefined
        || filter?.value === null) {
        this.locationModel = null;
        this.locations = null;
      }
    }
    if (this.locationModel !== undefined
      && this.locationModel !== null
      && this.locationModel.length > 0
    ) {
      const values = this.locationModel;
      this.locations = values?.map((item: any) => item.id);
    } else {
      this.locations = null;
    }

    // pagination
    this.pageNumber = event?.first !== undefined ? event.first/event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchResources();
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }
}
