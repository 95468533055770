<!-- <mat-nav-list *ngIf="licenseService?.isLicenceUser()">
  <ng-container *ngFor="let menuitem of menuItems">
    <mat-list-item [routerLink]="[menuitem.path]" routerLinkActive="active" *ngIf="(!menuitem.role) || (rolesService.hasRole(menuitem.role))">
      <div class="nav">
        <i [className]="'icon ' + menuitem.icontype" [matTooltip]="isExpanded? null : menuitem.title"></i>
        <span *ngIf="isExpanded" class="pl-2">{{menuitem.title}}</span>
        <span [matBadge]="menuitem.count" matBadgeOverlap="false" matBadgeSize="small" [hidden]="menuitem.count <= 0"></span>
      </div>
    </mat-list-item>
  </ng-container>
</mat-nav-list> -->

<p-menu
  [ngClass]="{'reduced': !isExpanded, 'expanded': isExpanded}"
  #menu     
  [model]="menuItems">
  <ng-template
    pTemplate="item"
    let-item>
    <a class="flex flex-col" [routerLink]="item.routerLink"
      [pTooltip]="!isExpanded ? item?.label : null"
      [tooltipOptions]="tooltipOptions"
    routerLinkActive="p-menuitem-link-active"
    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }">
      <div class="flex flex-row h-[20px]">
        <div><i class="relative top-[-6px] left-[-4px] text-[22px]" [ngClass]="item?.icon"></i></div>
        <div class="ml-2">{{item?.label}}</div>
      </div>
    </a>
  </ng-template>
</p-menu>