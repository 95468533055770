<ng-container *ngIf="withMainHeadline" class="mx-auto pt-[22px] px-[1.25rem] h-[calc(100%-100px)]">
  <nx-pageTitle-box class="mb-3" [label]="'Aufgaben'">
  </nx-pageTitle-box>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-container *ngIf="!withMainHeadline">
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-template #table>
  <div class="bodyWrapper bg-white h-full">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Aufgabenliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center">
        <!-- <input pInputText type="text" class="p-inputtext p-component p-element mr-3"
          (input)="dttasks.filterGlobal($event.target?.['value'], 'contains')" placeholder="Kontaktperson suchen" /> -->
      </div>
    </div>
    <p-table #dttasks [lazy]="true" (onLazyLoad)="loadData($event)" [scrollable]="true" [value]="dataSource?.data"
      [paginator]="true" [rows]="20" [rowsPerPageOptions]="[10, 20, 50]" [showCurrentPageReport]="true" [pageLinks]="0"
      [totalRecords]="dataSource?.totalElements" [loading]="loading"
      currentPageReportTemplate="{first} - {last} von {totalRecords}" [resizableColumns]="true" [sortField]="sortField"
      [sortOrder]="sortOrder">
      <ng-template pTemplate="caption">
        <div class="flex flex-row">
          <div class="basis-2/3 flex flex-row gap-2">
            <p-columnFilter class="type" field="type" matchMode="in" [showMenu]="false" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false"
              [showButtons]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <nx-filter-multiSelect [className]="'table-filter'" [filter]="false" [showToggleAll]="false"
                  [showHeader]="false" [appendTo]="'body'" [(ngModel)]="typesModel" [options]=availableTypes
                  [showClear]="true" placeholder="Typ" (onClear)="filter($event)" (onChange)="filter($event.value)"
                  optionLabel="name">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option?.name }}</span>
                    </div>
                  </ng-template>
                </nx-filter-multiSelect>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter field="state" matchMode="in" [showMenu]="false" [showMatchModes]="false"
              [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false"
              [showButtons]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <nx-filter-multiSelect [className]="'table-filter'" [filter]="false" [showToggleAll]="false"
                  [showHeader]="false" [appendTo]="'body'" [options]="availableStates" [(ngModel)]="statesModel"
                  [showClear]="true" [maxSelectedLabels]="1" placeholder="Status" (onClear)="filter($event)"
                  (onChange)="filter($event?.value)">
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      {{option?.name}}
                    </div>
                  </ng-template>
                </nx-filter-multiSelect>
              </ng-template>
            </p-columnFilter>

          </div>
          <div class="basis-1/3 flex flex-row justify-end">
            <p-dropdown [styleClass]="'sort'" appendTo="body" [options]="sortOptions" optionLabel="label"
              [showClear]="false" (onChange)="sort($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="header">
        <!-- Header cells -->
        <tr style="margin-top: -8px;position: relative;top: -1px;">
          <th pFrozenColumn style="min-width:3.75rem; width:3.75rem;max-width:3.75rem;text-align: center;" >Typ</th>
          <th style="min-width:7.5rem">Status</th>
          <th style="min-width:10rem" pResizableColumn>Fälligkeit</th>
          <th style="min-width:10rem" pResizableColumn>Beschreibung</th>
          <th style="min-width:10rem" pResizableColumn>Zuweisung</th>
          <th style="min-width:8rem" pResizableColumn>Erledigt</th>
          <th style="min-width:8rem" pResizableColumn>Benutzer</th>
          <!-- <th alignFrozen="right" pFrozenColumn style="min-width:3.6em"></th> will be renabled later when we have a menu here -->
        </tr>

      </ng-template>

      <!-- Body cells -->
      <ng-template pTemplate="body" let-task>
        <tr>
          <td pFrozenColumn style="padding-left: 0">
            <div class="flex flex-row h-[40px]">
              <div [ngStyle]="{ 'background-color': task?.getBackgroundColor() }" class="w-[5px] "></div>
              <div class="pl-[10px]"><i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i></div>
            </div>
          </td>
          <td><p-tag value="{{task?.getStateLabel()}}" [severity]="task?.getStateSeverity()"></p-tag></td>
          <td>
            {{taskService.getDeliveryDateFull(task?.dueDateTimePeriod)}}&nbsp;{{taskService.getDeliveryTime(task?.dueDateTimePeriod)}}
          </td>
          <td>{{task.description}}</td>
          <td><app-task-assignment-strategy [assignment]="task?.assignment" /></td>
          <td>{{task?.completedDateTime ? (task?.completedDateTime | date:'dd.MM.YY HH:mm') : '-' }}</td>
          <td>{{getName(task?.completedBy)}}</td>
          <!-- <td alignFrozen="right" pFrozenColumn>
                <nx-default-button
                [icon]="'icon-options'">
              </nx-default-button>
            </td> will be renabled later when we have a menu here -->
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Aufgaben vorhanden.</td>
        </tr>
      </ng-template>

      <!-- Pagination -->
    </p-table>
  </div>
