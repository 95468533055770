import { gql } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';

export const LOCATIONS_QUERY = gql`
query LocationsQuery($type: LocationType)
{
  locations: getLocations(filter:{
    type: $type
  }){
    id
    name
    label
  }
}
`;

export interface LocationsQueryResponse {
  locations: LocationEntity[];

}
