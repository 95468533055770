import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-rangeInput-control-label-view, [app-rangeInput-control-label-view]',
  templateUrl: './rangeInput-control-label-view.component.html',
  styleUrls: ['./rangeInput-control-label-view.component.scss']
})
export class RangeInputControlLabelViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

}
