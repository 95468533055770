import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorComponent implements OnInit {

  public moment = moment;
  @Input() stateKey: string;
  @Input() storageType: 'session' | 'local' | 'none' = 'none';
  @Input() displayLabel = true;
  @Input() placeholder = 'Datum';
  @Input() initialDate: Date;
  @Output() date = new EventEmitter();
  @Input() className: string;
  public selectedDate = moment().toDate();

  @ViewChild('calendar', { static: false }) calendar: Calendar; // Get reference to the calendar

  public calendarLocale: string = 'de';

  constructor() {
  }

  ngOnInit(): void {
    // here we check, what the first date should be
    // - if we have a persisted date, we use this
    // - if we have an initial date, we use this
    // - otherwise we use the current date
    const persistedDate = this.loadPersistedDate();
    if (persistedDate) {
      this.setDate(persistedDate);
      return;
    }
    if (this.initialDate) {
      this.setDate(this.initialDate);
      return;
    }
    this.setDate(moment().toDate());
  }

  dayBack(){
    this.setDate(moment(this.selectedDate).subtract(1, 'day').toDate());
  }

  dayForward(){
    this.setDate(moment(this.selectedDate).add(1, 'day').toDate());
  }

  loadToday(): void {
    this.setDate(moment().toDate());
  }

  dateChange($event) {
    this.setDate($event);
  }

  setDate(date: Date) {
    this.selectedDate = date;
    this.persistDate();
    this.date.emit(this.selectedDate);
  }

  persistDate() {
    if (this.storageType !== 'none' && this.stateKey !== undefined && this.stateKey.length > 0) {
      const storage = this.storageType === 'session' ? sessionStorage : localStorage;
      storage.setItem(this.getFullStateKey(), JSON.stringify(this.selectedDate));
    }
  }

  loadPersistedDate(): Date | undefined {
    if (this.storageType !== 'none' && this.stateKey !== undefined && this.stateKey.length > 0) {
      const storage = this.storageType === 'session' ? sessionStorage : localStorage;
      const storedDate = storage.getItem(this.getFullStateKey());
      if (storedDate) {
        try {
          let date = new Date(JSON.parse(storedDate));
          return date;
        } catch (error) {
          // error while parsing stored date - clean up
          storage.removeItem(this.getFullStateKey());
        }
      }
    }
    return undefined;
  }

  getFullStateKey(): string {
    return "DateSelectorComponent." + this.stateKey;
  }

    // Function to toggle calendar visibility
    toggleCalendar(event: any) {
      if (this.calendar) {
        if (this.calendar) {
          this.calendar.overlayVisible = true;  // Make the overlay visible
          setTimeout(() => {
            this.calendar.inputfieldViewChild.nativeElement.focus();  // Focus on the hidden input to trigger calendar
            this.calendar.onInputClick();  // Trigger the click event programmatically
          }, 0);
        }
      }
      event.stopPropagation();
    }

  isToday(): boolean {
    return moment(this.selectedDate).isSame(moment(), 'day');
  }
}
