// drag-drop-monitor.service.ts
import { Injectable, OnDestroy } from '@angular/core';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DragDropMonitorService implements OnDestroy {
  private dragState = new Subject<any>();
  dragState$ = this.dragState.asObservable();
  
  private cleanup: (() => void);

  constructor() {
    this.cleanup = monitorForElements({
      onDragStart: args => {
        // console.log('dragStart', args);
        this.dragState.next({ type: 'dragStart', ...args });
      },
      onDrag: args => {
        // console.log('drag', args);
        this.dragState.next({ type: 'drag', ...args });
      },
      onDrop: args => {
        // console.log('drop', args);
        this.dragState.next({ type: 'drop', ...args });
      }
    });
  }

  ngOnDestroy() {
    this.cleanup();
    this.dragState.complete();
  }
}