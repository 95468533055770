<!-- [expanded]="checkOpen(tour?.id)" -->
<!-- [disabled]="false" -->
<!-- (afterExpand)="opened(tour?.id)"
(afterCollapse)="closed(tour?.id)" -->
<!-- [ngClass]="{ empty : tour?.tasks?.length === 0 }" -->
 <p-panel
  #panel
  [nxPanel]="panel"
  nxDropList
  [nxDropListData]="tour"
  [nxIsDropDisabled]="isDropDisabled(tour)"
  (nxDropped)="dragDropped($event)"
  [toggleable]="true"
  [collapsed]="checkOpen(tour)"
  (collapsedChange)="collapsedChange($event, tour)"
  [styleClass]=""
  *ngFor="let tour of _tours; let last = last; trackBy: trackByIndex; let i = index" [ngClass]="getPanelStyleClass(tour)">
        <!--  -->
        <!-- [collapsedHeight]="'36px'"
        [expandedHeight]="'36px'" -->
        <!-- [ngClass]="'tour?.state'" -->
        
        <!-- header -->
  <ng-template pTemplate="header">
    <div
      class="tourHeader row flex flex-row items-center w-full px-2"
      [ngClass]="tour.state">

      <!-- collapse buttons -->
      <nx-default-button
        [icon]="'icon-open-down'"
        (click)="togglePanel($event, panel)"
        *ngIf="panel?.collapsed">
      </nx-default-button>
      <nx-default-button
        [icon]="'icon-close-up'"
        (click)="togglePanel($event, panel)"
        *ngIf="!panel?.collapsed">
      </nx-default-button>

      <!-- tour name -->
      <div class="tourLink basis-28 pl-1 items-center flex">
        <span class="noWrap name"
          #text
          [matTooltipDisabled]="isEllipsisActive(text)" matTooltipPosition="left"
            matTooltipShowDelay="250"
          matTooltip="{{tour.name}}">
        <a [routerLink]="['/tour-planner/detail/', tour.id ]" class="jump">
          {{tour.name}}<i [className]="'icon icon-jump-to'"></i></a></span>
      </div>

      <!-- start and end time -->
      <div class="startEndTime basis-14 pl-2 flex flex-col min-w-fit">
        <div>{{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'HH:mm')  : '--:--'}}</div>
        <div>{{tour?.effectiveArrivalDateTime ? (tour?.effectiveArrivalDateTime | date:'HH:mm') : '--:--'}}</div>
      </div>

      <!-- driver / resource -->
      <div class="driver basis-9/12 py-1 pl-1 truncate">
        <span class="noWrap driver"
        #driver1
        [matTooltipDisabled]="isEllipsisActive(driver1)"
        matTooltipShowDelay="250"
        matTooltip="{{sortBy === 'resource'? tour?.getAssigneeName() : tour?.resource?.name? tour?.resource?.name: ''}}">
        {{sortBy === 'resource'? tour?.getAssigneeName() : tour?.resource?.name? tour?.resource?.name: ''}}</span>
      </div>

      <!-- state, note, settings -->
      <div class="flex flex-row pl-2 items-center justify-end">
        <p-tag class="mr-2" [value]="tour?.getStateLabel()" [severity]="tour?.getStateSeverity()"></p-tag>
        <nx-default-button
          class="mr-2"
          [style]="'light'"
          [icon]="'icon-info'"
          [tooltip]="tour?.note? tour.note : 'Kein Tour-Hinweis hinterlegt'"
          (click)="$event.stopPropagation();"
          [ngClass]="tour?.note? 'note' :'nonote'">
        </nx-default-button>
        <nx-default-button
          [icon]="'icon-options'"
          class="mr-2"
          [style]="'light'"
          [data]="tour"
          [commandMenuItems]="getCommandMenuItems()"
          (onCommand)="executeCommand($event)"
          *ngIf="rolesService?.hasRole('nexcore_tour_update')"
          [tooltip]="'Tour bearbeiten'">
        </nx-default-button>
      </div>

    </div>
  </ng-template>
  <!-- header -->

  <!-- tasks -->
  <div
    #dropContainer
    class="tasks">
    <nx-empty-box
      class="empty-box"
      *ngIf="tour?.tasks?.length === 0"
      [labelOverwriteClass]="'text-base text-[#848488] font-light px-5 py-2 bg-[#f9f9f9] flex items-center justify-center h-[59px] min-w-[280px] bg-white'"
      [label]="'Keine Aufträge geplant.'"
      ></nx-empty-box>
    <div task-row class="flex flex-row task items-center basis-full relative bg-white"
      *ngFor="let task of tour?.tasks; last as isLast; first as isFirst; let i = index"
      #taskElement
      nxDragItem
      [nxDragItemIsDropTarget]="true"
      [nxIsDropDisabled]="isDropDisabled(tour)"
      [nxDragItemData]="task"
      [nxDragItemDropListIndex]="i"
      [nxDragItemDropListId]="tour?.id"
      [nxIsDragDisabled]="isDragDisabled(task)"
      (nxDragDropped)="dragDropped($event)"
      (nxDragStarted)="dragStarted($event)"
      (nxDragEnded)="dragReleased($event)"
      [class.done]="task.state === 'done'"
      [class.last]="isLast"
      [task]=task
      [class.notDraggable]="isDragDisabled(task)"
      [class.draggable]="!isDragDisabled(task)"
      [attr.data-task-id]="task.id">
    </div>
  </div>
  <!-- tasks -->
</p-panel>
