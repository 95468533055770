import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ExpandService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { FilterMetadata } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Contact } from '../../shared/entities/contact/contact';
import { CustomerDataSource } from './customerDataSource';

interface DynmaicTableComponentState{
  displayedColumns: string[],
}
@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent  implements OnDestroy{

  public text = '';
  public contactType: any;
  public contactTypeModel: any;
  public Contact = Contact;
  public dataSource: CustomerDataSource;
  private sortProperty = 'id';
  private sortDirection = 'asc';
  public pageNumber = 0;
  public pageSize = 15;
  public loading = false;
  public contactTypes: any[] = [];
  public typesModel: any[] = [];

  public sortField = 'id';
  public sortOrder = 1;

  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: 1 },
    { label: 'Typ absteigend', fieldName: 'type', direction: -1 },
    { label: 'Kontakt-ID aufsteigend', fieldName: 'number', direction: 1 },
    { label: 'Kontakt-ID absteigend', fieldName: 'number', direction: -1}
  ];
  @Input() withMainHeadline = true;

  constructor(
    private router: Router,
    private apollo: Apollo,
    // public dialog: MatDialog,
    public rolesService: RolesService,
    public expandService: ExpandService,
    private cdRef:ChangeDetectorRef,

  ) {
    this.contactTypes = Contact?.getContactTypes();
    this.setTableDataSource();
  }

  ngOnInit() {
    this.expandService.setExpandState(false);
  }

  setTableDataSource(): void {
    this.dataSource = new CustomerDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadCustomers({
      number: 0,
      size: 15,
      sortProperty: 'type',
      sortDirection: 'asc',
      types: this.contactType,
    });
  }

  createContact(): void {
    // const dialogRef = this.dialog.open(ContactComponent, {
    //   width: '760px',
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result && result.saved) {
    //     this.refetchContacts();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  refetchContacts(): void {
    // console.log('type', this.contactType);
    this.dataSource.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      types: this.contactType,
    });
  }

  getContactType(id: any): string | undefined {
    const type = Contact.getContactTypes().find((o) => o?.id === id?.toString());
    return type?.name;
  }

  // deleteContact(contactId: string) {
  //   this.contactService.deleteContact(
  //     contactId,
  //     this.returnCallback(this.router)
  //   );
  // }
  // confirmDialog(contact: Contact): void {
  //   const message = 'Möchtest Du den Kontakt (' + this.getContactName(contact) +') wirklich löschen?';
  //   const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     maxWidth: "400px",
  //     data: dialogData,
  //     autoFocus: false
  //   });

  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     if (dialogResult) {
  //       this.loadingService.setLoading(true);
  //       this.deleteContact(contact.id);
  //     }
  //   });
  // }
  // returnCallback(router: Router): void {
  //   setTimeout(() => {
  //     this.loadingService.setLoading(false);
  //     this.refetchContacts();
  //   }, 500);
  // }
  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  loadData(event: TableLazyLoadEvent) {
    console.log(event);
    this.loading = true;
    this.text = event?.globalFilter?.toString();

    // sorting
    if(event?.sortField !== undefined && typeof event?.sortField  == 'string') {
      this.sortProperty = event.sortField;
    }
    if(event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }
    // filtering

    if (event?.filters?.type !== undefined) {
      let filter = event.filters.type as FilterMetadata;
      if (filter === undefined
        || filter === null
      ) {
        this.typesModel = null;
        this.contactType = null;
      } else {
        if(filter?.value?.length > 0){
          this.contactType = filter?.value?.map(item => item.id);
        } else {
          this.contactType = undefined;
        }
      }
    }

    // pagination
    this.pageNumber = event?.first !== undefined ? event.first/event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchContacts();
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }
}


