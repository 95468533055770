<div [formGroup]="addressAutocompleteForm" class="w-full white">
  <p-autoComplete
    #autocompleteInput
    id="query"
    formControlName="query"
    [suggestions]="addressResults"
    placeholder="Adresse suchen..."
    [showEmptyMessage]= "true"
    (onSelect)="selectAddress($event)"
    (onFocus)="resetLastGroup()"

    field="label"
    [style]="{
      'width': '99%',
      'padding': '0.5rem',
    }"
    [inputStyle]=" {
      'border': 'none',
      'box-shadow': 'none',
      'outline': 'none'
    }"
    #addressQuery>

    <ng-template let-option pTemplate="item">
      <ng-container *ngIf="googleAutocompleteAcitve">
        <!-- Group Title -->
        <div *ngIf="option.group && checkGroup(option?.group)">
          <strong>{{ option.group }} ({{ option.count }})</strong>
          <hr />
        </div>
        <!-- Option Item -->
        <div>{{ option.label }}</div>
      </ng-container>

      <!-- Standard Items -->
      <ng-container *ngIf="!googleAutocompleteAcitve">
        <div>{{ option.label }}</div>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="empty">
      <span class="italic">Keine Ergebnisse</span>
    </ng-template>
  </p-autoComplete>
</div>
