import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Resource } from '../../entities/resource/resource';

export const UPDATE_RESOURCE_MUTATION = gql`
mutation updateResource($resourceInput: ResourceInput) {
  updateResource(input: $resourceInput) {
    id
  }
}
`;

export const CREATE_RESOURCE_MUTATION = gql`
mutation createResource($resourceInput: ResourceInput) {
  createResource(input: $resourceInput) {
    id
  }
}
`;

export const DELETE_RESOURCE_MUTATION = gql`
mutation deleteResource($id: ID) {
  deleteResource(id: $id) {
    id
  }
}
`;

export const RESOURCES_QUERY = gql`
query resourcesQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String, $resourceNumber: String)
{
  resources: getResourcesPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
      number: $resourceNumber
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
        id
        number
      }
  }
}
`;

export interface ResourcesQueryResponse {
  resources: Page<Resource>;
  loading: boolean;
}

export const NUMBER_NOT_TAKEN_QUERY = gql`
query resourceExists($resourceNumber: String)
{
  resourceExists: resourceExists(number: $resourceNumber)
}
`;

export interface NumberNotTakenQueryResponse {
  resourceExists: boolean;
  loading: boolean;
}
