import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesService } from '@nexato/nx-core-module';
import { LicenseService } from '../shared-module/shared/services/license.service';


@Injectable({
  providedIn: 'root',
})
export class PermissionGuard  {
  constructor(
    private rolesService: RolesService,
    protected readonly router: Router,
    public licenseService: LicenseService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let istActive = next?.data?.['permission']? this.rolesService.hasRole(next?.data?.['permission']): true;

    // console.log('next', next)

    if (!this.licenseService?.isLicenceUser()) {
      this.router.navigate(['no-license']);
      istActive = false;
    } else if (!istActive) {
      this.router.navigate(['app-no-access-page']);
    }
    return istActive;
  }
}
