import { DateTimePeriod } from "../dateTimePeriod/dateTimePeriod";

export class TaskAssignmentSuggestion {
    assigneeId: string;
    assignmentType: string;
    dueDateTimePeriod: DateTimePeriod;
    typeLabel: string;
    name: string;

    constructor(payload?: any) {
      if (payload) {
        this.assigneeId = payload.assigneeId;
        this.assignmentType = payload.assignmentType;
        this.dueDateTimePeriod = new DateTimePeriod(payload.dueDateTimePeriod);
        this.typeLabel = payload.typeLabel;
        this.name = payload.name;
      }
    }
}
