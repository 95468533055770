import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private keycloakService: KeycloakService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.keycloakService.isLoggedIn()).pipe(
      switchMap((isLoggedIn) => {
        if (!isLoggedIn) {
          this.keycloakService.login();
          return throwError(() => new Error('User not logged in'));
        }

        return from(this.keycloakService.updateToken(5)).pipe(
          switchMap(() => {
            const token = this.keycloakService.getKeycloakInstance().token;
            const clonedRequest = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
            return next.handle(clonedRequest);
          }),
          catchError((error) => {
            // If token refresh fails, redirect to login
            this.keycloakService.login();
            return throwError(() => new Error('Token refresh failed'));
          })
        );
      }),
      catchError(() => {
        // If login status check fails, redirect to login
        this.keycloakService.login();
        return throwError(() => new Error('Error checking login status'));
      })
    );
  }
}
