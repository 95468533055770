import { Apollo } from 'apollo-angular';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';
import { NXDataSourcePrime } from '@nexato/nx-core-module';

export class OrderDataSource extends NXDataSourcePrime<any> {
  public locations : LocationEntity[];
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.ORDERS_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.OrdersQueryResponse): void {
    this.locations = data?.locations;
    super.setSubject(
      data.orders?.content ? data.orders?.content : [],
      data.orders?.totalElements ? data.orders.totalElements : 0
    );
  }

  loadorders(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
