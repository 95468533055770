import { gql } from 'apollo-angular';
import { Comment } from '../../../order-module/shared/entities/comment/comment';

export const COMMENTS_QUERY = gql`
query OrderCommentsQuery(
  $ownerId: UUID,
  $ownerType: String,
  $sortProperty: String,
  $sortDirection: String,
)
{
  comments: getComments(
    filter:{
      ownerId: $ownerId,
      ownerType: $ownerType,
      sortRequest: {sortProperties: [$sortProperty], sortDirection: $sortDirection},
    }){
      id
      text
      lastModifiedBy {
        id
        firstName
        lastName
      }
      lastModifiedDateTime
    }
}
`;

export interface CommentsQueryResponse {
  comments: Comment[];
  loading: boolean;
}


