<div>
  <nx-pageTitle-box
    [label]="location?.name"
    [bcRootLvlLabel]="'Einstellungen'"
    [bcRootLvlPath]="'settings'"
    [bcRootLvl1Label]="'Niederlassungen'"
    [bcRootLvl1Path]="'settings/locations'"
    [icon]="'icon-branches'"
    [iconToolTip]="'Niederlassung'">

    <ng-template nxTemplate="end">
      <nx-default-button
        *ngIf="rolesService.hasRole('nexcore_location_delete')"
        (click)="location?.isDeletable ? deleteLocation() : null"
        [label]="'löschen'"
        [tooltip]="'Niederlassung löschen'"
        [style]="'transparent'"
        [disabled]="!location?.isDeletable"
        icon="icon icon-switch">
      </nx-default-button>

    </ng-template>
  </nx-pageTitle-box>

<p-tabView [scrollable]="true">
  <p-tabPanel header="Allgemein">
    <div class="flex flex-row gap-4 mt-2">
      <div
        class="basis-1/2">
        <nx-headline-box
          [label]="'Niederlassungsdaten'">
          <ng-template nxTemplate="end">
            <nx-default-button
              *ngIf="rolesService.hasRole('nexcore_location_update')"
              (click)="editLocation()"
              [tooltip]="'Niederlassung bearbeiten'"
              [style]="'light'"
              [icon]="'icon-edit'">
            </nx-default-button>
          </ng-template>
        </nx-headline-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'Name'">
          {{ location?.name }}
        </nx-default-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'Label'">
          {{ location?.label }}
        </nx-default-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'Identifier'">
          {{location?.externalId}}
        </nx-default-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'E-Mail'">
          {{location?.email}}
        </nx-default-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'Telefon'">
          {{location?.phoneNumber}}
        </nx-default-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'Web'">
          {{location?.web}}
        </nx-default-box>
        <nx-default-box
          class="bg-white border-b border-gray"
          [label]="'Typ'">
          {{getTypeString(location?.type)}}
        </nx-default-box>
      </div>
      <div
        class="basis-1/2">
        <nx-headline-box
          [label]="'Anschrift'">
          <ng-template nxTemplate="end">
            <nx-default-button
              *ngIf="rolesService.hasRole('nexcore_location_update')"
              (click)="editLocationAddress()"
              [style]="'light'"
              [icon]="'icon-edit'">
            </nx-default-button>
          </ng-template>
        </nx-headline-box>
        <nx-default-box
        class="bg-white border-b border-gray"
        [label]="'Adresse'">
        {{location?.address?.getAddressString()}}
      </nx-default-box>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Einstellungen" class="general-settings">
    <nx-headline-box
      class="mb-2"
      [label]="'Einstellungen der Niederlassung'">
      <ng-template nxTemplate="tag">
        <span class="text-[0.75rem] relative top-1">Überschreiben allgemeiner Einstellungen diese Niederlassungen</span>
      </ng-template>

      <ng-template nxTemplate="end">
        <nx-default-button
          *ngIf="!settingsForm?.enabled && rolesService.hasRole('nexcore_location_update')"
          (click)="editSettings()"
          [tooltip]="'Einstellungen bearbeiten'"
          [style]="'light'"
          [icon]="'icon-edit'">
        </nx-default-button>

        <nx-default-button
          *ngIf="settingsForm?.enabled"
          [style]="'light'"
          class="default-button ml-1"
          (click)="abortEditSettings()"
          label="Abbrechen">
        </nx-default-button>

        <nx-default-button
          *ngIf="settingsForm?.enabled"
          [style]="'light'"
          class="default-button ml-1 confirm"
          (click)="updateSettings()"
          label="Speichern">
        </nx-default-button>

      </ng-template>
    </nx-headline-box>
    <app-settings-form [form]="settingsForm" [settings]="settings" [locationId]="location?.id"></app-settings-form>
  </p-tabPanel>

</p-tabView>

<div class="flex flex-row gap-4 mt-2">
  <div
    class="basis-1/2">

  </div>
  <div
    class="basis-1/2">

  </div>
</div>


