import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditDialogComponent, EditDialogInstance, LoadingService, ToastService } from '@nexato/nx-core-module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Task } from '../../shared/entities/task/task';
import { LocationService } from '../../shared/services/location/location.service';
import { TasksService } from '../../shared/services/tasks/tasks.service';

@Component({
  selector: 'app-assignment-dialog',
  templateUrl: './assignment-dialog.component.html',
  styleUrls: ['./assignment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignmentDialogComponent
  implements EditDialogInstance {

  assignmentFormGroup: FormGroup;
  task: Task;
  public assignemtTypeOptions: any[] = [];
  public assigneeOptions: any[] = [];
  public types = [
    { id: 'COMPANY', name: 'Firma' },
    { id: 'DEPOT', name: 'Lager' },
    { id: 'TOURBASE', name: 'Tourbasis' }
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public locationService: LocationService,
    public loadingService: LoadingService,
    public taskService: TasksService,
    private toastService: ToastService
  ) {
    if (this.config.data) {
      this.task = this.config.data;
      this.assignemtTypeOptions = this.getAssignmentTypeOptions();
      if(this.task.assignmentStrategyCandidates){
        this.task.assignmentStrategyCandidates.map((candidate) => {
          if(candidate.type === this.task.assignmentSuggestion?.assignmentType){
            // currently wie support only assigneeId
            let assigneeId = candidate?.settings.find((setting) => setting.key === 'assigneeId');
            if(assigneeId){
              this.assigneeOptions = assigneeId?.settings?.options.map((option: any) => {
                return {
                  id: option.id,
                  name: option.label
                }
              });
            }
          }
          return {
            id: candidate.id,
            name: candidate.label
          }
        });
        
      }
    }
    this.assignmentFormGroup = new FormGroup({});

    // // add form controls
    this.assignmentFormGroup.addControl('taskId', new FormControl(this.task?.id));
    this.assignmentFormGroup.addControl('assignmentType', new FormControl(this.task?.assignmentSuggestion?.assignmentType, [Validators.required]));
    this.assignmentFormGroup.addControl('assigneeId', new FormControl(this.task?.assignmentSuggestion?.assigneeId, [Validators.required]));
    this.assignmentFormGroup.addControl('dueDateTimePeriod', new FormControl(this.task?.assignmentSuggestion?.dueDateTimePeriod, [Validators.required]));

    // if the value were set during initialization we must mark the form as dirty
    if(this.assignmentFormGroup.valid){
      this.assignmentFormGroup.markAsDirty();
    }
  }

  getAssignmentTypeOptions(): any[] {
    return this.task.assignmentStrategyCandidates.map((candidate) => {
      return {
        id: candidate.type,
        name: candidate.label
      }
    });
  }

  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    this.loadingService.setLoading(true);
    this.taskService.assignTask(this.assignmentFormGroup.value, () => {
      this.toastService.addToastWithMessage('Aufgabe zugewiesen.');
      this.ref.close(true);
      // we´re faking here the loading time... todo: remove this
      // and take care, that the refetch discards the spinner
      setTimeout(() => {
        this.loadingService.setLoading(false);
      }, 100);
    })  
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.assignmentFormGroup;
  }

}
