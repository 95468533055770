
export class AssignmentStrategyCandidate {
  id: string;
  type: string;
  label: string;
  dueDateTimePeriod: any;
  settings: any;


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.type = payload.type;
      this.label = payload.label;
      this.dueDateTimePeriod = payload.dueDateTimePeriod;
      this.settings = payload.settings;
    }
  }

}

