import { Component, Input } from '@angular/core';
import { Protocol } from 'src/app/rent-module/shared/entities/protocol/protocol';
import { ResourceAssignment } from 'src/app/rent-module/shared/entities/resourceAssignment/resourceAssignment';

@Component({
  selector: 'app-order-rent-resourceAssignment-protocols-view, [app-order-rent-resourceAssignment-protocols-view]',
  templateUrl: './order-rent-resourceAssignment-protocols-view.component.html',
  styleUrls: ['./order-rent-resourceAssignment-protocols-view.component.scss']
})
export class OrderRentResourceAssignmentProtocolsViewComponent {
  
  // public outCheck: Protocol; // for later use
  public resourceOut: Protocol;
  public resourceIn: Protocol;
  public refuel: Protocol;
  // public inCheck: Protocol; // for later use

  _resourceAssignment: ResourceAssignment;
  @Input() set resourceAssignment(resourceAssignment: ResourceAssignment) {
    if (resourceAssignment) {
      console.log(this.resourceAssignment?.protocols);
      this._resourceAssignment = resourceAssignment;
      this._resourceAssignment.protocols?.forEach(protocol => {
        if (protocol.type === 'protocol.rental.resourceOut') {
          this.resourceOut = protocol;
        }
        if (protocol.type === 'protocol.rental.resourceIn') {
          this.resourceIn = protocol;
        }
        if (protocol.type === 'protocol.rental.refuel') {
          this.refuel = protocol;
        }
      })

    }

  }

  getProtocolStateLabel(protocol: Protocol): string | undefined {
    return Protocol.getStateLabel(protocol);
  }

  getProtocolStateSeverity(protocol: Protocol): string | undefined {
    return Protocol.getStateSeverity(protocol);
  }

  inOrOutProtocolAvailable(): boolean {
    return this.resourceIn !== undefined || this.resourceOut !== undefined;
  }

  refuelProtocolAvailable(): boolean {
    return this.refuel !== undefined;
  }

  anyProtocolAvailable(): boolean {
    return this.inOrOutProtocolAvailable() || this.refuelProtocolAvailable();
  }
}
