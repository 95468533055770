import { gql } from 'apollo-angular';
import { Order } from '../../shared/entities/order/order';

export const ORDER_QUERY = gql`
query getOrderQuery(
  $orderId: UUID, $entity: String
)
{
  order: getOrder(
    id: $orderId
  )
  {
    id
    type
    number
    state
    archive
    location {
      name
    }
    lineItems {
      id
      text
      article {
        text
        number
        name
      }
      index
      indexText
      startDateTime
      endDateTime
      resourceAssignments {
        free
        text
        protocols {
          id
          type
          state
          completedDateTime
          completedBy {
            firstName
            lastName
          }
          formControlTemplate
          task{
            id
            assignment {
              assigneeId
              assignmentType
              typeLabel
              name
            }
          }
        }
        startDateTime
        endDateTime
        customAttributes {
          id
          name
          value
          customAttributeDefinition {
            id
          }
          customAttributeSection {
            id
          }
        }
        resource {
          id
          text
          name
          number
          functions
        }
        effectiveSchedulingInformation{
          inHandlingType
          inDateTimePeriod {
            dateTime
            fromDateTime
            toDateTime
            modifier
          }
          inAddress {
            street
            houseNumber
            postalCode
            city
            country
            geocodingResultGrade
            name
            note
          }
          outHandlingType
          outDateTimePeriod {
            dateTime
            fromDateTime
            toDateTime
            modifier
          }
          outAddress{
            street
            houseNumber
            postalCode
            city
            country
            geocodingResultGrade
            name
            note
          }
          onSiteContacts {
            name
            firstName
            lastName
          }
          inLocation{
            name
          }
          outLocation{
            name
          }
        }
      }
    }
    customer {
      id
      type
      companyName
      companyNameExtension
      firstName
      lastName
      number
      email
    }
    customerOrderNumber
    orderer{
      firstName
      lastName
      name
    }
    note
    customAttributes {
      id
      name
      value
      customAttributeDefinition {
        id
      }
      customAttributeSection {
        id
      }
    }
    files {
      id
    }
  }
  tasksCount : getTasksCount(taskFilter: {
    orderId:  $orderId
  })
  tasksCountUnassigned : getTasksCount(taskFilter: {
    orderId:  $orderId, states:  [NEW, UNASSIGNED]
  })
  tasksCountAssigned : getTasksCount(taskFilter: {
    orderId:  $orderId, states:  [ASSIGNED]
  })
  tasksCountCompleted : getTasksCount(taskFilter: {
    orderId:  $orderId, states: [COMPLETED]
  })
  commentsCount: getCommentsCount(filter: {
    ownerId:  $orderId
  })
  customAttributeSections: getCustomAttributeSections(filter: {
    entity: $entity
  }
    ) {
      id
      label
      index
      customAttributeDefinitions {
        id
        type
        label
        index
        key
      }

    }
}
`;


export interface OrderQueryResponse {
  order: Order;
  loading: boolean;
  tasksCount: any;
  tasksCountAssigned: any;
  tasksCountUnassigned: any;
  tasksCountCompleted: any;
  commentsCount: any;
  customAttributeSections: any;
}
