<ng-container [ngSwitch]="controlTemplate?.type">
    
    <ng-container *ngSwitchCase="'checkBoxInput'">
        <app-checkboxInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-checkboxInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'numberInput'">
        <app-numberInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-numberInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'refuelInput'">
        <app-refuelInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-refuelInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'rangeInput'">
        <app-rangeInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-rangeInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'fuelInput'">
        <app-fuelInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-fuelInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'chargeInput'">
        <app-chargeInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-chargeInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'pictureInput'">
        <app-pictureInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-pictureInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'picturesInput'">
        <app-picturesInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-picturesInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'commentInput'">
        <app-commentInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-commentInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'signatureInput'">
        <app-signatureInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-signatureInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'selectInput'">
        <app-selectInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-selectInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'textInput'">
        <app-textInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-textInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchCase="'rangeInput'">
        <app-rangeInput-control-value-view
            [controlTemplate]="controlTemplate">
        </app-rangeInput-control-value-view>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *ngIf="!controlTemplate; else missingControlType">-</ng-container>
        <ng-template #missingControlType>
            missing {{controlTemplate?.type}}
        </ng-template>
    </ng-container>
</ng-container>