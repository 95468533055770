import { gql } from 'apollo-angular';
import { Address } from '../../shared/entities/address/address';

export const ADDRESS_DIALOG_QUERY = gql`
query GetAddress(
  $addressId: UUID
)
{
  address: getAddress(
    id: $addressId
  ) {
    id
    type
    activeSource
    country
    city
    postalCode
    street
    houseNumber
    lat
    lng
    note
    text
    geocodingResultGrade
    name
    externalAddress
    ref
  }
}
`;


export interface AddressDialogQueryResponse {
  address: Address;
}


