import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { TableLazyLoadEvent } from 'primeng/table';
import { LineItem } from '../../shared/entities/lineItem/lineItem';
import { TasksService } from '../../shared/services/tasks/tasks.service';
import { TaskDataSource } from './taskDataSource';

@Component({
  selector: 'app-dashboard-task-list',
  templateUrl: './dashboard-task-list.component.html',
  styleUrls: ['./dashboard-task-list.component.scss']
})
export class DashboardTaskListComponent implements  OnDestroy, OnChanges{
  location: string;
  public states: string[] = ['NEW', 'ASSIGNED', 'DELEGATED', 'UNASSIGNED'];
  public types: string[];
  public displayType: string;
  public dataSource: TaskDataSource;
  private sortProperty = 'dueDateTimePeriod';
  private sortDirection = 'asc';
  pageNumber = 0;
  pageSize = 10;
  public loading = false;

  public defaultColumns = [
    'dueDateTimePeriod',
    'orderNumber',
    'orderCustomerName'
  ];

  public sortField = 'dueDateTimePeriod';
  public sortOrder = 1;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'dueDateTimePeriod', direction: 1},
    { label: 'Auftragsnummer aufsteigend', fieldName: 'order.number', direction: 1 },
    { label: 'Auftragsnummer absteigend', fieldName: 'order.number', direction: -1 },
  ];

  @Input() set filter(filter: any) {
    this.displayType = filter?.type;
    // console.log('type', type);
    if (this.displayType === 'in') {
      this.types = ['nexcore_rental_resourceAssignmenmt_in'];
    } else if(this.displayType === 'out') {
      this.types = ['nexcore_rental_resourceAssignmenmt_out'];
    } else {
      this.states = ['COMPLETED']
    }
    this.location = filter?.location;
    this.setTableDataSource();
    // console.log('customer id', this.customerId);
  }

  @Input() set selectedLocation(location: string){
    this.location = location;
  }
  public date = new Date();
  public initialDate = new Date();

  @Input() set setDate(date: any) {
    this.date = date;
  }
  constructor(
    private router: Router,
    private apollo: Apollo,
    private cdRef:ChangeDetectorRef,
    // public dialog: MatDialog,
    private loadingService: LoadingService,
    public taskService: TasksService,
    //public rolesService: RolesService
  ) {
   // this.setTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation) {
      this.location = changes.selectedLocation.currentValue
      this.refetchTasks();
    }
    if (changes.setDate) {
      this.date = changes.setDate.currentValue;
      this.refetchTasks();
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }


  setTableDataSource(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource = new TaskDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadTasks({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: 'dueDateTimePeriod',
      sortDirection: 'asc',
      states: this.states,
      types: this.types,
      locationIds: this.location,
      fromDate: fromDate,
      toDate: toDate,
      includeOverDue: false
    });
  }

  refetchTasks(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource?.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      states: this.states,
      types: this.types,
      locationIds: this.location,
      fromDate: fromDate,
      toDate: toDate,
      includeOverDue: false
    });
  }

  openTask(row: any) {

  }
  getResources(lineItems: LineItem[]) {
    let resources = [];
    for (let lineItem of lineItems) {
      for (let assignment of lineItem?.resourceAssignments) {
        resources.push(assignment?.resource?.name)
      }
    }
    return resources?( resources.join(', \n') ): '-';
  }
  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  getIcon(displayType: string): string {
    switch (displayType) {
      case 'out':
        return 'icon-pickup-out';
      case 'in':
        return 'icon-pickup-in';
      case 'completed':
        return 'icon-pickup_check';
    }
  }

  getHeadline(displayType: string): string {
    switch (displayType) {
      case 'out':
        return 'Abholungen';
      case 'in':
        return 'Rücknahmen';
      case 'completed':
        return 'Erledigte Aufgaben';
    }
  }


  loadData(event: TableLazyLoadEvent) {
    console.log(event);
    this.loading = true;
   // this.text = event?.globalFilter?.toString();

    // sorting
    if(event?.sortField !== undefined && typeof event?.sortField  == 'string') {
      this.sortProperty = event.sortField;
    }
    if(event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }


    // pagination
    this.pageNumber = event?.first !== undefined ? event.first/event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchTasks();
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }

  onPageChange(event) {
    this.pageNumber = event.page;
    this.pageSize = event.rows;
    this.refetchTasks();
  }
}
