import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Resource } from '../../shared/entities/resource/resource';

export const RESOURCES_QUERY = gql`
query resourcesQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String, $name: String, $locationIds : [UUID], $resourceNumber: String, $functions: [ResourceFunction], $text: String)
{
  resources: getResourcesPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
      name: $name,
      number: $resourceNumber,
      locationIds: $locationIds,
      functions: $functions,
      text: $text
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          number
	        name
          functions
          location {
            id
            name
          }
      }
  }
}
`;

export interface ResourcesQueryResponse {
  resources: Page<Resource>;
  loading: boolean;
}
