import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApolloService } from '@nexato/nx-core-module';
import * as fromGraphQl from './graphql';

@Injectable()
export class TaskService {

  constructor(
    private apolloService: ApolloService,
    private datePipe: DatePipe,
    // private customAttributeService: CustomAttributesService
  ) {
  }

  /**
   * Unassign a task
   * @param tasks the task
   * @param refetchQuery query to refetch after the update
   */
   public UnassignTask(taskId: string, callback: (arg: any) => void) {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UNASSIGN_MUTATION,
      {
        taskId
      },
      callback
    );
  }

  /**
   * Check tasks
   * @param tasks the tasks
   * @param refetchQuery query to refetch after the update
   */
  // public checkTasks(tasks: Task[], refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.CHECK_TASKS_MUTATION,
  //     {
  //       taskIds: tasks.map( task => task.id)
  //     },
  //     refetchQuery
  //   );
  // }

  /**
   * Check a task
   * @param tasks the task
   * @param refetchQuery query to refetch after the update
   */
  //  public checkTask(task: Task, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.CHECK_TASK_MUTATION,
  //     {
  //       taskId: task.id
  //     },
  //     refetchQuery
  //   );
  // }

  /**
   * Schedules tasks on a specific date.
   * @param tasks the tasks
   * @param date the date
   * @param refetchQuery query to refetch after the update
   */
  // public scheduleTasks(tasks: Task[], date: String, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.SCHEDULE_TASKS_MUTATION,
  //     {
  //       taskIds: tasks.map( task => task.id),
  //       date: date
  //     },
  //     refetchQuery
  //   );
  // }

  /**
   * Unschedules tasks.
   * @param tasks the tasks
   * @param refetchQuery query to refetch after the update
   */
  // public unscheduleTasks(tasks: Task[], refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.UNSCHEDULE_TASKS_MUTATION,
  //     {
  //       taskIds: tasks.map( task => task.id),
  //     },
  //     refetchQuery
  //   );
  // }

}
