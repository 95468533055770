import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { FilterMetadata } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { TasksService } from 'src/app/rent-module/shared/services/tasks/tasks.service';
import { Task } from '../../../rent-module/shared/entities/task/task';
import { OrderTaskListViewDataSource } from './orderTaskListViewDataSource';

@Component({
  selector: 'app-order-task-list-view',
  templateUrl: './order-task-list-view.component.html',
  styleUrls: ['./order-task-list-view.component.scss'],
})
export class OrderTaskListViewComponent implements OnDestroy {

  // properties are used as initial values and getting updated
  // by the table for refetching. They´re holoding the initial
  // values as well as the current values.
  private sortProperty = 'id';
  private sortDirection = 'asc';
  public states = ['NEW', 'ASSIGNED', 'COMPLETED'];
  public types = ['nexcore_rental_resourceAssignmenmt_out', 'nexcore_rental_resourceAssignmenmt_in', 'nexcore_rental_resourceAssignmenmt_refuel'];
  public typesModel: any[] = [];
  public statesModel: any[] = [];
  public _orderId: string | undefined;
  public description: string | undefined;

  // page number and size
  public pageNumber = 0;
  public pageSize = 20;
  public sortField = 'id';
  public sortOrder = 1;

  // indicates loading
  public loading = false;

  // states that are available for the filter
  public availableStates = [{
    id: 'NEW',
    name: 'Neu',
    label: 'Neu'
  }, {
    id: 'ASSIGNED',
    name: 'Zugewiesen',
    label: 'Zugewiesen'
  }, {
    id: 'COMPLETED',
    name: 'Erledigt',
    label: 'Erledigt'
  }];
  public availableTypes = [
   { id: 'nexcore_rental_resourceAssignmenmt_out', name: 'Übergabe', label: 'Übergabe' },
   { id: 'nexcore_rental_resourceAssignmenmt_in', name: 'Rücknahme', label: 'Rücknahme' },
   { id: 'nexcore_rental_resourceAssignmenmt_refuel', name: 'Nachtanken', label: 'Nachtanken' }
 ];
  public dataSource: OrderTaskListViewDataSource;

  @Input() set orderId(orderId: any) {
    this._orderId = orderId;
    this.setTableDataSource();
  }
  @Input() withMainHeadline = true;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: 1 },
    { label: 'Typ absteigend', fieldName: 'type', direction: -1 },
    { label: 'Status aufsteigend', fieldName: 'state', direction: 1 },
    { label: 'Status absteigend', fieldName: 'state', direction: -1},
    { label: 'Fälligkeit aufsteigend', fieldName: 'dueDateTimePeriod', direction: 1 },
    { label: 'Fälligkeit absteigend', fieldName: 'dueDateTimePeriod', direction: -1},
    { label: 'Erledigt aufsteigend', fieldName: 'completedDateTime', direction: 1 },
    { label: 'Erledigt absteigend', fieldName: 'completedDateTime', direction: -1},
    { label: 'Benutzer aufsteigend', fieldName: 'completedBy', direction: 1 },
    { label: 'Benutzer absteigend', fieldName: 'completedBy', direction: -1}
  ];

  constructor(
    private apollo: Apollo,
    public taskService: TasksService,
    private cdRef:ChangeDetectorRef
  ) { }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  loadData(event: TableLazyLoadEvent) {
    this.loading = true;

    // sorting
    if (event?.sortField !== undefined && typeof event?.sortField == 'string') {
      this.sortProperty = event.sortField;
    }
    if (event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }

    // filtering
    if (event?.filters.description !== undefined) {
      this.description = (event.filters.description as FilterMetadata)?.value;
    }
    // state
    if (event?.filters?.state !== undefined) {
      let filter = event.filters.state as FilterMetadata;
      if (filter?.value === undefined
        || filter.value === null
      ) {
        this.statesModel = null;
        this.states = null;
      }
    }
    if (this.statesModel !== undefined
      && this.statesModel !== null
      && this.statesModel.length > 0
    ) {
      const values = this.statesModel;
      this.states = values?.map(item => item.id);
    } else {
      this.states = null;
    }

    // type
    if (event?.filters?.type !== undefined) {
      let filter = event.filters.type as FilterMetadata;
      if (filter === undefined
        || filter === null
      ) {
        this.typesModel = null;
        this.types = null;
      } else {
        if(filter?.value?.length > 0){
          this.types = filter?.value?.map(item => item.id);
        } else {
          this.types = undefined;
        }
      }
    }
    // pagination
    this.pageNumber = event?.first !== undefined ? event.first : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 10;

    this.refetchloadTaskList();
  }

  setTableDataSource(): void {
    this.dataSource = new OrderTaskListViewDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    if (this._orderId) {
      this.dataSource.loadTaskList({
        orderId: this._orderId,
        states: this.states,
        types: this.types,
        description: this.description,
        sortProperty: this.sortProperty,
        sortDirection: this.sortDirection,
        number: this.pageNumber,
        size: this.pageSize,
      });
    }
  }

  refetchloadTaskList(): void {
    this.dataSource.refetchQuery({
      orderId: this._orderId,
      states: this.states,
      types: this.types,
      description: this.description,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      number: this.pageNumber,
      size: this.pageSize,
    });
  }

  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }

  public getStateLabel(state: string): string | undefined {
    return Task.getStateLabelFromString(state);
  }

  public getTypeLabel(type: string): string | undefined {
    return Task.getTypeLabelFromString(type);
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }


}
