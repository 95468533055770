import {gql} from 'apollo-angular';

export const UNASSIGN_MUTATION = gql`
mutation unassignTask($taskId: UUID) {
  unassignTask(taskId: $taskId) {
    id
  }
}
`;

// export const CHECK_TASKS_MUTATION = gql`
// mutation checkTasks($taskIds: [ID]) {
//   checkTasks(taskIds: $taskIds) {
//     id
//   }
// }
// `;

// export const CHECK_TASK_MUTATION = gql`
// mutation checkTask($taskId: ID) {
//   checkTask(taskId: $taskId) {
//     id
//   }
// }
// `;

// export const SCHEDULE_TASKS_MUTATION = gql`
// mutation scheduleTasks($taskIds: [ID], $date: String) {
//   scheduleTasks(taskIds: $taskIds, date: $date) {
//     id
//   }
// }
// `;

// export const UNSCHEDULE_TASKS_MUTATION = gql`
// mutation unscheduleTasks($taskIds: [ID]) {
//   unscheduleTasks(taskIds: $taskIds) {
//     id
//   }
// }
// `;

// export const SPLIT_TASK_MUTATION = gql`
// mutation splitTask($taskId: ID, $taskPositions: [ID]) {
//   splitTask(taskId: $taskId, taskPositions: $taskPositions) {
//     id
//   }
// }
// `;

// export const ASSIGN_OPERATOR_TO_TASK_MUTATION = gql`
// mutation assignOperatorToTask($taskId: ID, $operatorId: String) {
//   assignOperatorToTask(taskId: $taskId, operatorId: $operatorId){
//     id
//     assignee{
//       id
//     }
//   }
// }
// `;

// export const REMOVE_OPERATOR_FROM_TASK_MUTATION = gql`
// mutation removeOperatorFromTask($taskId: ID) {
//   removeOperatorFromTask(taskId: $taskId){
//     id
//     assignee{
//       id
//     }
//   }
// }
// `;

// export const ADD_REFILL_MUTATION = gql`
// mutation addRefill($taskId: Int, $value: Float, $protocolId: String) {
//   addRefill(taskId: $taskId, value: $value, protocolId: $protocolId){
//     id
//   }
// }
// `;

