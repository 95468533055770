import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LicenseService } from '../../shared/services/license.service';

@Component({
  selector: 'app-non-license-page',
  templateUrl: './non-license-page.component.html',
  styleUrls: ['./non-license-page.component.scss']
})
export class NonLicensePageComponent implements AfterViewInit{

  @Input() licenseType: string = 'Full User';

  constructor(
    public licenseService: LicenseService,
    public router: Router) { }
  
  ngAfterViewInit(): void {
    // check, if the user has a licence. If yes, redirect to dashboard
    if(this.licenseService?.isLicenceUser()){
      this.router.navigate(['/dashboard']);
    }
  }

}
