import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Container, LoadingService, RolesService, SettingsService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { MenuItem } from 'primeng/api';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
  host: {
    'class': 'general-settings'
  },
})
export class GeneralSettingsComponent
  extends Container<fromGraphQl.SettingsQueryResponse>
  implements OnInit
{
  public terms: boolean;
  public settings: any;
  termsControl = new UntypedFormControl();
  formCtrlSub: any;
  form: UntypedFormGroup;
  isEditable = false;
  @Input() withMainHeadline = true;
  public pageTitleMenuItems: MenuItem[]

  constructor(
    apollo: Apollo,
    loadingService: LoadingService,
    private http: HttpClient,
    public rolesService: RolesService,
    public settingsService: SettingsService,
    private fb: UntypedFormBuilder,
  ) {
    super(apollo, loadingService, fromGraphQl.SETTINGS_QUERY, environment);
    //this.pageTitleMenuItems = this.getAvailablePageTitleItems();
  }

  ngOnInit(): void {
    this.subscribeToQuery();
  }
  handleSubscriptionResult(data: fromGraphQl.SettingsQueryResponse): void {
    this.settings = data?.terms;
    this.form = this.createGroup();
    if (!this.isEditable) {
      this.form.disable();
    }
  }


  save(key: string, value: any) {
    let settingInput:any = {};
    settingInput = {
      "key": key,
      "value": value
    };
    this.settingsService.updateSettings(
      settingInput,
      this.returnCallback()
    );
  }
  returnCallback(): void {
      console.log('saved');
  }

  createGroup() {
    return this.settingsService.createSettingsFormGroup(this.settings);
  }

  abortEditSettings(): void {
    window.location.reload();
  }

  updateSettings() {
    Object.keys(this.form.controls).forEach((name) => {
      const currentControl = this.form.controls[name];
      console.log(currentControl.dirty);
      if (currentControl.dirty) {
       this.save(name, currentControl?.value);
      }
    });
    this.isEditable = false;
    this.form.disable();
  }

  editSettings() {
    this.form?.enable();
    this.isEditable = true;
    this.pageTitleMenuItems = [];
  }

}
