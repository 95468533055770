import { gql } from 'apollo-angular';

export const FILES_QUERY = gql`
query OrderFilesQuery(
  $ownerId: UUID,
  $sortProperty: String,
  $sortDirection: String,
)
{
  files: getFiles(
    filter:{
      ownerId: $ownerId,
      sortRequest: {sortProperties: [$sortProperty], sortDirection: $sortDirection},
    }){
      id
      fileName
      fullPath
      uploadedDateTime
      extension
      size
  }
}
`;

export interface FilesQueryResponse {
  files: File[];
  loading: boolean;
}


