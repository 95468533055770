import {gql} from 'apollo-angular';
import { Page } from '@nexato/nx-core-module';
import { Contact } from '../../shared/entities/contact/contact';

export const CUSTOMERS_QUERY = gql`
query customersQuery($number: Int, $size: Int, $sortProperty: String, $sortDirection: String, $name: String, $types: [ContactType], $contactNumber: String)
{
  customers: getContactPaged(
    filter: {
      sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
      name: $name,
      types: $types,
      number: $contactNumber
    }

    ) {
      totalPages
      totalElements
      size
      content{
          id
          type
          companyName
          firstName
          lastName
          number
          address {
            street
            postalCode
            city
            houseNumber
            country
          }
      }
  }
}
`;

export interface customersQueryResponse {
  customers: Page<Contact>;
  loading: boolean;
}
