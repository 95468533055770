import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Container, EditDialogService, ExpandService, LoadingService, RolesService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { environment } from 'src/environments/environment';
import { ResourceDialogComponent } from '../../components/resource-dialog-component/resource-dialog.component';
import { Resource } from '../../shared/entities/resource/resource';
import { LocationService } from '../../shared/services/location/location.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-resource-view',
  templateUrl: './resource-view.component.html',
  styleUrls: ['./resource-view.component.scss']
})
export class ResourceViewComponent extends Container<fromGraphQl.ResourceQueryResponse>
implements OnInit, OnDestroy
{
public routeSubscription: Subscription;
public id: string;
public resource: Resource;
public customAttributeSections: any;
activeIndex: number = 0;
functionName:string = '';
locations: LocationEntity[];

dialogRef: DynamicDialogRef | undefined;

constructor(
  apollo: Apollo,
  loadingService: LoadingService,
  public router: Router,
  public route: ActivatedRoute,
  public dialog: MatDialog,
  public rolesService: RolesService,
  public expandService: ExpandService,
  private dialogService: EditDialogService,
  private locationService: LocationService
) {
  super(apollo, loadingService, fromGraphQl.RESOURCE_QUERY, environment);
  this.locationService?.getLocations()?.pipe(
    take(1)
  ).subscribe((locations) => {
    this.locations = locations;
  });
}

ngOnInit(): void {
  const key = 'id';
  this.routeSubscription = this.route.params
    .pipe(
      filter((params) => params[key] !== undefined),
      take(1)
    )
    .subscribe((params) => {
      if (params[key] && params[key] !== 'create') {
        this.id = params[key];
        this.subscribeToQuery({
          resourceId: this.id,
          entity: 'Resource'
        });
      }
      // load model (if we have an id) and the form

    });
    this.expandService.setExpandState(false);
}
handleSubscriptionResult(data: fromGraphQl.ResourceQueryResponse): void {
  this.loadingService.setLoading(false);
  if (data) {
    this.queryRef.stopPolling();
    if (data?.resource) {
      this.resource = new Resource(data.resource);
      this.functionName = this.resource?.getFunctionName();
    }
    console.log('function name', this.functionName);
  }
  this.customAttributeSections = data?.customAttributeSections;
}

  /**
   * edit the resource dialog
   * ResourceDialogComponent
   */
  editResource(): void {
    this.dialogRef = this.dialogService.open(ResourceDialogComponent, {
      header: 'Ressource bearbeiten',
      data: {
        resource: this.resource,
        locations: this.locations
      }
    });
    this.dialogRef.onClose.subscribe((result) => {
      console.log('Result in View', result)

      // result is true or false, indiciating if it should refetch
      if (result){
        this.queryRef.refetch({
          resourceId: this.id,
          entity: 'Resource'
        });
      }
    });
  }



  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }
  CheckSectionInAttributes( id: string) {
    if (this.resource?.customAttributes) {
      for (let attribute of this.resource?.customAttributes){
        if (attribute?.customAttributeSection.id === id){
          return true;
        }
      }
    }
    return false;
  }

  CheckDefintionInAttributes(id: string) {
    if (this.resource?.customAttributes) {
      for (let attribute of this.resource?.customAttributes) {
        if (attribute?.customAttributeDefinition?.id === id) {
          return true;
        }
      }
    }
    return false;
  }
  getValue(id: string) {
    if (this.resource?.customAttributes) {
      for (let attribute of this.resource?.customAttributes) {
        if (attribute?.customAttributeDefinition?.id === id) {
          return attribute?.value;
        }
      }
    }
    return '-';
  }
  getAvailablePageTitleItems() {
    let pageTitleMenuItems: any[] = [];
    if (this.rolesService.hasRole('nexcore_resource_update')) {
      pageTitleMenuItems.push({
        label: 'Ressource bearbeiten',
        command: () => {
          this.editResource();
        }
      });
    }
    return pageTitleMenuItems;
  }

}
