import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EditDialogService, LoadingService, NXDataSourcePrime, RolesService, ToastService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { ConfirmationService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { LocationEntity } from '../../../shared-module/shared/entities/location/location';
import { LocationService } from '../../shared/services/location/location.service';
import { LocationDialogComponent } from '../location-dialog/location-dialog.component';
import { LocationDataSource } from './locationsDataSource';

@Component({
  selector: 'app-locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss'],
  providers: [ConfirmationService]
})
export class LocationsListComponent implements OnInit, OnDestroy {

  dialogRef: DynamicDialogRef | undefined;

  public sortProperty: string;
  public defaultSortProperty: string = 'id';
  public sortDirection: string;
  public defaultSortDirection: string = 'asc';
  public pageNumber = 0;
  public pageSize = 15;
  public sortModel: any;
  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: "asc"},
    { label: 'Name aufsteigend', fieldName: 'name', direction: "asc"},
    { label: 'Name absteigend', fieldName: 'name', direction: "desc" }
  ];

  public dataSource: LocationDataSource;
  public loading = false;

  constructor(
    private router: Router,
    private apollo: Apollo,
    private loadingService: LoadingService,
    public dialog: MatDialog,
    public locationService: LocationService,
    public rolesService: RolesService,
    private cdRef: ChangeDetectorRef,
    public dialogService: EditDialogService,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.dataSource = new LocationDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadLocations({
      pageNumber: 0,
      pageSize: 15,
      sortProperty: this.sortProperty ? this.sortProperty : this.defaultSortProperty,
      sortDirection: this.sortDirection ? this.sortDirection : this.defaultSortDirection,
    });
  }

  refetchLocations(force = false): void {
    this.dataSource.refetchQuery({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortProperty: this.sortProperty ? this.sortProperty : this.defaultSortProperty,
      sortDirection: this.sortDirection ? this.sortDirection : this.defaultSortDirection,
    }, force);
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  loadData(event: TableLazyLoadEvent) {
    this.loading = true;

    // sorting
    if(event?.sortField !== undefined && typeof event?.sortField  == 'string') {
      this.sortProperty = event.sortField;
    }
    if(event?.sortOrder !== undefined) {
      this.sortDirection = NXDataSourcePrime.translateSortOrderToSortDirection(event.sortOrder);
    }

    // pagination
    this.pageNumber = event?.first !== undefined ? event.first/event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchLocations();
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortProperty = event.value.fieldName;
      this.sortDirection = event.value.direction;
    } else {
      this.sortProperty = this.defaultSortProperty;
      this.sortDirection = this.defaultSortDirection;
    }
    this.cdRef.detectChanges();
  }

  translateSortDirectionToSortOrder(sortDirection: string): number {
    return NXDataSourcePrime.translateSortDirectionToSortOrder(sortDirection);
  }

  createLocation(): void {
    this.dialogRef = this.dialogService.open(LocationDialogComponent, {
      header: 'Niederlassung erstellen'
    });
    this.dialogRef.onClose.subscribe((result) => {
      // result is true or false, indiciating if it should refetch
      if(result){
        this.refetchLocations(true);
        this.loadingService.setLoading(false);
        this.toastService.addToastWithAction('Niederlassung erfolgreich erstellt.', 'Anzeigen', () => {
          this.router.navigate(['./settings/locations/location/' + result]);
        });
      }
    });
  }

  editLocation(location: LocationEntity) {
    this.router.navigate(['./settings/locations/location/' + location.id]);
  }


}
