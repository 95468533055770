import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-chargeInput-control-value-view, [app-chargeInput-control-value-view]',
  templateUrl: './chargeInput-control-value-view.component.html',
  styleUrls: ['./chargeInput-control-value-view.component.scss']
})
export class ChargeInputControlValueViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

  showLevelVisible(): boolean {
    return this.controlTemplate?.settings?.showLevelVisibleInput;
  }

  showLevelInput(): boolean {
    return this.controlTemplate?.settings?.showLevelInput;
  }

  getLevelAsPercentage(): any {
    if(this.controlTemplate?.settings?.value?.level != undefined && !isNaN(this.controlTemplate?.settings?.value?.level)) {
      return this.controlTemplate?.settings?.value?.level * 100
    }
    return "-";
  }

  showPictureInput(): boolean {
    return this.controlTemplate?.settings?.showPictureInput && this.controlTemplate?.settings?.value?.picture;
  }
}
