import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { EditDialogComponent, EditDialogInstance, LoadingService, RolesService, User } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { take } from 'rxjs';
import { Tour, TourInput } from '../../shared/entities/tour';
import * as fromGraphQl from './graphql';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-tour-settings-dialog",
  templateUrl: "tour-settings-dialog.html",
  styleUrls: ["./tour-settings-dialog.scss"]
})
export class TourSettingsDialogComponent
implements EditDialogInstance{

  tour: Tour;
  drivers = [];
  departureAddresses = [];
  arrivalAddresses = [];
  public tourFormGroup: FormGroup;
  // public resources: Resource[];


  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private datePipe: DatePipe,
    public rolesService: RolesService,
    private apollo: Apollo,
    public loadingService: LoadingService,
  ) {
    this.tourFormGroup = new FormGroup({});
    // add form controls
    this.tourFormGroup.addControl('resource', new FormControl());
    this.tourFormGroup.addControl('assignee', new FormControl());
    this.tourFormGroup.addControl('dateRange', new FormControl());
    this.tourFormGroup.addControl('departureAddress', new FormControl());
    this.tourFormGroup.addControl('arrivalAddress', new FormControl());
    this.tourFormGroup.addControl('startDateControl', new FormControl());
    this.tourFormGroup.addControl('endDateControl', new FormControl());
    this.tourFormGroup.addControl('description', new FormControl());
    this.apollo.query<fromGraphQl.TourSettingsDialogQueryResponse>({
      query: fromGraphQl.TOUR_SETTINGS_DIALOG_QUERY,
      variables: {
        tourId: this.config?.data.tour.id
      },
      fetchPolicy: 'network-only',
    })
    .pipe(take(1))
    .subscribe(data => {
      console.log('data', data)
      this.tour = new Tour(data.data.tour);
      for (let candidate of this.tour?.driverCandidates){
        this.drivers.push({
         id: candidate?.id,
         name: this.getName(candidate)
        })
      }
      for (let address of this.tour?.possibleStartAddresses){
        this.departureAddresses.push({
         id: address?.id,
         name: address?.getAddressString()
        })
      }
      for (let address of this.tour?.possibleEndAddresses){
        this.arrivalAddresses.push({
         id: address?.id,
         name: address?.getAddressString()
        })
      }
      this.initForm();
    });
    // this.resources = data.resources;

  }
  initForm() {
    this.tourFormGroup.patchValue({
      assignee: this.tour?.driver?.id,
      resource: this.tour?.resource?.id,
      // startTime: this.datePipe.transform(this.tour?.scheduledDepartureDateTime, 'HH:mm', 'CET'),
      // endTime: this.datePipe.transform(this.tour?.scheduledArrivalDateTime, 'HH:mm', 'CET'),
      // startDateControl: this.tour?.scheduledDepartureDateTime ? this.tour?.scheduledDepartureDateTime : null,
      // endDateControl: this.tour?.scheduledArrivalDateTime ? this.tour?.scheduledArrivalDateTime : null,
      description: this.tour?.note,
      departureAddress: this.tour?.departureAddress?.id,
      arrivalAddress: this.tour?.arrivalAddress?.id
    });
    if (this.tour?.state === 'RUNNING') {
      this.tourFormGroup?.controls['assignee']?.disable();
      this.tourFormGroup?.controls['resource']?.disable();
      this.tourFormGroup?.controls['startDateControl']?.disable();
      this.tourFormGroup?.controls['startTime']?.disable();
      this.tourFormGroup?.controls['departureAddress']?.disable();
    }
    this.tourFormGroup?.setValidators(this.dateRangeValidator);
    let fromDate = this.tour?.scheduledDepartureDateTime ? new Date(this.tour?.scheduledDepartureDateTime ): null;
    let toDate = this.tour?.scheduledArrivalDateTime ? new Date( this.tour?.scheduledArrivalDateTime) : null;
    this.tourFormGroup.get('startDateControl')?.setValue(fromDate);
    this.tourFormGroup.get('endDateControl')?.setValue(toDate);

  }
  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    const formModel = this.tourFormGroup.value;
    let tourModel = new TourInput();
    tourModel.id = this.tour.id;
    tourModel.locationId = this.tour?.location?.id;

    if (this.tourFormGroup?.controls['description']?.dirty) {
      tourModel.note = formModel?.description;
    } else {
     tourModel.note = this.tour.note;
    }
    if (this.tourFormGroup?.controls['assignee']?.dirty) {
       tourModel.assignee = formModel?.assignee;
    } else {
      tourModel.assignee = this.tour.driver?.id;
    }
    if (this.tourFormGroup?.controls['resource']?.dirty) {
      tourModel.resource = formModel?.resource;
    } else {
     tourModel.resource = this.tour.resource?.id;
    }

    if (formModel?.startDateControl) {
      tourModel.scheduledDepartureDateTime = new Date (this.tourFormGroup.get('startDateControl')?.value).toISOString();
    } else {
      tourModel.scheduledDepartureDateTime = formModel.scheduledDepartureDateTime;
    }
    if (formModel?.endDateControl) {
      tourModel.scheduledArrivalDateTime = new Date (this.tourFormGroup.get('endDateControl')?.value).toISOString();
    } else {
      tourModel.scheduledArrivalDateTime = formModel.scheduledArrivalDateTime;
    }

    if (formModel?.arrivalAddress) {
      tourModel.arrivalAddressId = formModel?.arrivalAddress;
    } else {
      tourModel.arrivalAddressId = formModel.arrivalAddress;
    }
    if (formModel?.departureAddress) {
      tourModel.departureAddressId = formModel?.departureAddress;
    } else {
      tourModel.departureAddressId = formModel.departureAddress;
    }
    this.ref.close(tourModel);


  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.tourFormGroup;
  }

  // deleteTour(){
  //   if(this.tour?.isDeletable){
  //     const message = 'Möchtest Du die Tour (' + this.tour.name +') wirklich löschen?';
  //     const dialogData = new ConfirmDialogModel("Aktion bestätigen", message, 'Löschen');
  //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //       maxWidth: "400px",
  //       data: dialogData,
  //       autoFocus: false
  //     });

  //     dialogRef.afterClosed().subscribe((dialogResult: any) => {
  //       if (dialogResult) {
  //         this.dialogRef.close({tour: this.tour});
  //       }
  //     });
  //   }
  // }

  getName(user: User): string {
    if (user?.firstName && user?.lastName) {
      return user.firstName + ' ' + user.lastName;
    } else if (user?.firstName || user?.lastName) {
      return user?.firstName ? user?.firstName : user?.lastName;
    } else {
      return '-'
    }
  }

  dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup; // Cast to FormGroup
    const fromDate = formGroup.get('startDateControl')?.value;
    const toDate = formGroup.get('endDateControl')?.value;

    if (fromDate && toDate && fromDate >= toDate) {
      return { dateRangeInvalid: true }; // Error if 'toDate' is earlier than or same as 'fromDate'
    }
    return null; // No error
  }


  get dateRangeInvalid(): boolean {
    return this.tourFormGroup?.hasError('dateRangeInvalid');
  }
}
