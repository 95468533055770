<ng-container *ngIf="controlTemplate?.settings?.value; else noValue">
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showLevelVisible()">
        <div class="basis-2/3 py-2">{{controlTemplate?.settings?.levelVisibleLabel}}</div>
        <div class="basis-1/3 py-2 text-right flex justify-end"><input type="checkbox" checked disabled></div>
    </div>
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showLevelInput()">
        <div class="basis-1/2 py-2">{{controlTemplate?.settings?.levelLabel}}</div>
        <div class="basis-1/2 py-2 text-right">{{getLevelAsPercentage()}} %</div>
    </div>
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showPictureInput()">
        <div class="basis-1/2 py-2">Foto</div>
        <div class="basis-1/2 py-2 text-right">TODO</div>
    </div>
</ng-container>
<ng-template #noValue>
    <div class="text-right p-2">-</div>
</ng-template>