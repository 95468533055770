
  <div class="bodyWrapper bg-white h-full">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Kontaktpersonenliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center mr-3">
        <input pInputText type="text" class="p-inputtext p-component p-element mr-3"
          (input)="dtpersonen.filterGlobal($event.target?.['value'], 'contains')" placeholder="Kontaktperson suchen" />
          <nx-default-button [icon]="'icon-plus'" (click)="createContactPerson()" *ngIf="rolesService.hasRole('nexcore_contactPerson_create')"
          [style]="'light'" [label]="'Kontaktperson anlegen'"></nx-default-button>
      </div>
    </div>
    <p-table #dtpersonen
      [lazy]="true"
      (onLazyLoad)="loadData($event)"
      [value]="dataSource?.data"
      [scrollable]="true"
      [paginator]="true"
      [rows]="15"
      [loading]="loading"
      [rowsPerPageOptions]="[15, 20, 50]"
      [totalRecords]="dataSource?.totalElements"
      scrollHeight="flex"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} - {last} von {totalRecords}"
      [resizableColumns]="true"
      [sortField]="sortField" [sortOrder]="sortOrder">
      <ng-template pTemplate="caption">
        <div class="flex flex-row">
          <div class="basis-2/3 flex flex-row gap-2">
          </div>
          <div class="basis-1/3 flex flex-row justify-end">
            <p-dropdown [styleClass]="'sort'" appendTo="body" [options]="sortOptions" optionLabel="label"
            [showClear]="false" (onChange)="sort($event)"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
          <tr>
              <th>Name</th>
              <th>Telefon</th>
              <th>Mobilfunk</th>
              <th>E-Mail</th>
              <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-linkedContact>
          <tr>
              <td>{{linkedContact.getFullName()}}</td>
              <td>{{linkedContact.phoneNumber? linkedContact.phoneNumber: '-'}}</td>
              <td>{{linkedContact.mobileNumber? linkedContact.mobileNumber: '-'}}</td>
              <td>{{linkedContact.email? linkedContact.email: '-'}}</td>
              <td class="text-right">
                <nx-default-button
                  *ngIf="getCommandMenuItems()?.length > 0"
                  [style]="'light'"
                  [data]="linkedContact"
                  [commandMenuItems]="getCommandMenuItems()"
                  (onCommand)="executeCommand($event)"
                  [icon]="'icon-options'">
                </nx-default-button>
              </td>
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="5" class="emptyMessage">Keine Kontaktpersonen vorhanden.</td>
        </tr>
      </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
  </ng-template>
</div>
