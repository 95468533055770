import { User } from "@nexato/nx-core-module";

export class Comment {
  id: string | undefined;
  text: string | undefined;
  ownerId: string | undefined;
  ownerType: string | undefined;
  createdBy: User;
  createdDateTime:  string | undefined;
  lastModifiedBy: User;
  lastModifiedDateTime: string | undefined;


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.text = payload.text;
      this.ownerId = payload.ownerId;
      this.ownerType = payload.ownerType;
      this.createdDateTime = payload.createdDateTime;
      if (payload.createdBy) {
        this.createdBy = new User(payload.createdBy);
      }
      if (payload.lastModifiedBy) {
        this.lastModifiedBy = new User(payload.lastModifiedBy);
      }
      this.lastModifiedDateTime = payload.lastModifiedDateTime;
    }

  }

  /**
   * creates models from a result
   * @param result Array of any
   * @returns Comments
   */
  static createComments(result: Array<any>): Comment[] {
    const comments = new Array<Comment>();
    if (result) {
      result?.map((comment) => {
        comments.push(new Comment(comment));
      });
    }
    return comments;
  }

}

export class CommentInput {
  id: string;
  text: string;
  ownerId: string;
  ownerType: string;
  references: any;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.text = payload.text;
      this.ownerId = payload.ownerId;
      this.ownerType = payload.ownerType;
      this.references = payload?.references;
    }
  }
}

