<div class="flex flex-col text-base my-1">
    <div
        [ngClass]="[!notification?.readDateTime ? 'font-medium' : 'font-light']">
            <span
                *ngIf="!notification?.readDateTime"
            class="w-[8px] h-[8px] bg-[#DAFF00] border border-gray rounded-full inline-flex relative -top-[1px]"></span>
            {{notification?.message}}
        </div>
    <div class="flex flex-row justify-between">
        <div class="font-light mt-1">{{notification?.createdDateTime | date: 'dd.MM.' }}</div>
        <div>
            <nx-default-button
                [style]="'dark'"
                (click)="onDetailsClicked(notification); $event.stopPropagation()"
                [label]="'Details anzeigen'">
            </nx-default-button>
        </div>
    </div>
</div>