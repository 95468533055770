<div class="flex flex-col p-2" (click)="goToPath()">
  <div class="flex flex-row">
    <div class="h-[1.75rem] w-[1.75rem] bg-[#EAEEEF] rounded-md text-[1.688rem] mb-[0.5rem]">
      <i [className]="icon"></i>
    </div>
    <div class="font-medium ml-[0.5rem] mt-[0.25rem]">{{label}}</div>
  </div>
  <div class="flex flex-row text-[#848488]">
    <div class="ml-[2.375rem]">{{description}}</div>
  </div>
</div>
