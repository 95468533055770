<ng-container *ngIf="withMainHeadline" class="mx-auto pt-[22px] px-[1.25rem] h-[calc(100%-100px)]">
  <nx-pageTitle-box class="mb-3" [label]="'Kunden'" [icon]="'icon-group'" [iconToolTip]="'Kunden'">
  </nx-pageTitle-box>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-container *ngIf="!withMainHeadline">
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-template #table>
  <div class="bodyWrapper bg-white h-[calc(100%-100px)]">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Kundenliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center">
          <!-- <input pInputText type="text" class="p-inputtext p-component p-element mr-3" (input)="dtCustomers.filterGlobal($event.target?.['value'], 'contains')" placeholder="Kunden suchen" /> -->
      </div>
    </div>
    <p-table
      #dtCustomers
      [lazy]="true"
      (onLazyLoad)="loadData($event)"
      [value]="dataSource?.data"
      [scrollable]="true"
      [paginator]="true"
      [rows]="15"
      [rowsPerPageOptions]="[15, 20, 50]"
      [totalRecords]="dataSource?.totalElements"
      [loading]="loading"
      scrollHeight="flex"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} - {last} von {totalRecords}"
      [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th colspan="8">
            <div class="flex flex-row">
              <div class="basis-2/3 flex flex-row gap-2">
                <p-columnFilter
                  class="type"
                  field="type"
                  matchMode="in"
                  [showMenu]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  [showClearButton]="false"
                  [showButtons]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <nx-filter-multiSelect
                        [className]="'table-filter'"
                        [filter]="false"
                        [showToggleAll]="false"
                        [showHeader]="false"
                        [appendTo]="'body'"
                        [(ngModel)] = "typesModel"
                        [options]=contactTypes
                        [showClear]="true"
                        placeholder="Typ"
                        (onClear)="filter($event)"
                        (onChange)="filter($event.value)"
                        optionLabel="name">
                          <ng-template let-option pTemplate="item">
                              <div class="inline-block vertical-align-middle">
                                <span class="ml-1 mt-1">{{ option?.name }}</span>
                              </div>
                          </ng-template>
                        </nx-filter-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
              <div class="basis-1/3 flex flex-row justify-end">
                <p-dropdown
                  [styleClass]="'sort'"
                  appendTo="body"
                  [options]="sortOptions"
                  optionLabel="label"
                  [showClear]="false"
                  (onChange)="sort($event)"></p-dropdown>
              </div>
            </div>
          </th>
        </tr>
          <tr>
              <th style="width:5em">Typ</th>
              <th>Kontakt-ID</th>
              <th>Name</th>
              <th>Straße</th>
              <th>PLZ</th>
              <th>Ort</th>
              <th>Land</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-contact>
          <tr class="clickable" [newTabNavigation]="['./customers/customer', contact?.id]">
              <td class="iconTable"> <i [className]="Contact?.getContactIcon(contact?.type)" [matTooltip]="Contact?.getContactType(contact?.type)"></i></td>
              <td>{{contact?.number? contact.number: ''}}</td>
              <td>{{getContactName(contact)}}</td>
              <td>{{contact?.address?.street? contact.address.street: ''}}  {{contact?.address?.houseNumber? ' ' + contact?.address?.houseNumber: ''}}</td>
              <td>{{contact?.address?.postalCode? contact.address.postalCode: '-'}}</td>
              <td>{{contact?.address?.city? contact.address.city: '-'}}</td>
              <td>{{contact?.address?.country? contact.address.country: '-'}}</td>
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Kunden vorhanden.</td>
        </tr>
      </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
  </ng-template>
</div>


