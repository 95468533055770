<div>
  <nx-table-header
    [label]="getHeadline(type)">
  </nx-table-header>
</div>
<div
  *ngFor="let tour of dataSource?.data; last as isLast; first as isFirst; let i = index"
  class="child flex flex-row task items-center basis-full relative border-b bg-white border-gray cursor-pointer"
  [ngClass]="{'border-b' : !isLast}">
  <div
    class="tourHeader row flex flex-row items-center w-full px-2"
    [ngClass]="tour.state">


  <!-- tour name -->
  <div class="tourLink pl-1 pt-1 items-center flex min-w-[100px]">
    <span class="noWrap name">
    <a [routerLink]="['/tour-planner/detail/', tour.id ]" class="jump">
      {{tour.name}}<i [className]="'icon icon-jump-to'"></i></a></span>
  </div>

  <!-- start and end time -->
  <div class="startEndTime pl-2 flex flex-col min-w-[60px] text-[#848488]">
    <div>{{tour?.effectiveDepartureDateTime ? (tour?.effectiveDepartureDateTime | date:'HH:mm')  : '--:--'}}</div>
    <div>{{tour?.effectiveArrivalDateTime ? (tour?.effectiveArrivalDateTime | date:'HH:mm') : '--:--'}}</div>
  </div>

  <!-- driver / resource -->
  <div class="startEndTime pl-2 flex flex-row overflow-hidden">
    <div class="whitespace-nowrap overflow-hidden text-ellipsis">{{getDriverName(tour)}}</div>
    <div>&nbsp;/&nbsp;</div>
    <div class="whitespace-nowrap overflow-hidden text-ellipsis">{{tour.resource?.name? tour.resource?.name: '-'}}</div>
  </div>

  <div
    [pTooltip]="getProgressToolTip(tour)"
    [tooltipOptions]="tooltipOptions"
      class="ml-auto max-w-[80px] min-w-[60px] bg-gray-200 rounded-full h-2 justify-end">
      <div
      class="bg-[#262626] h-2 rounded-full"
      [ngStyle]="{ 'width': getDonePercentage(tour) + '%' }">
    </div>
  </div>

</div>
</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Touren gefunden'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageNumber * pageSize"
  [rows]="pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[5, 10, 15]">
</p-paginator>
    <!-- <p-table #dtresources [lazy]="true" (onLazyLoad)="loadData($event)" [value]="dataSource?.data" [scrollable]="true"
      [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 15, 20, 50]" [totalRecords]="dataSource?.totalElements"
      [loading]="loading" scrollHeight="flex" [sortField]="sortField" [sortOrder]="sortOrder"
      [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} von {totalRecords}"
      [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Tour</th>
          <th>Fahrzeug</th>
          <th>Fahrer</th>
          <th *ngIf="this.type === 'running' || this.type === 'planned'">Startzeit</th>
          <th *ngIf="this.type === 'finished'">Endzeit</th>
          <th *ngIf="this.type === 'running'">Fortschritt</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tour>
        <tr class="clickable">
          <td> <a [routerLink]="['../tour-planner/detail/', tour.id ]" class="jump">
            {{tour.name}} <i [className]="'icon icon-jump-to'"></i></a></td>
          <td>{{tour.resource?.name? tour.resource?.name: '-'}}</td>
          <td>{{tour.getAssigneeName()}}</td>
          <td *ngIf="this.type === 'planned'">{{tour.scheduledDepartureDateTime?  (tour.scheduledDepartureDateTime | date:'HH:mm'): '-'}}</td>
          <td *ngIf="this.type === 'running'"> {{tour.actualDepartureDateTime? (tour.actualDepartureDateTime | date:'HH:mm'): '-'}}</td>
          <td *ngIf="this.type === 'finished'">{{tour.actualArrivalDateTime? (tour.actualArrivalDateTime | date:'HH:mm'): '-'}}</td>
          <td *ngIf="this.type === 'running'">
            <div
            [pTooltip]="getProgressToolTip(tour)"
            [tooltipOptions]="tooltipOptions"
              class="w-full bg-gray-200 rounded-full h-2">
              <div

                class="bg-[#262626] h-2 rounded-full"
                [ngStyle]="{ 'width': getDonePercentage(tour) + '%' }">
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Touren vorhanden.</td>
        </tr>
      </ng-template>
    </p-table> -->
