import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApolloService } from '@nexato/nx-core-module';
import moment from 'moment';
import { Order } from 'src/app/order-module/shared/entities/order/order';
import { DateTimePeriod } from '../../entities/dateTimePeriod/dateTimePeriod';
import { TaskAssignmentInput } from '../../entities/taskAssignmentInput/taskAssignmentInput';
import * as fromGraphQl from './graphql';

/**
 * Tasks service.
 */
@Injectable()
export class TasksService {
  constructor(private apolloService: ApolloService,
    public datePipe: DatePipe,
    ) {}

  /**
   * Completes TAsk
   * @param id Task Id
   * @param protocol protocol zu Complete Task
   */
  public completeTask(taskId: string, formModelInput: any, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.COMPLETE_TASK_MUTATION,
      {
        taskId,
        formModelInput,
      },
      callback
    );
  }

  /**
   * Assign Task
   * @param id Task Id
   * @param protocol protocol zu Complete Task
   */
    public assignTask(taskAssignmentInput: TaskAssignmentInput, callback: any): void {
      this.apolloService.mutateAndExecute(
        fromGraphQl.ASSIGN_TASK_MUTATION,
        {
          taskAssignmentInput,
        },
        callback
      );
    }

  /**
   * Assign Tasks
   * @param taskAssignmentInputs array of TaskAssignmentInputs
   * @param callback callback function
   */
  public assignTasks(taskAssignmentInputs: TaskAssignmentInput[], callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.ASSIGN_TASKS_MUTATION,
      {
        taskAssignmentInputs,
      },
        callback
    );
  }

  /**
   * un Assign Task
   * @param id Task Id
   * @param protocol protocol zu Complete Task
   */
  public unassignTasks(taskIds: string[], callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UNASSIGN_TASKS_MUTATION,
      {
        taskIds,
      },
      callback
    );
  }

  /**
   * un Assign Task
   * @param id Task Id
   * @param protocol protocol zu Complete Task
   */
  public unassignTask(taskId: string, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UNASSIGN_TASK_MUTATION,
      {
        taskId,
      },
      callback
    );
  }


    public getDeliveryDate(dateTime: DateTimePeriod) {
      if (dateTime){
        switch(dateTime?.modifier) {
         case 'BEFORE':
           return this.datePipe.transform(dateTime.dateTime, "dd.MM");
         case 'AFTER':
           return this.datePipe.transform(dateTime.dateTime, "dd.MM");
         case 'EXACT':
           return this.datePipe.transform(dateTime.dateTime, "dd.MM");
         case 'BETWEEN':
           return this.datePipe.transform(dateTime.fromDateTime, "dd.MM")
         default:
           return '-';
        }
       }
       return '-';

    }
    public getDeliveryTime(dateTime: DateTimePeriod){
      if (dateTime){
       switch(dateTime?.modifier) {
        case 'BEFORE':
          return 'vor ' + this.datePipe.transform(dateTime.dateTime, "HH:mm");
        case 'AFTER':
          return 'nach ' + this.datePipe.transform(dateTime.dateTime, "HH:mm");
        case 'EXACT':
          return this.datePipe.transform(dateTime.dateTime, "HH:mm");
        case 'BETWEEN':
          let a = moment(dateTime?.fromDateTime);
          let b = moment(dateTime?.toDateTime);
          let difference = b.diff(a, 'days');
          let dateString= this.datePipe.transform(dateTime.fromDateTime, "HH:mm") + '-'
          +this.datePipe.transform(dateTime.toDateTime, "HH:mm") ;
          dateString += (difference && difference>0)? ' +' + difference : '' ;
          return dateString;
        default:
          return '--:--';
       }
      }
      return '--:--';
    }

    public getDeliveryDateFull(dateTime: DateTimePeriod) {
      if (dateTime){
        switch(dateTime?.modifier) {
         case 'BEFORE':
           return this.datePipe.transform(dateTime.dateTime, "dd.MM.YY");
         case 'AFTER':
           return this.datePipe.transform(dateTime.dateTime, "dd.MM.YY");
         case 'EXACT':
           return this.datePipe.transform(dateTime.dateTime, "dd.MM.YY");
         case 'BETWEEN':
           return this.datePipe.transform(dateTime.fromDateTime, "dd.MM.YY")
         default:
           return '-';
        }
       }
       return '-';

    }

    public getCommentsNotesCount(order: Order) : number {
      return order?.note? 1 + order?.comments?.length  : order?.comments?.length;
    }
}
