import { NXDataSourcePrime } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { Task } from '../../shared/entities/task/task';
import * as fromGraphQl from './graphql';

export class TaskAssignmentDataSource extends NXDataSourcePrime<any> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.TASK_ASSIGNMENT_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.TasksQueryResponse): void {
    super.setSubject(
      data.tasks?.content ? Task.createTasks(data.tasks?.content) : [],
      data.tasks?.totalElements ? data.tasks.totalElements : 0
    );
  }

  loadTasks(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
