
<div [formGroup]="contactpersonFormGroup" class="form flex flex-col">
  <nx-edit-text-input  *ngIf="!isName"
    formControlName="firstName"
    placeholder="Vorname"
    label="Vorname">
  </nx-edit-text-input>
  <nx-edit-text-input  *ngIf="!isName"
    formControlName="lastName"
    placeholder="Nachname"
    label="Nachname">
  </nx-edit-text-input>
  <nx-edit-text-input  *ngIf="isName"
    formControlName="name"
    placeholder="Name"
    label="Name">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="phoneNumber"
    placeholder="Telefon"
    label="Telefon">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="mobileNumber"
    placeholder="Mobilfunk"
    label="Mobilfunk">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="email"
    placeholder="E-Mail"
    label="E-Mail">
  </nx-edit-text-input>
<div>
