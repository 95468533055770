<div class="circle"
  [class.small]="size === 'small'"
  [class.red]="grade ==='RED'">
</div>
<div class="circle"
  [class.small]="size === 'small'"
  [class.yellow]="grade ==='YELLOW'">
</div>
<div class="circle"
  [class.small]="size === 'small'"
  [class.green]="grade ==='GREEN'">
</div>