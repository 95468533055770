<div [formGroup]="resourceFormGroup" class="form flex flex-col">
  <nx-edit-text-input
    formControlName="number"
    placeholder="Nummer"
    label="Nummer">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="name"
    placeholder="Name"
    label="Name">
  </nx-edit-text-input>
  <nx-edit-multiselect-input
    formControlName="functions"
    [label]="'Funktionen'"
    [options]="functions">
  </nx-edit-multiselect-input>
  <nx-edit-select-input
    formControlName="location"
    [label]="'Niederlassung'"
    [options]="locations">
  </nx-edit-select-input>
<div>
