<nx-pageTitle-box
  class="mb-3"
  [label]="'Tourenplaner'"
  [icon]="'icon-tour'"
  [iconToolTip]="'Tourenplaner'">
  <ng-template nxTemplate="label">
    <div class="text-lg font-medium leading-4 mt-[0.375rem]">Tourenplaner</div>
    <nx-default-button
      class="ml-2 mt-[3px]"
      [style]="'dark'"
      (click)="switchToSingleDayView($event)"
      [ngClass]="{ 'activeView': activeView === 'singleDay'}"
      [label]="'Tagesansicht'">
    </nx-default-button>
    <nx-default-button
      class="ml-2 mt-[3px]"
      [style]="'dark'"
      (click)="switchToMultiDayView($event)"
      [ngClass]="{ 'activeView': activeView === 'multiDay'}"
      [label]="'Mehrtagesansicht (beta)'">
  </nx-default-button>
  </ng-template>
  <ng-template nxTemplate="end">
    <app-date-selector
      class="date-selector"
      className="white"
      [stateKey]="'tourPlanner'"
      [storageType]="'session'"
      [displayLabel]=false
      (date)="dateChanged($event)">
    </app-date-selector> 
    <app-location-selector
      class="ml-2"
      [style]="'dark'"
      [multi]="false"
      [stateKey]="'tourPlanner'"
      [storageType]="'local'"
      [type]="'TOURBASE'"
      (locations)="locationsChanged($event)">
    </app-location-selector>
  </ng-template>
</nx-pageTitle-box>

<div class="body tourplannerContainer flex flex-row basis-full gap-3 overflow-hidden">

  <ng-container *ngIf="activeView === 'singleDay'">
    <ng-container *ngIf="!isTourComponentExpanded">
      
      <div class="flex-shrink-0 min-w-[500px] max-w-[570px]">
        
        <app-tours-planner-tours
          #tourlist
          [date]="selectedDate"
          [location]="selectedLocation"
          [isTourComponentExpanded]="isTourComponentExpanded"
          (expandTourComponent)="expandTourComponent()"
          (reduceTourComponent)="reduceTourComponent()"
          >
        </app-tours-planner-tours>

      </div>

      <div class="tasksContainer flex-grow">
        <app-tours-planner-tasks-list
          #tasklist 
          [date]="selectedDate">
        </app-tours-planner-tasks-list>
      </div>

    </ng-container>

    <ng-container *ngIf="isTourComponentExpanded">

      <p-splitter
        [styleClass]="'flex flex-col h-full'"
        [panelStyleClass]="'w-full min-h-0 overflow-hidden'"
        [ngClass]="'w-full'"
        layout="vertical"
        (onResizeEnd)="onResizeEnd($event)">
        <ng-template pTemplate>

          <div class="w-full">
            <app-tours-planner-tours
              #tourlist
              [date]="selectedDate"
              [location]="selectedLocation"
              [isTourComponentExpanded]="isTourComponentExpanded"
              (expandTourComponent)="expandTourComponent()"
              (reduceTourComponent)="reduceTourComponent()">
          </app-tours-planner-tours>
          </div>
          
        </ng-template>
        <ng-template pTemplate>
            <div class="w-full mt-2">
              <app-tours-planner-tasks-list
                #tasklist 
                [date]="selectedDate">
              </app-tours-planner-tasks-list>
            </div>
        </ng-template>
      </p-splitter>

    </ng-container>
  </ng-container>

  <ng-container *ngIf="activeView === 'multiDay'">

    <p-splitter
      [styleClass]="'flex flex-col h-full'"
      [panelStyleClass]="'w-full min-h-0 overflow-hidden'"
      [ngClass]="'w-full'"
      layout="vertical"
      (onResizeEnd)="onResizeEnd($event)">
      <ng-template pTemplate>

        <div class="w-full">
          <app-tours-planner-tours-multi
            #tourlist
            [date]="selectedDate"
            [location]="selectedLocation"
            (expandTourComponent)="tourPlannerService?.expandTourComponent()"
            (reduceTourComponent)="tourPlannerService?.reduceTourComponent()">
          </app-tours-planner-tours-multi>
        </div>
        
      </ng-template>
      <ng-template pTemplate>
          <div class="w-full mt-2">
            <app-tours-planner-tasks-list
              #tasklist 
              [date]="selectedDate">
            </app-tours-planner-tasks-list>
          </div>
      </ng-template>
    </p-splitter>

  </ng-container>

</div>

