import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AutoCompleteResult, AutoCompleteResultSource } from '../../shared/entities/address/AutoCompleteResult';
import { Address } from '../../shared/entities/address/address';
import { GoogleService } from '../../../rent-module/shared/services/google.service';
import { HereService } from '../../../rent-module/shared/services/here.service';
import { MetricsService, SettingsService } from '@nexato/nx-core-module';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-address-autocomplete",
  templateUrl: "address-autocomplete.html",
  styleUrls: ["./address-autocomplete.scss"]
})
export class AddressAutocompleteComponent implements OnDestroy {


  // address autocomplete form
  public addressAutocompleteForm: FormGroup;
  private addressAutocompleteFormSubscription: Subscription;
  // resultGroups will be used, if google is active to show
  // both results: Google and Here with headlines
  // results will be used, if only Here will be queried
  public addressAutoCompleteResultsHere: AutoCompleteResult[] = [];
  public addressAutoCompleteResultsGoogle: AutoCompleteResult[] = [];
  @ViewChild('autocompleteInput') autocompleteInput!: AutoComplete;

  public googleAutocompleteAcitve = false;
  public lastGroup: string | null = null;
  // session token is generated every time the user types in the address input
  // and nulled, when the user selects an address
  // see google documentation: https://developers.google.com/maps/documentation/places/web-service/autocomplete#sessiontoken
  private googleAutocompleteSessionToken: string;

  // address form
  addressForm: FormGroup;
  public address: Address; // this comes in, if an order already exists and has an address
  public addressInput: any; // this comes in, if we´re in an order form and the address is only an input
  public addressResults = [];

  @Output() addressSelected = new EventEmitter<Address>();

  constructor(
    public hereService: HereService,
    public googleService: GoogleService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private metricService: MetricsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.settingsService.getSetting('addresses_secondary_geocoder_autocomplete_enabled', (setting) => {
      if (setting.currentValue === true){
        this.googleAutocompleteAcitve = true;
      }

    });
    this.initializeAddressAutocompleteForm();
  }

  ngOnDestroy(): void {
    this.addressAutocompleteFormSubscription?.unsubscribe();
  }

  initializeAddressAutocompleteForm() {
    this.addressAutocompleteForm = this.fb.group({
      'query': [null],
    });
    this.addressAutocompleteFormSubscription = this.addressAutocompleteForm.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if (value != null && value.query != null && value.query.length > 1) {
          this.hereService.searchAddress(value.query, (result: AutoCompleteResult[]) => {
            let hereCount = result?.length;
            // if google is active, we want to have the result as a result groul

              this.addressAutoCompleteResultsHere = result;
              this.addressAutoCompleteResultsHere = this.addressAutoCompleteResultsHere?
              this.addressAutoCompleteResultsHere.map(result => ({
                ...result,
                group: 'Here',
                count:  hereCount
              })) : [];

              if (!this.googleAutocompleteAcitve) {
                this.addressResults =  this.addressAutoCompleteResultsHere;
              } else {
                this.addressResults =[...this.addressAutoCompleteResultsGoogle, ...this.addressAutoCompleteResultsHere];
              }

          });
          // initiate google search only, if google is active
          if(this.googleAutocompleteAcitve) {
            if(!this.googleAutocompleteSessionToken) {
              // create a new session token, if it´s not already there
              // each time we create a new session token, we want to
              // add a metric
              this.googleAutocompleteSessionToken = this.googleService.getSessionToken();
              this.metricService.publishTimeSeriesMetricEvent('addr.geo.google.acmplt.sessn');
            }
            this.googleService.searchAddress(value.query, this.googleAutocompleteSessionToken, (result: AutoCompleteResult[]) => {
              let googleCount = result?.length;
              this.addressAutoCompleteResultsGoogle = result ?
                result.map(result => ({
                  ...result,
                  group: 'Google',
                  count: googleCount
                })) : [];
              this.addressResults = [...this.addressAutoCompleteResultsGoogle, ...this.addressAutoCompleteResultsHere];
            })
          }

        }

      });
  }

  displayAddressLabel(address: any): string {
    return address?.title;
  }

  async selectAddress(event: any) {
    this.addressAutocompleteForm.get('query').reset();
    this.autocompleteInput.inputEL.nativeElement.focus();
    this.addressAutoCompleteResultsHere = [];
    this.addressAutoCompleteResultsGoogle = [];

    this.addressResults = [];
    this.googleAutocompleteSessionToken = undefined;

    if (event?.value
      && event?.value?.autoCompleteResultSource == AutoCompleteResultSource.HERE) {
      this.hereService.lookup(event.value.payload.id, (result: { address: { countryName: string; city: string; postalCode: string; street: string; houseNumber: any; }; position: { lat: any; lng: any; }; }) => {
        let address = this.hereService.createAddressFromLookupResult(result);
        this.addressSelected.emit(address);
      })
    }
    if (event?.value
      && event?.value?.autoCompleteResultSource == AutoCompleteResultSource.GOOGLE) {
      this.googleService.getPlaceDetails(event?.value?.payload?.place_id
        , (address: any) => {
          this.addressSelected.emit(address);
        })
    }
  }

  focusAutocomplete() {
    this.autocompleteInput.inputEL.nativeElement.focus();
  }

  checkGroup(group: string | null): boolean {
    if (group !== this.lastGroup) {
      this.lastGroup = group;
      return true;
    }
    return false;
  }

  // Reset `lastGroup` before each autocomplete display to start fresh
  resetLastGroup() {
    this.lastGroup = null;
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

}
