<ng-container *ngIf="_controlTemplate?.settings?.value; else noValue">
    <div class="flex flex-row border-b border-solid border-gray-300 last:border-b-0" *ngIf="showCustomerOnSite()">
        <div class="basis-2/3 py-2">{{_controlTemplate?.settings?.customerOnSiteLabel}}</div>
        <div class="basis-1/3 py-2 text-right flex justify-end"><input type="checkbox" [checked]="customerOnSite()" disabled></div>
    </div>
    <div class="flex flex-row pr-2 border-b border-solid border-gray-300 last:border-b-0" *ngIf="showCustomerOnSiteName()">
        <div class="basis-1/2 py-2">{{_controlTemplate?.settings?.customerOnSiteNameLabel}}</div>
        <div class="basis-1/2 py-2 text-right">{{_controlTemplate?.settings?.value?.customerOnSiteName ? _controlTemplate?.settings?.value?.customerOnSiteName : '-'}}</div>
    </div>
    <div class="flex flex-row py-2 border-b border-solid border-gray-300 last:border-b-0 w-full" *ngIf="_controlTemplate?.settings?.value?.file?.fullPath && !archived">
        <img class="border border-solid border-gray-300" src="{{fileApi + '/view/' + _controlTemplate?.settings?.value?.file?.fullPath}}" style="width:16em">
    </div>
    <div class="py-2 border-b border-solid border-gray-300 last:border-b-0 text-end italic font-light" *ngIf="archived">
        Archiviert
    </div>
</ng-container>
<ng-template #noValue>
    -
</ng-template>