import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { RolesService } from '@nexato/nx-core-module';
import { Apollo, QueryRef } from 'apollo-angular';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { environment } from 'src/environments/environment';
import { Tour, TourInput } from '../../shared/entities/tour';
import { TourPlannerService } from '../../shared/services/tour/tour-planner.service';
import { TourService } from '../../shared/services/tour/tour.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: "div[tours-planner-tours], app-tours-planner-tours",
  templateUrl: "./tours-planner-tours.component.html",
  styleUrls: ["./tours-planner-tours.component.scss"],
})
export class ToursPlannerToursComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild('xScrollContainer') public xScrollContainer: any;
  xScrollCleanup: any;

  // temp for testing
  public tours: any;
  expanded = true;
  // -->

  isDraggingSubscription: Subscription;
  public notPlannedHeadline: string;
  // tours sorted
  public dailyToursByResources = new Array<any>();
  public dailyToursNoResourcePlanned = new Array<Tour>();
  public dailyToursByDriver = new Array<any>();
  public dailyToursNoDriverPlanned = new Array<Tour>();
  // the tours to display (depending on the sort value)
  public dailyToursSorted = new Array<any>();
  public dailyToursSortedNotPlanned = new Array<any>();

  public statesModel: any;
  public statesOptions =
    [
      { id: 'NEW', name: 'neu' },
      { id: 'RUNNING', name: 'läuft' },
      { id: 'PAUSED', name: 'pausiert' },
      { id: 'FINISHED', name: 'beendet' },
      { id: 'ARCHIVED', name: 'archiviert' }
    ];
  public sortModel: any;
  public sortOptions = [
    { label: 'Fahrer', id: 'driver' },
    { label: 'Fahrzeug', id: 'resource' }
  ];
  public tourplanerToursQuery: QueryRef<fromGraphQl.TourPlannerToursQueryResponse>;
  public tourplanerToursQuerySubscription: Subscription;
  
  // date
  _date: Date;
  @Input() set date(date: Date) {
    this._date = date;
    this.refetchQuery();
  }

  // location
  _location: LocationEntity
  @Input() set location(location: LocationEntity){
    this._location = location;
    this.refetchQuery();
  }

  @Input() isTourComponentExpanded: boolean;
  @Output() expandTourComponent = new EventEmitter();
  @Output() reduceTourComponent = new EventEmitter();

  constructor(
    public rolesService: RolesService,
    private apollo: Apollo,
    private tourPlannerService: TourPlannerService,
    private tourService: TourService
  ) {
    this.tourPlannerService.setToursPlannerToursComponent(this);
    this.initializeStatesModel();
    this.initializeSortModel();
    this.isDraggingSubscription = this.tourPlannerService.isDragging$.subscribe((dragging) => {
      if(dragging){
        this.tourplanerToursQuery?.stopPolling();
      } else {
        this.tourplanerToursQuery?.startPolling(environment.pollingIntervall);
      }
    });
  }

  ngAfterViewInit(): void {
    this.xScrollCleanup = autoScrollForElements({
      element: this.xScrollContainer.nativeElement,
    });
  }

  initializeStatesModel() {
    let statesModel;
    try {
      statesModel = localStorage.getItem('ToursPlannerToursComponent.statesModel');
      if (statesModel) {
        this.statesModel = JSON.parse(statesModel);
      }
    } catch (error) {
      // nothing special to do
    }
    // no special initial state
  }

  onStateModelChange() {
    this.persistStatesModel();
    this.refetchQuery();
  }

  persistStatesModel(){
    localStorage.setItem('ToursPlannerToursComponent.statesModel', JSON.stringify(this.statesModel));
  }

  initializeSortModel() {
    let sortModel;
    try {
      sortModel = localStorage.getItem('ToursPlannerToursComponent.sortModel');
      if (sortModel) {
        this.sortModel = JSON.parse(sortModel);
      }
    } catch (error) {
      // nothing special to do
    }
    if(!sortModel){
      this.sortModel = this.sortOptions[0];
    }
  }

  onSortModelChange() {
    this.persistSortModel();
    this.sort();
  }

  persistSortModel(){
    localStorage.setItem('ToursPlannerToursComponent.sortModel', JSON.stringify(this.sortModel));
  }

  createTour() {
    let tour = new TourInput();
    tour.locationId = this._location?.id;
    // // correct the 'german' date format to get a valid iso string:
    // let dmy = this.datePipe.transform(this.componentstate.date.setHours(0, 0, 0, 0), "dd.MM.yyyy" ).split(".");
    let date = new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString();
    tour.dateTimeReference = date;
    this.tourService.createTour(tour, () => this.refetch());
  }

  ngOnInit(): void {
    this.subscribeToToursData();
  }

  sort(){
    if (this.sortModel?.id === 'resource'){
      this.notPlannedHeadline = 'Kein Fahrzeug geplant';
      this.dailyToursSorted = this.dailyToursByResources;
      this.dailyToursSortedNotPlanned = this.dailyToursNoResourcePlanned;
    } else {
      this.notPlannedHeadline = 'Kein Fahrer geplant';
      this.dailyToursSorted = this.dailyToursByDriver;
      this.dailyToursSortedNotPlanned = this.dailyToursNoDriverPlanned;
    }
  }

  refetchQuery() {
    if(!this._location || !this._date){
      return;
    }
    if(this.tourplanerToursQuery){
      this.tourplanerToursQuery?.refetch({
        locationId: this._location?.id,
        states: this.statesModel ? this.statesModel.map((state: any) => state.id) : undefined,
        fromDateTime: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        toDateTime: new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      });
    } else {
      this.subscribeToToursData();
    }
  }

  subscribeToToursData() {
    if(!this._location || !this._date){
      return;
    }
    this.tourplanerToursQuery = this.apollo.watchQuery<fromGraphQl.TourPlannerToursQueryResponse>({
      pollInterval: environment.pollingIntervall,
      fetchPolicy: 'network-only',
      query: fromGraphQl.TOUR_PLANNER_TOURS_QUERY,
      variables: {
        locationId: this._location?.id,
        states: this.statesModel ? this.statesModel.map((state: any) => state.id) : undefined,
        fromDateTime: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        toDateTime: new Date(moment(this._date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      }
    });
    this.tourplanerToursQuerySubscription = this.tourplanerToursQuery.valueChanges.subscribe(
      {
        next: (data) => {
          if (data.networkStatus === 7) {
            this.tours = Tour.createTours(data.data.dailyTours);
            this.sortTours();
          }
        },
        error: (error) => {
          this.tourplanerToursQuery = undefined;
          this.tourplanerToursQuerySubscription?.unsubscribe();
        }
      }
    );
  }

  startPolling() {
    this.tourplanerToursQuery?.startPolling(environment.pollingIntervall);
  }

  stopPolling() {
    this.tourplanerToursQuery?.stopPolling();
  }

  refetch() {
    this.refetchQuery();
  }

  sortTours() {
    this.dailyToursByDriver = [];
    this.dailyToursByResources = [];
    this.dailyToursNoDriverPlanned = [];
    this.dailyToursNoResourcePlanned = [];
    let tours = this.tours;
    this.dailyToursNoResourcePlanned = tours?.filter((tour: Tour) => {
      if (tour?.resource) {
        if (this.dailyToursByResources.findIndex(x => x?.resource?.id == tour?.resource?.id) === -1) {
          this.dailyToursByResources.push(
            {
              'resource': tour?.resource,
              'tours': [tour]
            });
        } else {
          this.dailyToursByResources = this.dailyToursByResources.map((byResource: any) => {
            if (byResource.resource?.id === tour?.resource?.id) {
              byResource?.tours?.push(tour);
              return byResource;
            }
            return byResource;
          });
        }
      }
      else {
        return tour;
      }
    });
    tours = this.tours;
    this.dailyToursNoDriverPlanned = tours.filter((tour: Tour) => {
      if (tour?.driver) {
        if (this.dailyToursByDriver.findIndex(x => x?.driver?.id == tour?.driver?.id) === -1) {
          // this.tourDrivers.push(tour.assignee);
          this.dailyToursByDriver.push(
            {
              'driver': tour?.driver,
              'tours': [tour]
            });
        } else {
          this.dailyToursByDriver = this.dailyToursByDriver.map((byDriver: any) => {
            if (byDriver.driver?.id === tour?.driver?.id) {
              byDriver?.tours?.push(tour);
              return byDriver;
            }
            return byDriver;
          });
        }
      }
      else {
        return tour;
      }
    });
    this.dailyToursByResources = this.dailyToursByResources.sort((a, b) => (a?.resource?.name?.toLowerCase() < b?.resource?.name?.toLowerCase()  ? -1 : 1));
    this.dailyToursByDriver = this.dailyToursByDriver.sort((a, b) => (a?.driver?.name?.toLowerCase() < b?.driver?.name?.toLowerCase()  ? -1 : 1));

    if (this.sortModel?.id === 'resource'){
      this.notPlannedHeadline = 'Kein Fahrzeug geplant';
      this.dailyToursSorted = this.dailyToursByResources;
      this.dailyToursSortedNotPlanned = this.dailyToursNoResourcePlanned;
    } else {
      this.notPlannedHeadline = 'Kein Fahrer geplant';
      this.dailyToursSorted = this.dailyToursByDriver;
      this.dailyToursSortedNotPlanned = this.dailyToursNoDriverPlanned;
    }

  }

  getPlannedHeadline(daily: any){
    if (daily?.resource){
      return daily.resource.name;
    }
    if (daily?.driver){
      return daily.driver.firstName + ' ' + daily.driver.lastName;
    }
  }

  ngOnDestroy(): void {
    this.tourplanerToursQuerySubscription?.unsubscribe();
    this.isDraggingSubscription?.unsubscribe();
    if(this.xScrollCleanup){
      this.xScrollCleanup(); 
    }
  }
  
}




