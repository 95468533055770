import { gql } from 'apollo-angular';

// export const UPDATE_LOCATION_MUTATION = gql`
// mutation updateLocation($locationInput: LocationInput) {
//   updateLocation(input: $locationInput) {
//     id
//   }
// }
// `;

// export const CREATE_LOCATION_MUTATION = gql`
// mutation createLocation($locationInput: LocationInput) {
//   createLocation(input: $locationInput) {
//     id
//     name
//   }
// }
// `;

export const DELETE_ORDER_MUTATION = gql`
mutation deleteOrder($orderId: UUID) {
  deleteOrder(id: $orderId)
}
`;

export const CREATE_COMMENT_MUTATION = gql`
mutation createComment($input: CommentInput) {
  createComment(input: $input){
    id
  }
}
`;

export const UPDATE_COMMENT_MUTATION = gql`
mutation updateComment($input: CommentInput) {
  updateComment(input: $input){
    id
  }
}
`;

export const DELETE_COMMENT_MUTATION = gql`
mutation deleteComment($id: UUID) {
  deleteComment(id: $id)
}
`;

export const GET_PUBLIC_LINK_MUTATION = gql`
mutation getPublicLink($id: UUID) {
  getPublicLink(id: $id)
}
`;

export const BLOCK_ARCHIVING_MUTATION = gql`
mutation blockArchiving($id: UUID) {
  blockArchiving(id: $id) {
    id
  }
}
`;

export const DELETE_FILE_MUTATION = gql`
mutation deleteFile($id: UUID) {
  deleteFile(id: $id)
}
`;
