import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';
import { Tour } from 'src/app/tour-planning/shared/entities/tour';
import { NXDataSourcePrime } from '@nexato/nx-core-module';

export class TourDataSource extends NXDataSourcePrime<any> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.TOURS_QUERY, environment);
  }
  public count = 0;
  handleSubscriptionResult(data: fromGraphQl.TourQueryResponse): void {
    super.setSubject(
      data.tours?.content ? Tour.createTours(data.tours?.content) : [],
      data.tours?.totalElements ? data.tours.totalElements : 0
    );
    this.count = data.tours?.totalElements;
  }

  loadTours(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
