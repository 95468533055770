import { Injectable } from '@angular/core';
import * as fromGraphQl from './graphql';
import { ApolloService } from '@nexato/nx-core-module';
import { ContactPersonInput } from '../../entities/contactPerson/contactPerson';

/**
 * The models service.
 */
@Injectable()
export class ContactPersonService {
  constructor(private apolloService: ApolloService) {}

  /**
   * updates a ContactPerson
   * @param locationInput ContactPerson Input
   * @param callback method
   */
  public updateContactPerson(input: ContactPersonInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_CONTACTPERSON_MUTATION,
      {
        input,
      },
      callback
    );
  }

  /**
   * creates a ContactPerson
   * @param locationInput locationInput input
   * @param callback method
   */
  public createContactPerson(input: ContactPersonInput, callback: any): void {
    // console.log('Input', locationInput);
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_CONTACTPERSON_MUTATION,
      {
        input,
      },
      callback
    );
  }

  /**
   * deletes a location
   * @param locationId licencePlate Id
   * @param callback method
   */
  public deleteContactPerson(id: string, callback: any): void {

    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_CONTACTPERSON_MUTATION,
      {
        id,
      },
      callback
    );
  }
}
