import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { Order } from 'src/app/order-module/shared/entities/order/order';
import { Contact } from 'src/app/rent-module/shared/entities/contact/contact';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';


export const SEARCH_QUERY = gql`
query searchQuery($number: Int, $size: Int, $sortPropertyOrder: String, $sortPropertyResource: String, $sortPropertyContact: String, $sortDirection: String,  $search: String)
{
  orders: getOrdersPaged(
    filter: {
      sortRequest: { sortProperties: [$sortPropertyOrder], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortPropertyOrder, sortDirection: $sortDirection},
      number: $search,
    }

    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          type
          number
          customer {
            type
            companyName
            firstName
            lastName
          }
      }
  }
  resources: getResourcesPaged(
    filter: {
      sortRequest: { sortProperties: [$sortPropertyResource], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortPropertyResource, sortDirection: $sortDirection},
      text: $search
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
	        externalId
	        name
          number
      }
  }
  customers: getContactPaged(
    filter: {
      sortRequest: { sortProperties: [$sortPropertyContact], sortDirection: $sortDirection },
      pagination: { number: $number, size: $size,  sortProperty: $sortPropertyContact, sortDirection: $sortDirection},
      name: $search
    }

    ) {
      totalPages
      totalElements
      size
      content{
          id
          type
          companyName
          firstName
          lastName
          number
      }
  }
}
`;

export interface SearchQueryResponse {
  orders: Page<Order>;
  resources: Page<Resource>;
  customers: Page <Contact>;
}
