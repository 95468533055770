import { Article, ArticleInput } from "../article/article";
import { Order, OrderInput } from "../../../../order-module/shared/entities/order/order";
import { ResourceAssignment, ResourceAssignmentInput } from "../resourceAssignment/resourceAssignment";
import { SchedulingInformation } from "../schedulingInformation/schedulingInformation";


export class LineItem {
  id: string;
  externalId: string;
  index: string;
  indexText: string;
	article: Article;
	order: Order;
  startDateTime: string;
  endDateTime: string;
	text: string;
	resourceAssignments: ResourceAssignment[];
  schedulingInformation: SchedulingInformation;
	isDeletable: Boolean;
  customAttributes: any;


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.index = payload.index;
      this.indexText = payload.indexText;
      this.text = payload.text;
      this.isDeletable = payload.isDeletable;
      this.startDateTime = payload.startDateTime;
      this.endDateTime = payload.endDateTime;
      if(payload.resourceAssignments && payload.resourceAssignments.length > 0) {
        this.resourceAssignments = new Array<ResourceAssignment>();
        payload.resourceAssignments.map( (resourceAssignment: any) => {
          this.resourceAssignments.push(new ResourceAssignment(resourceAssignment));
        });
      }
      this.customAttributes = payload.customAttirbutes;
    }
    if (payload.order) {
      this.order = new Order(payload.order);
    }
    if (payload.article) {
      this.article = new Article(payload.article);
    }
    if (payload.schedulingInformation) {
      this.schedulingInformation = new SchedulingInformation(payload.schedulingInformation);
    }
    if (payload.resourceAssignments) {
      this.resourceAssignments = ResourceAssignment.createResourceAssignments(payload.resourceAssignments);
    }
  }


  /**
   * creates models from a result
   * @param result Array of any
   * @returns Bookigs
   */
  static createLineItems(result: Array<any>): LineItem[] {
    const lineItems = new Array<LineItem>();
    if (result) {
      result.map((lineItem) => {
        lineItems.push(new LineItem(lineItem));
      });
    }
    return lineItems;
  }
}
export class LineItemInput {
  id: string | undefined;
  externalId: string | undefined;
  index: string;
  indexText: string;
	article: ArticleInput;
	text: String;
	startDateTime: String;
	endDateTime: String;
	resourceAssignments: ResourceAssignmentInput[];
	order: OrderInput;
	orderId: string;


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.index = payload.index;
      this.indexText = payload.indexText;
      this.article = payload.article;
      this.text = payload.text;
      this.startDateTime = payload.startDateTime;
      this.endDateTime = payload.endDateTime
      this.orderId = payload.orderId;
      this.resourceAssignments = payload.resourceAssignments;
    }
    if (payload.order) {
      this.order = new OrderInput(payload.order);
    }
  }

}



