import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditDialogComponent, EditDialogInstance, LoadingService } from '@nexato/nx-core-module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocationEntity, LocationInput } from 'src/app/shared-module/shared/entities/location/location';
import { LocationService } from '../../shared/services/location/location.service';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent
  implements EditDialogInstance {

  location: LocationEntity
  locationFormGroup: FormGroup;
  public types = [
    { id: 'COMPANY', name: 'Firma' },
    { id: 'DEPOT', name: 'Lager' },
    { id: 'TOURBASE', name: 'Tourbasis' }
  ];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public locationService: LocationService,
    public loadingService: LoadingService
  ) {
    if (this.config.data) {
      this.location = this.config.data;
    }
    this.locationFormGroup = new FormGroup({});
    // add form controls
    this.locationFormGroup.addControl('id', new FormControl());
    this.locationFormGroup.addControl('name', new FormControl( undefined, Validators.required));
    this.locationFormGroup.addControl('label', new FormControl());
    this.locationFormGroup.addControl('externalId', new FormControl());
    this.locationFormGroup.addControl('email', new FormControl());
    this.locationFormGroup.addControl('phoneNumber', new FormControl());
    this.locationFormGroup.addControl('web', new FormControl());
    this.locationFormGroup.addControl('type', new FormControl());

    if(this.location){
      this.locationFormGroup.patchValue(this.location);
    }
  }

  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    this.loadingService.setLoading(true);
    if(this.location){
      // its an update
      // for an update we must add the address to the input: the address gets added/modified in its own dialog
      // if we do not add the address, it will be deleted
      let locationInput = this.locationService.completeLocationInput(new LocationInput(this.locationFormGroup.value), this.location);
      this.locationService.updateLocation(locationInput, (result: any) => {
        this.ref.close(true);
      });
    } else {
      // its a create
      this.locationService.createLocation(new LocationInput(this.locationFormGroup.value), (result: any) => {
        this.ref.close(result.data.createLocation.id);
      });
    }
    

  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.locationFormGroup;
  }

}
