import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';
import { Task } from '../../shared/entities/task/task';
import { NXDataSourcePrime } from '@nexato/nx-core-module';

export class TaskDataSource extends NXDataSourcePrime<any> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.TASKS_QUERY, environment);
  }
  public count = 0;
  handleSubscriptionResult(data: fromGraphQl.TaskQueryResponse): void {
    // console.log('data', data?.tasks?.content);
    super.setSubject(
      data.tasks?.content ? Task.createTasks(data.tasks?.content) : [],
      data.tasks?.totalElements ? data.tasks.totalElements : 0
    );
    this.count = data.tasks?.totalElements;
  }

  loadTasks(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
