<ng-container
  *ngIf="withMainHeadline"
  class="mx-auto pt-[22px] px-[1.25rem] h-[calc(100%-100px)]">
  <nx-pageTitle-box
    class="mb-3"
    [label]="'Aufträge'"
    [icon]="'icon-order'"
    [iconToolTip]="'Aufträge'">
  </nx-pageTitle-box>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-container
  *ngIf="!withMainHeadline">
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-template #table>

  <div class="bodyWrapper bg-white h-[calc(100%-100px)]">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Auftragsliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center">
          <!-- <input pInputText type="text" class="p-inputtext p-component p-element mr-3" (input)="dt1.filterGlobal($event.target?.['value'], 'contains')" placeholder="Auftrag suchen" /> -->
      </div>
    </div>
    <p-table
      #dt1
      [lazy]="true"
      (onLazyLoad)="loadData($event)"
      [value]="dataSource?.data"
      [scrollable]="true"
      [paginator]="true"
      [rows]="15"
      [loading]="loading"
      [rowsPerPageOptions]="[15, 20, 50]"
      [totalRecords]="dataSource?.totalElements"
      scrollHeight="flex"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} - {last} von {totalRecords}"
      paginatorStyleClass="rounded-b-md"
      [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th colspan="5">
            <div class="flex flex-row">
              <div class="basis-2/3 flex flex-row gap-2">
                <p-columnFilter
                  class="type"
                  field="type"
                  matchMode="in"
                  [showMenu]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  [showClearButton]="false"
                  [showButtons]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                      <nx-filter-multiSelect
                        [className]="'table-filter'"
                        [filter]="false"
                        [showToggleAll]="false"
                        [showHeader]="false"
                        [appendTo]="'body'"
                        [(ngModel)] = "typesModel"
                        [options]=orderTypes
                        [showClear]="true"
                        placeholder="Typ"
                        (onClear)="filter($event)"
                        (onChange)="filter($event.value)"
                        optionLabel="name">
                          <ng-template let-option pTemplate="item">
                              <div class="inline-block vertical-align-middle">
                                <span class="ml-1 mt-1">{{ option?.name }}</span>
                              </div>
                          </ng-template>
                        </nx-filter-multiSelect>
                  </ng-template>
                </p-columnFilter>

                <p-columnFilter field="state" matchMode="in"
                  [showMenu]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  [showClearButton]="false"
                  [showButtons]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <nx-filter-multiSelect
                      [className]="'table-filter'"
                      [filter]="false"
                      [showToggleAll]="false"
                      [showHeader]="false"
                      [appendTo]="'body'"
                      [options]="orderStates"
                      [(ngModel)] = "statesModel"
                      [showClear]="true"
                      [maxSelectedLabels]="1"
                      placeholder="Status"
                      (onClear)="filter($event)"
                      (onChange)="filter($event?.value)">
                        <ng-template let-option pTemplate="item">
                            <div class="inline-block vertical-align-middle">
                              {{option?.name}}
                            </div>
                        </ng-template>
                    </nx-filter-multiSelect>
                  </ng-template>
                </p-columnFilter>

                <p-columnFilter field="location" matchMode="in"
                  [showMenu]="false"
                  [showMatchModes]="false"
                  [showOperator]="false"
                  [showAddButton]="false"
                  [showApplyButton]="false"
                  [showClearButton]="false"
                  [showButtons]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <nx-filter-multiSelect
                      [className]="'table-filter'"
                      [filter]="false"
                      [(ngModel)] = "locationModel"
                      [maxSelectedLabels]="1"
                      [showClear]="true"
                      [showToggleAll]="false"
                      [showHeader]="false"
                      appendTo="body"
                      [options]="dataSource?.locations"
                      placeholder="Niederlassung"
                      optionLabel="name"
                      (onClear)="filter($event)"
                      (onChange)="filter($event?.value)">
                        <ng-template let-option pTemplate="item">
                            <div class="inline-block vertical-align-middle">
                              {{option?.name}}
                            </div>
                        </ng-template>
                    </nx-filter-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
              <div class="basis-1/3 flex flex-row justify-end">
                <p-dropdown
                  [styleClass]="'sort'"
                  appendTo="body"
                  [options]="sortOptions"
                  optionLabel="label"
                  [showClear]="false"
                  (onChange)="sort($event)"></p-dropdown>
              </div>
            </div>
          </th>
        </tr>
        <!-- stange inline style removed a weired one px spacer that is only visible during scrolling Oo -->
        <tr style="margin-top: -8px;position: relative;top: -1px;">
          <th style="width:5em">Typ</th>
          <th style="width:16rem">Auftragsnummer</th>
          <th>Kunde</th>
          <th>Status</th>
          <th>Niederlassung</th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-order>
          <tr class="clickable" [newTabNavigation]="['./orders/order', order.id]">
              <td  class="iconTable"><i [className]="Order.getOrderIcon(order?.type)"></i></td>
              <td>{{order.number}}</td>
              <td>{{getContactName(order?.customer)? getContactName(order?.customer): '-'}}</td>
              <td><p-tag value="{{Order.getOrderState(order.state)}}" [severity]="Order.getOrderStateSeverityStatic(order.state)"></p-tag></td>
              <td>{{order?.location?.name}}</td>
          </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Aufträge vorhanden.</td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="footer"></ng-template>

  </div>
</ng-template>
