<div [formGroup]="tourFormGroup" class="form flex flex-col">

  <nx-edit-select-input
    formControlName="resource"
    [label]="'Fahrzeug'"
    [showClear]="true"
    [options]="tour?.transportCandidates">
  </nx-edit-select-input>
  <nx-edit-select-input
    formControlName="assignee"
    [label]="'Fahrer'"
    [showClear]="true"
    [options]="drivers">
  </nx-edit-select-input>
  <nx-textfield-input
    formControlName="description"
    placeholder="Tour-Hinweis"
    label="Tour-Hinweis"
    [rows]="3"/>
  <div class="p-field">
    <label for="range">Startzeit</label><br/>
    <p-calendar
      [style]="{'width':'100%'}"
      formControlName="startDateControl"
      id="fromDate"
      dateFormat="dd.mm.yy"
      [showTime]="true"
      hourFormat="24"
      placeholder="Auswählen"
      appendTo="body"
      [showClear]="true"
      [ngClass]="{'error-border':  dateRangeInvalid}">
    </p-calendar>
  </div>
  <div class="p-field">
    <label for="range">Endzeit</label><br/>
    <p-calendar
      [style]="{'width':'100%'}"
      formControlName="endDateControl"
      id="toDate"
      dateFormat="dd.mm.yy"
      [showTime]="true"
      hourFormat="24"
      placeholder="Auswählen"
      appendTo="body"
      [showClear]="true"
      [ngClass]="{'error-border': dateRangeInvalid}">
    </p-calendar>
  </div>
  <nx-edit-select-input
  formControlName="departureAddress"
  [label]="'Startort'"
  [showClear]="true"
  [options]="departureAddresses">
</nx-edit-select-input>
<nx-edit-select-input
  formControlName="arrivalAddress"
  [label]="'Endort'"
  [showClear]="true"
  [options]="arrivalAddresses">
</nx-edit-select-input>


<div>
