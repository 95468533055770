import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { LocationEntity } from '../../../shared-module/shared/entities/location/location';

export const LOCATIONS_QUERY = gql`
query locationsQuery(
  $pageNumber: Int,
  $pageSize: Int,
  $sortProperty: String,
  $sortDirection: String
)
{
  locations: getLocationsPaged(
    filter: {
      sortRequest: { 
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection 
      },
      pagination: { 
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
        sortDirection: $sortDirection
      },
    }
    ) {
      totalPages
      totalElements
      number
      size
      content{
          id
          name
          isDeletable
      }
  }
}
`;

export interface LocationsQueryResponse {
  locations: Page<LocationEntity>;
  loading: boolean;
}
