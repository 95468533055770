import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentState, ExpandService, PersistenceScope, RolesService, StateService, StateVar } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { FilterMetadata } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { LineItem } from 'src/app/rent-module/shared/entities/lineItem/lineItem';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { environment } from 'src/environments/environment';
import { Contact } from '../../../rent-module/shared/entities/contact/contact';
import { Order } from '../../shared/entities/order/order';
import { OrderDataSource } from './orderDataSource';

interface OrderComponentState{
  selectedLocationOrder: LocationEntity[]
}
@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, OnDestroy {

  // indicates loading
  public loading = false;

  public Order = Order;
  public number = '';
  public customerId: string = null;
  public resourceId: string = null;

  private sortProperty = 'id';
  private sortDirection = 'asc';
  public pageNumber = 0;
  public pageSize = 15;
  public location: LocationEntity[];
  public locationModel: any[];
  public locations: any[];
  public isFirst = true;
  public componentStateCount = 0;
  previousState: any = null;
  private types: any[] = [];
  public typesModel: any[] = [];
  private states: any[] = [];
  public statesModel: any[] = [];
  public orderTypes: any[] = [];
  public orderStates: any[] = [];

  public sortField = 'id';
  public sortOrder = 1;

  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
    { label: 'Typ aufsteigend', fieldName: 'type', direction: 1 },
    { label: 'Typ absteigend', fieldName: 'type', direction: -1 },
    { label: 'Auftragsnummer aufsteigend', fieldName: 'number', direction: 1 },
    { label: 'Auftragsnummer absteigend', fieldName: 'number', direction: -1}
  ];

  componentState: ComponentState<OrderComponentState>;
  selectedLocationOrder: StateVar<LocationEntity[]>;

  @Input() set customer(customer: string) {
    this.customerId = customer;
    // this.displayedColumns= [
    //   'type',
    //   'number',
    //   'lineItems',
    //   'state'
    // ];
    // console.log('customer id', this.customerId);
    this.refetchOrders();
  }

  @Input() set resource(resource: string) {
    this.resourceId = resource;
    // console.log('customer id', this.customerId);
    this.refetchOrders();
  }

  @Input() withMainHeadline = true;

  public fileApi = environment.fileApi;
  public dataSource: OrderDataSource;

  constructor(
    private router: Router,
    private apollo: Apollo,
    public rolesService: RolesService,
    stateService: StateService,
    private cdRef:ChangeDetectorRef,
    public expandService: ExpandService

  ) {
    this.componentState = new ComponentState<OrderComponentState>("OrdersListComponent", stateService, apollo);
    this.selectedLocationOrder = new StateVar({
      key: "selectedLocationTask",
      initialValue: this.location,
      persistenceScope: PersistenceScope.Backend
    });
    this.componentState.addStateVar(this.selectedLocationOrder);
    this.orderTypes = Order?.getOrderTypes();
    this.orderStates = Order?.getOrderStates();
  }

  createOrder(): void {
    // const dialogRef = this.dialog.open(VehicleDialogComponent, {
    //   width: '760px',
    // });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result && result.saved) {
    //     this.refetchVehicles();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }

  ngOnInit(): void {
    console.log('Iscollpsed order List', this.expandService?.getExpandState())
      this.componentState.watch( (state:any) => {
        if (this.componentStateCount > 0 && this.previousState!= state) {
          this.selectedLocationOrder.currentValue = state[this.selectedLocationOrder.key];
          this.location = this.selectedLocationOrder.currentValue;
          this.isFirst? this.setTableDataSource(): this.refetchOrders();
          this.isFirst = false;
          this.previousState = state;
          this.cdRef.detectChanges();
         }
        this.componentStateCount++;
      });
    this.expandService.setExpandState(false);
  }

  setTableDataSource(): void {
    this.dataSource = new OrderDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadorders({
      number: 0,
      size: 15,
      sortProperty:  'type',
      sortDirection: 'asc',
      customer: this.customerId,
      resource: this.resourceId,
      locationId: this.locations
    });
  }

  refetchOrders(): void {
    const number = this.number ? this.number : '';
    this.dataSource?.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      orderNumber: number,
      customer: this.customerId,
      resource: this.resourceId,
      locationId: this.locations,
      types: this.types,
      states: this.states
    });
  }

  getContactName(contact: Contact): string {
    return new Contact(contact).getFullName();
  }

  getLineItems(lineItems : LineItem[]): string {
    let name = undefined;
    for (const lineItem of lineItems) {
      const name1 = this.findArticleNumber(lineItem);
      if (name1) {
        name = name? name + ', ' + name1 : name1;
      }
    }
    return name;
  }

  findArticleNumber(lineItem: LineItem) {
    // we have an article available
    if (lineItem?.article?.number) {
      return lineItem?.article?.number;
    }
    // we do not have an article available, so we display
    // resource.number. If no article is available, the constraint is,
    // that we have only one resourceAssignment per lineItem, so we can
    // safely acces the first resourceAssignment harcoded
    if (lineItem?.resourceAssignments?.length > 0){
      return lineItem?.resourceAssignments[0].resource?.name;
    }
    return undefined;
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  selectLocation($event: any){
    // this.sitesActive = true;
    this.location = $event;
    this.componentState.update(this.selectedLocationOrder,  this.location);
    this.refetchOrders();
  }

  loadData(event: TableLazyLoadEvent) {
    console.log(event);
    this.loading = true;

    // sorting
    if (event?.sortField !== undefined && typeof event?.sortField == 'string') {
      this.sortProperty = event.sortField;
    }
    if (event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }

    //filtering
    if (event?.filters.number !== undefined) {
      this.number = (event.filters.number as FilterMetadata)?.value;
    }
    if (event?.filters?.location !== undefined) {
      let filter = event.filters.location as FilterMetadata;
      if (filter?.value === undefined
        || filter?.value === null) {
        this.locationModel = null;
        this.locations = null;
      }
    }
    if (this.locationModel !== undefined
      && this.locationModel !== null
      && this.locationModel.length > 0
    ) {
      const values = this.locationModel;
      this.locations = values?.map(item => item.id);
    } else {
      this.locations = null;
    }

    // type is not shwon as row so its an array of filterMetadata
    // but we use only the value at position 0
    let typeFilter = event?.filters?.type as FilterMetadata[];
    if (typeFilter?.[0]?.value?.length > 0) {
      this.types = typeFilter?.[0].value;
    } else {
      this.types = undefined;
    }

    if (event?.filters?.type !== undefined) {
      let filter = event.filters.type as FilterMetadata;
      if (filter === undefined
        || filter === null
      ) {
        this.typesModel = null;
        this.types = null;
      } else {
        if(filter?.value?.length > 0){
          this.types = filter?.value?.map(item => item.id);
        } else {
          this.types = undefined;
        }
      }
    }

    if (event?.filters?.state !== undefined) {
      let filter = event.filters.state as FilterMetadata;
      if (filter?.value === undefined
        || filter.value === null
      ) {
        this.statesModel = null;
        this.states = null;
      }
    }
    if (this.statesModel !== undefined
      && this.statesModel !== null
      && this.statesModel.length > 0
    ) {
      const values = this.statesModel;
      this.states = values?.map(item => item.id);
    } else {
      this.states = null;
    }

    // pagination
    this.pageNumber = event?.first !== undefined ? event.first / event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchOrders();
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }

}



