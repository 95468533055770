import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';


// {
//   "__typename": "SettingDescription",
//   "key": "framework_task_type_colors",
//   "label": "Farbcodes für Task Typen",
//   "description": "Farbcodes für Task Typen",
//   "type": "TaskTypeColor",
//   "defaultValue": {
//     "nexcore_rental_resourceAssignmenmt_refuel": "#DAFF00",
//     "default": "#EAEEEF",
//     "nexcore_rental_resourceAssignmenmt_in": "#00FDFF",
//     "nexcore_rental_resourceAssignmenmt_out": "#9B00FF"
//   },
//   "currentValue": {
//     "nexcore_rental_resourceAssignmenmt_refuel": "#DAFF00",
//     "default": "#EAEEEF",
//     "nexcore_rental_resourceAssignmenmt_in": "#00FDFF",
//     "nexcore_rental_resourceAssignmenmt_out": "#9B00FF"
//   },
//   "settings": {
//     "labels": {
//       "nexcore_rental_resourceAssignmenmt_refuel": "Nachtanken Task",
//       "default": "Standard Farbe",
//       "nexcore_rental_resourceAssignmenmt_in": "Rücknahme Task",
//       "nexcore_rental_resourceAssignmenmt_out": "Übergabe Task"
//     }
//   }
// }

@Component({
  selector: "nx-task-color-picker",
  templateUrl: "./nx-task-color-picker.component.html",
  styleUrls: ["./nx-task-color-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NxTaskColorPickerComponent),
      multi: true
    }
  ]
})
export class NxTaskColorPickerComponent implements ControlValueAccessor{

  public options: NxTaskColorPickerOptions[];
  public colorsForm: FormGroup;
  private _field: any;
  @Input() set field(field: any) {
    this._field = field;
    this.buildOptions();
  };

  // public addressForm: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {
  }


  /** value accessor */
  writeValue(address: any): void {
    // we initialize the form control with the current value that
    // should be sufficient to display the current value
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.colorsForm.disable();
    } else {
      this.colorsForm.enable();
    }
  }

  buildOptions() {
    if(this._field) {
      // collect all types from the default values array
      const types = Object.keys(this._field.defaultValue);
      // iterate over all types and create an option for each
      this.options = [];
      this.colorsForm = new FormGroup({});
      types.forEach(type => {
        this.options.push({
          type: type,
          label: this._field.settings.labels[type],
          defaultColor: this._field.defaultValue[type],
          currentColor: this._field.currentValue[type]
        });
        let formControl = this.fb.control(this._field.currentValue[type]);
        this.colorsForm.addControl(type, formControl);
      });
      this.colorsForm.valueChanges.subscribe(value => {
        this.propagateChange(value);
      });
    }
  }

  reset(option: NxTaskColorPickerOptions) {
    this.colorsForm.get(option.type).setValue(option.defaultColor);
  }
}

export interface NxTaskColorPickerOptions {
  type: string;
  label: string;
  defaultColor: string;
  currentColor: string;
}