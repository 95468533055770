import { CdkDragRelease, CdkDragStart } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { ApolloService, ConfirmDialogEventType, ConfirmDialogService, ConfirmDialogSeverity, EditDialogService, RolesService, ToastService } from '@nexato/nx-core-module';
import { Apollo, QueryRef } from 'apollo-angular';
import moment from 'moment';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Resource } from 'src/app/rent-module/shared/entities/resource/resource';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { Address } from 'src/app/shared-module/shared/entities/address/address';
import { environment } from 'src/environments/environment';
import { TourSettingsDialogComponent } from '../../components/tour-settings-dialog/tour-settings-dialog.component';
import { NxDragDrop } from '../../shared/dragDrop/evetns';
import { Driver } from '../../shared/entities/driver';
import { Tour } from '../../shared/entities/tour';
import { TourPlannerService } from '../../shared/services/tour/tour-planner.service';
import { TourService } from '../../shared/services/tour/tour.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-tours-detail-page',
  templateUrl: './tours-detail-page.component.html',
  styleUrls: ['./tours-detail-page.component.scss']
})
export class ToursDetailPageComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('xScrollContainer') public xScrollContainer: any;
  xScrollCleanup: any;

  public pageTitleMenuItems: MenuItem[];
  dialogRef: DynamicDialogRef | undefined;

  public loading = true;
  public tourDetailQuery: QueryRef<fromGraphQl.TourDetailQueryResponse>;
  public supplierDetailQuerySubscription: Subscription;
  public tour: Tour;
  public routeSubscription: Subscription;
  private result: any;
  private id: string;
  public drivers: Driver[];
  public resources: Resource[];
  public homebaseAddresses: Address[];
  public objectChoosen = false;

  public isTourInEditingMode = false;
  public _route: any;
  _sections: any[];
  _sumDistance: string;
  _sumDuration: number;
  _baseDuration: string;
  data: any;

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private apollo: Apollo,
    private apolloService: ApolloService,
    // private entityService: EntityService,
    private tourService: TourService,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    public rolesService: RolesService,
    public tourPlannerService: TourPlannerService,
    public dialogService: EditDialogService,
    private confirmDialogService: ConfirmDialogService,
    private toastService: ToastService
  ) {

  }

  ngAfterViewInit(): void {
    this.xScrollCleanup = autoScrollForElements({
      element: this.xScrollContainer.nativeElement,
    });
  }

  ngOnInit() {
    this.routeSubscription = this.route.params.pipe(
      filter(params => params["id"] !== undefined),
    ).subscribe((params: { [x: string]: string; }) => {
      this.id = params["id"]; // (+) converts string 'id' to a number
      this.subscribeToTourDetailsData();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.supplierDetailQuerySubscription.unsubscribe();
    if(this.xScrollCleanup){
      this.xScrollCleanup(); 
    }
  }

  subscribeToTourDetailsData() {
    this.tourDetailQuery = this.apollo.watchQuery<fromGraphQl.TourDetailQueryResponse>({
      pollInterval: environment.pollingIntervall,
      fetchPolicy: 'network-only',
      query: fromGraphQl.TOURS_DETAIL_STANDARD_QUERY,
      variables: {
        tourId: this.id,
      },
      notifyOnNetworkStatusChange: true
    });
    this.supplierDetailQuerySubscription = this.tourDetailQuery.valueChanges.subscribe(
      result => {

        if (result.networkStatus !== 6) {
          this.loading = result.loading;
        }
        if (result.networkStatus === 7) {
          // we´ve received fresh data
          this.result = result.data;
          let areSame = JSON.stringify(this.result) === JSON.stringify(this.data);
          if ( !areSame) {
            // console.log('Not same', areSame);
            this.resources = Resource.createResources(result?.data?.tour?.transportCandidates)
            this.tour = new Tour(result.data.tour);
            this.drivers = Driver.createDrivers(result.data.tour.driverCandidates);
            this.data = this.result;
            this.pageTitleMenuItems = this.getAvailablePageTitleItems();
          }
          this.loading= false;

          // this.vehicles = this.entityService.createVehiclesFromResult(result.data.tour.assignableVehicles);
          // this.homebaseAddresses = this.entityService.createAddressesFromResult(result.data.tour.assignableAddresses);
        }
      },
      () => {
        // restart polling after error
        this.tourDetailQuery = undefined;
        setTimeout( () => this.subscribeToTourDetailsData(), environment.pollingIntervall);
      }
    );

    }

  // tour
  saveTour(tour: Tour) {
    this.isTourInEditingMode = false;
    this.loading = true;
    this.tourService.updateTour(tour, this.tourDetailQuery);
  }

  editTour(event$: boolean) {
    this.isTourInEditingMode = event$;
  }


  changeOrder($event: { task: { id: any; }; newPosition: any; }) { // change the 'order' of the orders
    // this.tourService.changeOrder(this.tour.id, $event.task.id, $event.newPosition, this.tourDetailQuery);
  }

  // route
  routeCalculated(route: any) {
    this._route = route;
    // console.log('This is Route', this._route);
    this._sections = route.sections;

    this._sumDistance = this._sections.reduce( (acc, val) => {
      return acc + val.summary.length / 1000;
    }, 0);
    this._sumDuration = this._sections.reduce( (acc, val) => {
      return acc + val.summary.duration / 60;
    }, 0);
    this._baseDuration = this._sections.reduce( (acc, val) => {
      return acc + val.summary.baseDuration / 60;
    }, 0);
    this.cdr.detectChanges();
  }

  goBack() {
    // window.history.back();
    this.location.back();
  }

  openSettings(tour: Tour) {
    if (tour.state !== 'FINISHED') {
      this.dialogRef = this.dialogService.open(TourSettingsDialogComponent, {
        header: 'Tour bearbeiten',
        data: {
          tour: tour,
        },
      });
      this.dialogRef.onClose.subscribe(dialog=>{
        console.log('Dialog', dialog);
        if (dialog) {
          this.tourService.updateTourInputAndExecute(dialog, () => this.tourDetailQuery.refetch());
        }
      });
    }
  }

   saveSettings(event: any) {
    // console.log('settings', event);
    this.loading = true;
    this.tourService.updateTourInput(event, this.tourDetailQuery);
    this.refetchQuery();
    this.loading = false;

  }

  refetchQuery() {
    this.tourDetailQuery?.startPolling(environment.pollingIntervall);
  }

  refetch() {
    this.tourDetailQuery.refetch();
  }
  
  getHeader(): string{
      return `${this.tour?.name? this.tour?.name: '–'} / ${(this.tour?.dateTimeReference ? (moment(new Date(this.tour.dateTimeReference)).format('DD.MM.yyyy')): '–' )}
      / ${(this.tour?.location?.name? this.tour?.location?.name: '–')}`;
  }
  getSubHeader(): string {
    return  `( ${(this.tour?.resource?.name? this.tour?.resource?.name: 'kein Fahrzeug geplant')} /
         ${ (this.tour?.getAssigneeNameHeader()? this.tour?.getAssigneeName(): 'kein Fahrer geplant') } )`;
  }

  toLetters(num: number): string {
    "use strict";
    var mod = num % 26,
        pow = num / 26 | 0,
        out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
    return pow ? this.toLetters(pow) + out : out;
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  isDragDisabled(task: Task) {
    return this.tourPlannerService.isDragDisabled(task);
  }

  isDropDisabled(tour: Tour): boolean {
    return this.tourPlannerService.isDropDisabled(tour); // Call service method
  };

  dragDropped(event: NxDragDrop<Task, Tour> | any) {
    console.log('Event', event);
    this.tourPlannerService.dragDroppedWithCallback(event, () => {
      this.tourDetailQuery.refetch();
    });
  }

  dragStarted(event: CdkDragStart<Task>) {
    this.tourDetailQuery?.stopPolling();
  }

  dragReleased(event: CdkDragRelease<Task>) {
    this.tourDetailQuery?.startPolling(environment.pollingIntervall);
  }

  getAvailablePageTitleItems() {
    let availabelPageTitleMenuItems: any[] = [];

    availabelPageTitleMenuItems.push({
      label: 'Tour bearbeiten',
      disabled: this.tour?.state === 'FINISHED' || this.tour?.state === 'ARCHIVED',
      command: () => {
        this.openSettings(this.tour);
      },
      role: 'nexcore_tour_update'
    });

    availabelPageTitleMenuItems.push({
      label: 'Tour löschen',
      disabled: this.tour?.state === 'FINISHED' || this.tour?.state === 'ARCHIVED' || this.tour?.tasks?.length > 0,
      command: () => {
        this.deleteTour(this.tour);
      },
      role: 'nexcore_tour_delete'
    });

    availabelPageTitleMenuItems.push({
      label: 'Tour beenden',
      disabled: true,
      command: () => {
        // TODO
      },
      role: 'nexcore_tour_update'
    });

    return availabelPageTitleMenuItems.filter(item => this.rolesService.hasRole(item.role));
  }

  public deleteTour(tour: Tour){
    const message = 'Möchtest Du die Tour ' + tour.name + ' wirklich löschen?';
    this.dialogRef = this.confirmDialogService.open({
      message: message,
      severity: ConfirmDialogSeverity.WARN,
      header: 'Tour löschen'
    });
    this.dialogRef.onClose.subscribe( (result : ConfirmDialogEventType) => {
      if(result === ConfirmDialogEventType.CONFIRM){
        this.tourService.deleteTourAndExecute(tour, () => {
          this.toastService.addToastWithMessage('Tour gelöscht.');
          this.router.navigate(["/tour-planner"])
        });
      };
    })
  }
}
