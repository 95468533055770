import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';
import { ContactPerson } from '../../shared/entities/contactPerson/contactPerson';

export const CONTACT_PERSON_LIST_QUERY = gql`
query ContactPersonList($number: Int, $size: Int, $sortProperty: String, $sortDirection: String, $text: String, $contactId: UUID, $isOneTimeContact: Boolean)
{
  contactPersonList: getContactPersonsPaged(filter: {
    sortRequest: { sortProperties: [$sortProperty], sortDirection: $sortDirection },
    pagination: { number: $number, size: $size,  sortProperty: $sortProperty, sortDirection: $sortDirection},
    text: $text,
    contactId: $contactId,
    isOneTimeContact: $isOneTimeContact
  }
    ) {
    totalPages
    totalElements
    number
    size
    content{
      id
      salutation
      firstName
      lastName
      email
      name
      phoneNumber
      mobileNumber
    }

    }
}
`;

export interface ContactPersonListQueryResponse {
  contactPersonList: Page<ContactPerson>;
}
