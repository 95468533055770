<div class="flex flex-col" [formGroup]="colorsForm">
  <div *ngFor="let option of options; let i = index" class="flex flex-row">
    <div class="grow mt-1">{{option.label}}</div>
    <div class="flex flex-row">
      <p-colorPicker class="mt-1" [formControlName]="option.type"></p-colorPicker>
      <nx-default-button
        [disabled]="colorsForm.disabled"
        (click)="reset(option)"
        tooltip="Auf Standardwert zurücksetzen"
        class="flex justify-end ml-2"
        [icon]="'icon-return'">
      </nx-default-button>
    </div>
  </div>
</div>