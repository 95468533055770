import { Injectable } from "@angular/core";
import { ApolloService } from "@nexato/nx-core-module";
import { AddressInput } from "src/app/shared-module/shared/entities/address/address";
import * as fromGraphQl from './graphql';

@Injectable()
export class AddressService {

  constructor(
    private apolloService: ApolloService
  ) {

  }

  acceptAddress(addressId: string, callback: any) {
    this.apolloService.mutateAndExecute(
        fromGraphQl.ACCEPPT_ADDRESS,
        {
          addressId: addressId
        },
        callback
      );
  }

  updateAddress(addressInput: AddressInput, callback: any) {
    this.apolloService.mutateAndExecute(
        fromGraphQl.UPDATE_ADDRESS,
        {
          addressInput: addressInput
        },
        callback
      );
  }
}
