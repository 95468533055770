<nx-pageTitle-box
  class="mb-3"
  [label]="'Dashboard'"
  [icon]="'icon-dashboard'"
  [iconToolTip]="'Dashboard'">
  <ng-template nxTemplate="end">
    <app-date-selector
      class="date-selector"
      className="white"
      [displayLabel]=false
      (date)="dateChanged($event)"
      [initialDate]="this.date">
    </app-date-selector>
    <app-location-selector
      class="ml-2"
      [style]="'dark'"
      [multi]="false"
      [stateKey]="'dashboardComponent'"
      [storageType]="'local'"
      (locations)="selectLocation($event)">
    </app-location-selector>
  </ng-template>
</nx-pageTitle-box>

<div class="grid grid-cols-2 gap-4">
  <div>
    <app-dashboard-tour-list
      [filter]="{'type':'planned', 'location': selectedLocation?.id }"
      [selectedLocation]="selectedLocation?.id"
      [setDate]="date" />
    </div>
  <div>
    <app-dashboard-tour-list
      [filter]="{'type':'running', 'location': selectedLocation?.id }"
      [selectedLocation]="selectedLocation?.id"
      [setDate]="date" />
  </div>
  <div>
    <app-dashboard-task-list
      [filter]="{'type':'out', 'location': selectedLocation?.id }"
      [selectedLocation]="selectedLocation?.id"
      [setDate]="date" />
  </div>
  <div>
    <app-dashboard-task-list [filter]="{'type':'in', 'location': selectedLocation?.id }"
      [selectedLocation]="selectedLocation?.id"
      [setDate]="date" />
  </div>
  <div>
    <app-dashboard-tour-list
      [filter]="{'type':'finished', 'location': selectedLocation?.id }"
      [selectedLocation]="selectedLocation?.id"
      [setDate]="date" />
    </div>
  <div>
    <app-dashboard-task-list
      [filter]="{'type':'completed', 'location': selectedLocation?.id }"
      [selectedLocation]="selectedLocation?.id"
      [setDate]="date" />
    </div>
</div>
