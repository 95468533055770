import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings-overview-box',
  templateUrl: './settings-overview-box.component.html',
  host: {
    'class': 'hover:bg-[#f3f4f6] cursor-pointer rounded-md'
  },
  styleUrls: ['./settings-overview-box.component.scss'],
})
export class SettingsOverviewBoxComponent
{

  @Input() icon: string;
  @Input() label: string;
  @Input() description: string;
  @Input() path: string;
  @Input() tenantName: string;

  constructor(
    protected readonly router: Router,
  ) {
  }

  goToPath() {
    if (!this.path?.includes('company')) {
      this.router?.navigate([this.path]);
    }
    else {
      this.router.navigate(['settings/company']);
    }
  }

}
