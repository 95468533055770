import { Address, AddressInput } from "../address/address";

export class LocationEntity {
  id: string;
  externalId: string;
  name: string | undefined;
  label: string | undefined;
  email: string| undefined;
	phoneNumber: string | undefined;
  web: string;
  type: LocationType [];
	address: Address;
  isDeletable: Boolean;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.label = payload.label;
      this.email = payload.email;
      this.phoneNumber = payload.phoneNumber;
      this.isDeletable = payload.isDeletable;
      this.web = payload.web;
      this.type = payload.type;
    }
    if (payload.address) {
      this.address = new Address(payload.address);
    }
  }


  /**
   * creates models from a result
   * @param result Array of any
   * @returns Bookigs
   */
  static createLocations(result: Array<any>): LocationEntity[] {
    const locations = new Array<LocationEntity>();
    if (result) {
      result.map((location) => {
        locations.push(new LocationEntity(location));
      });
    }
    return locations;
  }
}
export class LocationInput {
  id: string | undefined;
  externalId: string | undefined;
  name: string | undefined;
  label: string | undefined;
  email: string |  undefined;
	phoneNumber: string |  undefined;
	web: string |  undefined;
	type: LocationType [];
	address: AddressInput;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.label = payload.label;
      this.email = payload.email;
      this.phoneNumber = payload.phoneNumber;
      this.web = payload.web;
      this.type = payload.type
      this.address = payload.address;
    }
  }
}

export enum LocationType {
  COMPANY, DEPOT, TOURBASE
}



