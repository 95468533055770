import { Component, Input } from '@angular/core';
import { Order } from '../../shared/entities/order/order';

@Component({
  selector: 'app-order-rent-details',
  templateUrl: './order-rent-details.component.html',
  styleUrls: ['./order-rent-details.component.scss']
})
export class OrderRentDetailsComponent {
@Input() order: Order;
@Input() customSections: any;
public Order = Order;

CheckSectionInAttributes( id: string) {
  if (this.order?.customAttributes) {
    for (let attribute of this.order?.customAttributes) {
      if (attribute?.customAttributeSection.id === id) {
        return true;
      }
    }
  }
  return false;
}
CheckDefintionInAttributes(id: string) {
  if (this.order?.customAttributes) {
    for (let attribute of this.order?.customAttributes) {
      if (attribute?.customAttributeDefinition?.id === id) {
        return true;
      }
    }
  }
  return false;
}
getValue(id: string) {
  for (let attribute of this.order?.customAttributes){
    if (attribute?.customAttributeDefinition?.id === id){
      return attribute?.value;
    }
  }
  return '-';
}


}
