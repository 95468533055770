<div class="body flex flex-col">
  <div class="flex items-center justify-center">
    <img src="/assets/NX_Icon_Missing-License.svg" alt="Kein Lizenz" >
  </div>
  <div class="flex items-center justify-center text-base font-light text-[#848488] leading-3">
    <h2>Ups! Hier fehlt eine {{licenseType}} Lizenz.</h2>
  </div>
  <div class="flex items-center justify-center text-base font-light text-[#848488] leading-3">
    <span>Bitte wende Dich an Deinen Administrator oder setze Dich mit unserem Support in Verbindung.</span>
  </div>
</div>
