import { Component, Input } from '@angular/core';


@Component({
  // tslint:disable-next-line:component-selector
  selector: "app-address-indicator",
  templateUrl: "address-indicator.html",
  host: {
    'class': 'flex'
  },
  styleUrls: ["./address-indicator.scss"]
})
export class AddressIndicatorComponent {

  @Input() grade: string;
  @Input() size: string;

  constructor(
  ) {
  }

}
