import { Component, Input } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-picturesInput-control-value-view, [app-picturesInput-control-value-view]',
  templateUrl: './picturesInput-control-value-view.component.html',
  styleUrls: ['./picturesInput-control-value-view.component.scss']
})
export class PicturesInputControlValueViewComponent {

  public fileApi = environment.fileApi;
  public archived = false;
  public settings = {
    counter: true,
    zoomFromOrigin: false,
    plugins: [lgZoom],
  };
  public files: any[];

  _controlTemplate: ControlTemplate;
  @Input() set controlTemplate(controlTemplate: ControlTemplate) {
    if(controlTemplate){
      this._controlTemplate = controlTemplate;
      this.files = this.getAllPictures();
    }
  }

  constructor(
  ) {
  }

  public showPictures(): boolean {
    return this.files?.length > 0;
  }

  public showNoPicturesPossible(): boolean {
    return this._controlTemplate?.settings?.value?.picturesNotPossible;
  }

  private getAllPictures(): string[]{
    let result = new Array<string>();
    console.log(this._controlTemplate?.settings?.value);
    // collect requested pictures
    if(this._controlTemplate?.settings?.value?.['requestedPictures']) {
      for (const key in this._controlTemplate.settings.value['requestedPictures']) {
        const value = this._controlTemplate.settings.value['requestedPictures'][key];
        if(value?.file?.ownerState === 'ARCHIVED') {
          // if one picture is archived, the whole control is archived
          this.archived = true;
          return;
        }
        if(value?.file?.fullPath) {
          result.push(value?.file?.fullPath);
        }
      }
    }
    // collect additional pictures
    if(this._controlTemplate?.settings?.value?.['additionalPictures']) {
      this._controlTemplate?.settings?.value['additionalPictures'].forEach((element: any) => {
        if(element?.file?.ownerState === 'ARCHIVED') {
          // if one picture is archived, the whole control is archived
          this.archived = true;
          return;
        }
        if(element?.file?.fullPath) {
          result.push(element?.file?.fullPath);
        }
      });
    }
    return result;
  }
}
