
    <div  class="full-width progressbar-bar flex flex-row">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="main-component-wrapper mat-typography flex flex-row justify-start itmes-center">
      <div  class="full basis-full">
        <router-outlet></router-outlet>
      </div>
    </div>

