import { gql } from 'apollo-angular';
import { Tour } from '../../shared/entities/tour';

export const TOUR_SETTINGS_DIALOG_QUERY = gql`
query GetTour(
  $tourId: UUID
)
{
  tour: getTour(
    id: $tourId
  ) {
    id
    name
    scheduledDepartureDateTime
    scheduledArrivalDateTime
    actualDepartureDateTime
    actualArrivalDateTime
    dateTimeReference
    state
    note
    driver{
      id
      firstName
      lastName
    }
    resource {
      id
      name
    }
    effectiveArrivalDateTime
    effectiveDepartureDateTime
    driverCandidates{
      id
      firstName
      lastName
    }
    isDeletable
    possibleStartAddresses {
      id
      street
      houseNumber
      postalCode
      city
      geocodingResultGrade
    }
    possibleEndAddresses {
      id
      street
      houseNumber
      postalCode
      city
      geocodingResultGrade
    }
    departureAddress {
      id
    }
    arrivalAddress {
      id
    }
    transportCandidates {
      id
      name
    }
  }
}
`;


export interface TourSettingsDialogQueryResponse {
  tour: Tour;
}


