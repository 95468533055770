import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { EditDialogComponent, EditDialogInstance, LoadingService, ToastService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map, Observable, of } from 'rxjs';
import { LocationEntity, LocationInput } from 'src/app/shared-module/shared/entities/location/location';
import { Resource, ResourceInput } from '../../shared/entities/resource/resource';
import { ResourceService } from '../../shared/services/resource/resource.service';

@Component({
  selector: 'app-resource-dialog',
  templateUrl: './resource-dialog.component.html',
  styleUrls: ['./resource-dialog.component.scss'],
  providers: [],
})
export class ResourceDialogComponent
  implements  EditDialogInstance
{
  // general
  public resource: Resource;

  // form
  public resourceFormGroup: FormGroup;
  public functions: any[];
  public locations: LocationEntity[]

  constructor(
    apollo: Apollo,
    private resourceService: ResourceService,
    private loadingService: LoadingService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private toastService: ToastService

  ) {

    if (this?.config?.data?.resource) {
      this.resource = this.config?.data?.resource;
    }
    if (this?.config?.data?.locations) {
      this.locations = this.config?.data?.locations;
    }
    this.initTypes();
    this.initializeForm();
  }

  initTypes(): void {
    this.functions = [ {
      name: 'Transport',
      value: 'TRANSPORT',
      id:'TRANSPORT'
    }
    ];

  }

  initializeForm(): void {
    this.resourceFormGroup = new FormGroup({});
    this.resourceFormGroup.addControl('name', new FormControl(undefined, Validators.required));
    this.resourceFormGroup.addControl('number', new FormControl(undefined, Validators.required, [this.validateNumberNotTaken.bind(this)]));
    this.resourceFormGroup.addControl('functions', new FormControl());
    this.resourceFormGroup.addControl('location', new FormControl());
    if (this.resource) {
      this.resource = new Resource(this.resource);
      const locationId = this.resource?.location?.id;
      this.resourceFormGroup.patchValue(this.resource);
      this.resourceFormGroup.controls['location']?.setValue(locationId);
      this.resourceFormGroup.get('number')?.disable();
    }
  }

  onConfirm(editDialogComponent: EditDialogComponent<any>): void {
    let resourceInput = this.prepareResourceModel();
    if(this.resource){
      this.loadingService.setLoading(true);
      this.resourceService.updateResource(resourceInput, (result: any) => {
        this.ref.close(result?.data?.updateResource?.id);
      });
    } else {
        this.resourceService.createResource(resourceInput, (result: any) => {
          if (result) {
            this.loadingService.setLoading(true);
            this.ref.close(result);
          } else {
            editDialogComponent.confirmDisabled = false;
            this.resourceFormGroup.controls['number']?.reset();
            this.toastService.addToastWithMessage('Resource mit angegeben Nummer ist schon da.');
          }
        });

    }
  }

  onReject(editDialogComponent: EditDialogComponent<any>): void {
    this.ref.close(false);
  }

  getEditForm(): FormGroup {
    return this.resourceFormGroup;
  }

  prepareResourceModel(): ResourceInput {
    const resourceModel = new ResourceInput();
    // set the id, if we have one
    if (this.resource) {
      resourceModel.id = this.resource?.id;
    }
    // set attributes from the input
    const formModel = this.resourceFormGroup.value;
    resourceModel.name = formModel?.name;
    resourceModel.number = formModel?.number;
    resourceModel.functions = formModel?.functions;
    resourceModel.location = formModel?.location? new LocationInput({id: formModel?.location}): null;

    // copy other attributes, that cannot be filled out by the user
    // no resource available, when we create the resource
    if(this.resource) {
      resourceModel.customAttributes = this.getCustomAttributeInput(this.resource?.customAttributes);
      resourceModel.externalId = this.resource?.externalId; // the overwrite durig update should be
      // prevented on the backend, too - but just to make sure
    }

    console.log('To svae', resourceModel);

    return resourceModel;
  }

  getCustomAttributeInput(customAttributes: any[]){
    let customAttributesInputs = [];
    if(customAttributes) {
      for(let customAttribute of customAttributes) {
        let customAttributeInput = {
          id: customAttribute?.id,
          key: customAttribute?.customAttributeDefinition?.id,
          value: customAttribute?.value,
          ownerId:customAttribute?.ownerId
        };
        customAttributesInputs.push(customAttributeInput);
      }
    }

    return customAttributesInputs;
  }

  validateNumberNotTaken(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    // catch the case if someone is just editing an already existing user
    if (this.resource && control.value === this.resource.number) {
      return of(null);
    }
    return this.resourceService.checkNumberNotTaken(control.value).pipe(
      map((res) => {
        return res ? { numberExists: res } : null;
      })
    );
  }

}
