<div [formGroup]="assignmentFormGroup" class="form flex flex-col">
  <nx-edit-select-input
    formControlName="assignmentType"
      [options]="assignemtTypeOptions"
      label="Zuweisungstyp">
  </nx-edit-select-input>
  <nx-edit-select-input
    formControlName="assigneeId"
    [options]="assigneeOptions"
    label="Zuweisen an">
  </nx-edit-select-input>
  <nx-edit-dateTime-input
    formControlName="dueDateTimePeriod"
    label="Fälligkeit">
  </nx-edit-dateTime-input>
  <!-- <nx-edit-text-input
    formControlName="name"
    placeholder="Nexato Gmbh"
    label="Name">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="label"
    label="Label">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="externalId"
    label="Identifier">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="email"
    placeholder="mail@nexato.de"
    label="E-Mail">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="phoneNumber"
    placeholder="+49 661 480 191 00"
    label="Telefon">
  </nx-edit-text-input>
  <nx-edit-text-input
    formControlName="web"
    placeholder="http://www.nexato.de"
    label="Web">
  </nx-edit-text-input>
  <nx-edit-multiselect-input
    [label]="'Typ'"
    [options]="types"
    formControlName="type"
    placeholder="Firma, Tourbasis, ...">
  </nx-edit-multiselect-input> -->
</div>
