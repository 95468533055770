import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HereService } from 'src/app/rent-module/shared/services/here.service';
import { Tour } from '../../shared/entities/tour';



declare function require(path: string): any;
@Component({
  selector: "app-tours-detail-planning-map",
  templateUrl: "./tours-detail-planning-map.component.html",
  styleUrls: ["./tours-detail-planning-map.component.scss"]
})
export class ToursDetailPlanningMapComponent {
  @Input()
  set tour(tour: Tour) {
    if (tour) {
      this.routeCalculationPossible = this.hereService.calculateRoute(tour, this.map, ( route ) => {
        this.routeCalculated.emit( route )
      });
    }
  }

  @Output() routeCalculated = new EventEmitter();

  @ViewChild('mapContainer') set mapContainer(mapContainer: ElementRef) {
    if (mapContainer) {
      this._mapContainer = mapContainer;
      this.initializeMap();
    }
  }
  private _mapContainer: ElementRef;
  private map: any;
  private behavior: any;
  private routeCalculationPossible = false;


  constructor(
    public hereService: HereService,
  ) {
  }

  initializeMap(){
    this.map = this.hereService?.initializeMap(this._mapContainer.nativeElement);
    // console.log('Map', this.map);
    this.behavior = this.hereService?.addBehaviorToMap(this.map);
    this.centerMap()
  }

  centerMap(){
    this.map.setCenter({lat:52.5159, lng:13.3777});
    this.map.setZoom(14);
  }

}
