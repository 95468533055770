import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloService } from '@nexato/nx-core-module';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { CommentInput } from '../../entities/comment/comment';
import { Order } from '../../entities/order/order';
import * as fromGraphQl from './graphql';


/**
 * The models service.
 */
@Injectable()
export class OrderService {
  constructor(private apolloService: ApolloService,
    private http: HttpClient) { }

  // /**
  //  * updates a Order
  //  * @param orderInput Order Input
  //  * @param callback method
  //  */
  // public updateOrder(orderInput: OrderInput, callback: any): void {
  //   this.apolloService.mutateAndExecute(
  //     fromGraphQl.UPDATE_LOCATION_MUTATION,
  //     {
  //       orderInput,
  //     },
  //     callback
  //   );
  // }

  // /**
  //  * creates a Order
  //  * @param orderInput orderInput input
  //  * @param callback method
  //  */
  // public createOrder(orderInput: OrderInput, callback: any): void {
  //   // console.log('Input', orderInput);
  //   this.apolloService.mutateAndExecute(
  //     fromGraphQl.CREATE_LOCATION_MUTATION,
  //     {
  //       orderInput,
  //     },
  //     callback
  //   );
  // }

  /**
   * deletes a order
   * @param orderId licencePlate Id
   * @param callback method
   */
  public deleteOrder(orderId: string, callback: any): void {

    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_ORDER_MUTATION,
      {
        orderId,
      },
      callback
    );
  }

  /**
   * Generates a public link for an order
   * @param orderId id of the order
   * @param callback callback method
   */
    public getPublicLink(id: string, callback: any): void {
      this.apolloService.mutateAndExecute(
        fromGraphQl.GET_PUBLIC_LINK_MUTATION,
        {
          id,
        },
        callback
      );
    }

  public downloadPDF(order: Order): void {

    const url = environment.backendUri +"/rest/order/" + order.id +"/export?type=pdf";
    this.http.get(url, { responseType: 'blob' }).subscribe((response: any) => {
      const blob = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, order?.number);
    });
  }

  public createComment(commentInput: CommentInput,callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_COMMENT_MUTATION,
      {
        input: commentInput,
      },
      callback
    );
  }
  public updateComment(commentInput: CommentInput,callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_COMMENT_MUTATION,
      {
        input: commentInput,
      },
      callback
    );
  }

  public deleteComment(id: string, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_COMMENT_MUTATION,
      {
         id,
      },
      callback
    );
  }

  public blockArchiving(id: string, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.BLOCK_ARCHIVING_MUTATION,
      {
         id
      },
      callback
    );
  }

  public deleteFile(id: string, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_FILE_MUTATION,
      {
         id,
      },
      callback
    );
  }

}
