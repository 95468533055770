import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { LocationEntity } from '../../shared/entities/location/location';
import * as fromGraphQl from './graphql';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss']
})
export class LocationSelectorComponent implements OnInit {

  public moment = moment;
  @Input() multi = true;
  @Input() stateKey: string;
  @Input() storageType: 'session' | 'local' | 'none' = 'none';
  @Input() placeholder = 'Niederlassung';
  @Input() style: "light" | "transparent" | "dark" = "transparent";
  public _type: string;
  @Input() set type(type: string) {
    this._type = type;
    this.query();
  }
  @Output() locations = new EventEmitter<LocationEntity[]>();
  public selectedLocation: LocationEntity;
  public selectedLocations: LocationEntity[];
  public locationOptions: LocationEntity[];

  constructor(
    private apollo: Apollo,
  ) {
  }

  ngOnInit(): void {
    this.query();
  }

  query(){
    this.apollo.query<fromGraphQl.LocationsQueryResponse>({
      query: fromGraphQl.LOCATIONS_QUERY,
      variables: {
        type: this._type
      },
      fetchPolicy: 'cache-first',
    }).subscribe((res) => {
      if(res.data?.locations){
        this.locationOptions = LocationEntity.createLocations(res.data.locations);
        // setup the component
        const persistedLocationIds = this.loadPersistedLocationIds();
        // filter for all locations, that are in the persisted location ids
        if (persistedLocationIds) {
          const persistedLocations = this.locationOptions.filter(location => persistedLocationIds?.includes(location.id));
          if (persistedLocations) {
            this.setLocations(persistedLocations);
            return;
          } else {
            // clear persisted location, if it is not available
            // TODO
          }
        }
      }
    });
  }

  locationChange($event) {
    // check, if event.value is an array, then set it directly, otherwise check, if its
    // a single value and set it as arra<
    if (Array.isArray($event.value)) {
      this.setLocations($event.value);
    } else {
      this.setLocations([$event.value]);
    }
    // this.setLocations($event.value);
  }

  setLocations(locations: LocationEntity[]) {
    this.selectedLocations = locations;
    this.setLocation()
    this.persistLocationIds();
    this.locations.emit(this.selectedLocations);
  }

  setLocation() {
      this.selectedLocation = this.selectedLocations?.[0];
  }

  persistLocationIds() {
    if (this.storageType !== 'none' && this.stateKey !== undefined && this.stateKey.length > 0) {
      const storage = this.storageType === 'session' ? sessionStorage : localStorage;
      // crate array of location ids from selected locations
      const locationIds = this.selectedLocations.map(location => location.id);
      storage.setItem(this.getFullStateKey(), JSON.stringify(locationIds));
    }
  }

  loadPersistedLocationIds(): string | undefined {
    if (this.storageType !== 'none' && this.stateKey !== undefined && this.stateKey.length > 0) {
      const storage = this.storageType === 'session' ? sessionStorage : localStorage;
      const storedLocationIds = storage.getItem(this.getFullStateKey());
      if (storedLocationIds) {
        try {
          let locationIds = JSON.parse(storedLocationIds);
          return locationIds;
        } catch (error) {
          // error while parsing stored date - clean up
          storage.removeItem(this.getFullStateKey());
        }
      }
    }
    return undefined;
  }

  getFullStateKey(): string {
    return "LocationSelectorComponent." + this.stateKey;
  }
}
