import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-signatureInput-control-value-view, [app-signatureInput-control-value-view]',
  templateUrl: './signatureInput-control-value-view.component.html',
  styleUrls: ['./signatureInput-control-value-view.component.scss']
})
export class SignatureInputControlValueViewComponent {

  public archived = false;
  public fileApi = environment.fileApi;

  _controlTemplate: ControlTemplate;
  @Input() set controlTemplate(controlTemplate: ControlTemplate) {
    if(controlTemplate){
      this._controlTemplate = controlTemplate;
      if(controlTemplate?.settings?.value?.file?.ownerState === 'ARCHIVED') {
        this.archived = true;
      }
    }
  }

  constructor(
  ) {
  }

  public showCustomerOnSite(): boolean {
    return this._controlTemplate?.settings?.showCustomerOnSite;
  }

  public customerOnSite(): boolean { 
    return this._controlTemplate?.settings?.value?.customerOnSite;
  }

  public showCustomerOnSiteName(): boolean {
    return this._controlTemplate?.settings?.showCustomerOnSiteName;
  }
}
