<ng-container *ngIf="withMainHeadline" class="mx-auto pt-[22px] px-[1.25rem] h-[calc(100%-100px)]">
  <nx-pageTitle-box class="mb-3" [label]="'Ressourcen'" [icon]="'icon-item'" [iconToolTip]="'Ressourcen'">
  </nx-pageTitle-box>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-container *ngIf="!withMainHeadline">
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-template #table>
  <div class="bodyWrapper bg-white h-[calc(100%-100px)]">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Ressourcenliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center mr-3">
        <input pInputText type="text" class="p-inputtext p-component p-element"
          (input)="dtresources.filterGlobal($event.target?.['value'], 'contains')" placeholder="Ressource suchen" />
          <nx-default-button
            class="ml-3"
            *ngIf="rolesService?.hasRole('nexcore_resource_create')"
            [style]="'light'"
            (click)="createResource()"
            [label]="'Ressource anlegen'"
            [icon]="'icon-plus'">
        </nx-default-button>
      </div>
    </div>
    <p-table #dtresources [lazy]="true" (onLazyLoad)="loadData($event)" [value]="dataSource?.data" [scrollable]="true"
      [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15, 20, 50]" [totalRecords]="dataSource?.totalElements"
      [loading]="loading" scrollHeight="flex" [sortField]="sortField" [sortOrder]="sortOrder"
      [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} von {totalRecords}"
      [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th colspan="5">
            <div class="flex flex-row">
              <div class="basis-2/3 flex flex-row gap-2">

                <p-columnFilter field="function" matchMode="in" [showMenu]="false" [showMatchModes]="false"
                  [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false"
                  [showButtons]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <nx-filter-multiSelect [className]="'table-filter'" [filter]="false" [showToggleAll]="false"
                      [showHeader]="false" [appendTo]="'body'" [options]="functionsArray" [(ngModel)]="functionModel"
                      [showClear]="true" [maxSelectedLabels]="1" placeholder="Funktion" (onClear)="filter($event)"
                      (onChange)="filter($event?.value)">
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          {{option?.name}}
                        </div>
                      </ng-template>
                    </nx-filter-multiSelect>
                  </ng-template>
                </p-columnFilter>

                <p-columnFilter field="location" matchMode="in" [showMenu]="false" [showMatchModes]="false"
                  [showOperator]="false" [showAddButton]="false" [showApplyButton]="false" [showClearButton]="false"
                  [showButtons]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <nx-filter-multiSelect [className]="'table-filter'" [filter]="false" [(ngModel)]="locationModel"
                      [maxSelectedLabels]="1" [showClear]="true" [showToggleAll]="false" [showHeader]="false"
                      appendTo="body" [options]="locationsList" placeholder="Niederlassung" optionLabel="name"
                      (onClear)="filter($event)" (onChange)="filter($event?.value)">
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          {{option?.name}}
                        </div>
                      </ng-template>
                    </nx-filter-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
              <div class="basis-1/3 flex flex-row justify-end">
                <p-dropdown [styleClass]="'sort'" appendTo="body" [options]="sortOptions" optionLabel="label"
                  [showClear]="false" (onChange)="sort($event)"></p-dropdown>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th>Nummer</th>
          <th>Name</th>
          <th>Funktion</th>
          <th>Niederlassung</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-resource>
        <tr class="clickable" [newTabNavigation]="['./resources/resource', resource?.id]">
          <td>{{resource?.number}}</td>
          <td>{{resource.name}}</td>
          <td>{{resource?.getFunctionName()}}</td>
          <td>{{resource?.location?.name}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Ressourcen vorhanden.</td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
    </ng-template>
  </div>
</ng-template>
