import { File } from "@nexato/nx-core-module";
import { LocationEntity } from "src/app/shared-module/shared/entities/location/location";
import { Contact } from "../../../../rent-module/shared/entities/contact/contact";
import { ContactPerson, ContactPersonInput } from "../../../../rent-module/shared/entities/contactPerson/contactPerson";
import { LineItem } from "../../../../rent-module/shared/entities/lineItem/lineItem";
import { Comment } from "../comment/comment";

export class Order {
  id: string | undefined;
  externalId: string | undefined;
  number: string;
  lineItems: LineItem[];
  customer: Contact;
  type: OrderType;
  state: OrderState;
  customAttributes: any[];
  schedulingInformation: any;
  isDeletable: boolean;
  orderer: ContactPerson;
  note: string;
  customerOrderNumber: string;
  location: LocationEntity;
  archive: boolean;
  comments: Comment[];
  files: File[];
  fileCount: any;
  commentCount: any;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.number = payload.number;
      // console.log('test');
      if(payload.lineItems && payload.lineItems.length > 0){
        this.lineItems = new Array<LineItem>();
        payload.lineItems.map( (lineItem: any) => {
          this.lineItems.push(new LineItem(lineItem));
        })
      }
      this.type = payload.type;
      this.state = payload.state;
      this.customAttributes = payload.customAttributes;
      this.isDeletable = payload.isDeletable;
      this.customerOrderNumber = payload.customerOrderNumber;
      this.note = payload.note;
      this.schedulingInformation = payload?.schedulingInformation
      this.archive = payload?.archive;
    }
    if (payload.customer) {
      this.customer = new Contact(payload.customer);
    }
    if (payload.orderer) {
      this.orderer = new ContactPerson(payload.orderer);
    }
    if (payload?.lineItems) {
      this.lineItems = LineItem.createLineItems(payload.lineItems);
    }
    if (payload.location) {
      this.location = new LocationEntity(payload.location);
    }
    if (payload?.comments) {
      this.comments = Comment.createComments(payload.comments);
    }
    if (payload?.files) {
      this.files = payload.files;
    }
    this.fileCount = payload?.fileCount;
    this.commentCount = payload?.commentCount;
  }

  /**
   * creates models from a result
   * @param result Array of any
   * @returns Bookigs
   */
  static createOrders(result: Array<any>): Order[] {
    const orders = new Array<Order>();
    if (result) {
      result?.map((order) => {
        orders.push(new Order(order));
      });
    }
    return orders;
  }
  static getOrderType(type: string): string {
    switch (type) {
      case 'RENTAL':
        return 'Miete';
      case 'OPERATOR':
        return 'Bedieneinsatz';
      case 'SERVICE':
        return 'Service';
      default:
        return '';
    }
  }

  static getOrderTypes(): any[] {
    const orderTypes: { id: string; name: string, label: string }[] = [];
    orderTypes.push(
      {
        id: 'RENTAL',
        name: 'Miete',
        label: 'Miete',
      }
    );
    return orderTypes;
  }

  static getOrderState(state: string): string {
    switch (state) {
      case 'NEW':
        return 'neu';
      case 'RUNNING':
        return 'laufend';
      case 'COMPLETED':
        return 'abgeschlossen';
      case 'ARCHIVED':
        return 'archiviert';
      default:
        return '';
    }
  }

  public getOrderStateSeverity(): string {
    return Order.getOrderStateSeverityStatic(this.state + '');
  }

  static getOrderStateSeverityStatic(state: string): string {
    switch (state) {
      case 'NEW':
        return 'info';
      case 'RUNNING':
        return 'warning';
      case 'COMPLETED':
        return 'success';
      case 'ARCHIVED':
        return 'info';
      default:
        return '';
    }
  }

  static getOrderStates(): any[] {
    const orderStates: { id: string; name: string, label: string }[] = [];
    orderStates.push(
      {
        id: 'NEW',
        name: 'Neu',
        label: 'Neu',
      },
      {
        id: 'RUNNING',
        name: 'Laufend',
        label: 'Laufend',
      },
      {
        id: 'COMPLETED',
        name: 'Abgeschlossen',
        label: 'Abgeschlossen',
      },
      {
        id: 'ARCHIVED',
        name: 'Archiviert',
        label: 'Archiviert',
      }
    );
    return orderStates;
  }

  static getOrderIcon(type: string): string {
    switch (type) {
      case 'RENTAL':
        return 'icon icon-rental';
      case 'OPERATOR':
        return 'icon icon-control';
      case 'SERVICE':
        return 'icon icon-task';
      case 'DELIVERY':
        return 'icon icon-deliver';
      default:
        return '';
    }
  }

}


export class OrderInput {
  id: string;
  externalId: string;
  type: OrderType;
  number: string;
  lineItems: any[];
  orderer: ContactPersonInput;
  customerOrderNumber: string;
  note: string;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.type = payload.type;
      this.number = payload.number;
      this.lineItems = payload.lineItems;
      this.orderer = payload.orderer;
      this.customerOrderNumber = payload.customerOrderNumber;
      this.note = payload.note;
    }
  }
}

export enum OrderState {
	NEW,
  RUNNING,
  COMPLETED,
  ARCHIVED
}

export enum OrderType {
  RENTAL
}
