<ng-container class="mx-auto pt-[22px] px-[1.25rem] h-[calc(100%-100px)]">
  <nx-pageTitle-box class="mb-3" [label]="'Einstellungen'" [icon]="'icon-settings'" [iconToolTip]="'Einstellungen'">
  </nx-pageTitle-box>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>
<ng-template #table>
  <nx-headline-box
    class="mt-2"
    label="Unternehmenseinstellungen">
  </nx-headline-box>
  <nx-default-box class="bg-white border-b border-gray flex flex-row">
    <ng-template nxTemplate="body">
      <nx-empty-box
        *ngIf="getSettingsOverviewBoxes().length === 0"
        [label]="'Keine Einstellungen vorhanden.'"></nx-empty-box>
      <div class="grid gap-4 grid-cols-3">
        <ng-container *ngFor="let box of getSettingsOverviewBoxes()">
          <app-settings-overview-box
            [icon]="box.icon"
            [label]="box.label"
            [description]="box.description"
            [path]="box.path"
          ></app-settings-overview-box>
        </ng-container>
      </div>
    </ng-template>
  </nx-default-box>
</ng-template>


<!-- <nx-headline-box
  class="mt-2"
  label="Systemeinstellungen">
</nx-headline-box>

<nx-default-box class="bg-white border-b border-gray flex flex-row">
  <ng-template nxTemplate="body">
    <div class="grid gap-4 grid-cols-3">
       <ng-container *ngFor="let box of settingsOverviewBoxes2">
        <app-settings-overview-box
          *ngIf="rolesService.hasRole(box.role)"
          [icon]="box.icon"
          [label]="box.label"
          [description]="box.description"
          [path]="box.path"
          [tenantName]="box?.tenantName"
        ></app-settings-overview-box>
      </ng-container>
    </div>
  </ng-template>

</nx-default-box> -->

