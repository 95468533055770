import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { RolesService } from '@nexato/nx-core-module';
import { Apollo, QueryRef } from 'apollo-angular';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';
import { environment } from 'src/environments/environment';
import { DriverTourDay, ResourceTourDay, TourDay, TourInput } from '../../shared/entities/tour';
import { TourPlannerService } from '../../shared/services/tour/tour-planner.service';
import { TourService } from '../../shared/services/tour/tour.service';
import * as fromGraphQl from './graphql';

@Component({
  selector: "div[tours-planner-tours-multi], app-tours-planner-tours-multi",
  templateUrl: "./tours-planner-tours-multi.component.html",
  styleUrls: ["./tours-planner-tours-multi.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToursPlannerToursMultiComponent implements OnDestroy, OnInit, AfterViewInit {

  @ViewChild('xScrollContainer') public xScrollContainer: any;
  @ViewChildren('yScrollContainer') public yScrollContainers: QueryList<ElementRef>;

  xScrollCleanup: any;
  yScrollCleanup: any;
  public tourDays: TourDay[];

  // temp for testing
  public tours: any;
  expanded = true;
  // -->

  isDraggingSubscription: Subscription;

  public statesModel: any;
  public statesOptions =
    [
      { id: 'NEW', name: 'neu' },
      { id: 'RUNNING', name: 'läuft' },
      { id: 'PAUSED', name: 'pausiert' },
      { id: 'FINISHED', name: 'beendet' },
      { id: 'ARCHIVED', name: 'archiviert' }
    ];
  public sortModel: any;
  public sortOptions = [
    { label: 'Fahrer', id: 'driver' },
    { label: 'Fahrzeug', id: 'resource' }
  ];
  public tourplanerToursByResourceQuery: QueryRef<fromGraphQl.TourPlannerToursByResourceQueryResponse>;
  public tourplanerToursByResourceQuerySubscription: Subscription;
  public tourplanerToursByDriverQuery: QueryRef<fromGraphQl.TourPlannerToursByDriverQueryResponse>;
  public tourplanerToursByDriverQuerySubscription: Subscription;
  
  // date
  _date: Date;
  @Input() set date(date: Date) {
    this._date = date;
    this.refetchQuery();
  }

  // location
  _location: LocationEntity
  @Input() set location(location: LocationEntity){
    this._location = location;
    this.refetchQuery();
  }

  @Input() isTourComponentExpanded: boolean;
  @Output() expandTourComponent = new EventEmitter();
  @Output() reduceTourComponent = new EventEmitter();

  constructor(
    public rolesService: RolesService,
    private apollo: Apollo,
    private tourPlannerService: TourPlannerService,
    private tourService: TourService,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.tourPlannerService.setToursPlannerToursMultiComponent(this);
    this.initializeStatesModel();
    this.initializeSortModel();
    this.isDraggingSubscription = this.tourPlannerService.isDragging$.subscribe((dragging) => {
      if(dragging){
        this.tourplanerToursByResourceQuery?.stopPolling();
        this.tourplanerToursByDriverQuery?.stopPolling();
      } else {
        this.tourplanerToursByResourceQuery?.startPolling(environment.pollingIntervall);
        this.tourplanerToursByDriverQuery?.startPolling(environment.pollingIntervall);
      }
    });
  }

  ngAfterViewInit(): void {
    this.xScrollCleanup = autoScrollForElements({
      element: this.xScrollContainer.nativeElement,
    });
    this.yScrollContainers.changes.subscribe((containers: QueryList<ElementRef>) => {
      if(this.yScrollCleanup) {
        this.yScrollCleanup();
      }
      const autoScrolls = containers.map((container) =>
        autoScrollForElements({
          element: container.nativeElement
        })
      );
      // Combine all auto-scrolls into one to apply them together
      this.yScrollCleanup = combine(...autoScrolls);
    });
  }

  initializeStatesModel() {
    let statesModel;
    try {
      statesModel = localStorage.getItem('ToursPlannerToursComponent.statesModel');
      if (statesModel) {
        this.statesModel = JSON.parse(statesModel);
      }
    } catch (error) {
      // nothing special to do
    }
    // no special initial state
  }

  onStateModelChange() {
    this.persistStatesModel();
    this.refetchQuery();
  }

  persistStatesModel(){
    localStorage.setItem('ToursPlannerToursMultiComponent.statesModel', JSON.stringify(this.statesModel));
  }

  initializeSortModel() {
    let sortModel;
    try {
      sortModel = localStorage.getItem('ToursPlannerToursMultiComponent.sortModel');
      if (sortModel) {
        this.sortModel = JSON.parse(sortModel);
      }
    } catch (error) {
      // nothing special to do
    }
    if(!sortModel){
      this.sortModel = this.sortOptions[0];
    }
  }

  onSortModelChange() {
    this.persistSortModel();
    this.tourplanerToursByDriverQuery = undefined;
    this.tourplanerToursByDriverQuerySubscription?.unsubscribe();
    this.tourplanerToursByResourceQuery = undefined;
    this.tourplanerToursByResourceQuerySubscription?.unsubscribe();
    this.subscribeToToursData();
  }

  persistSortModel(){
    localStorage.setItem('ToursPlannerToursMultiComponent.sortModel', JSON.stringify(this.sortModel));
  }

  createTour(day: TourDay) {
    let tour = new TourInput();
    tour.locationId = this._location?.id;
    // // correct the 'german' date format to get a valid iso string:
    // let dmy = this.datePipe.transform(this.componentstate.date.setHours(0, 0, 0, 0), "dd.MM.yyyy" ).split(".");
    let date = new Date(moment(day.getDate()).toDate().setHours(0, 0, 0, 0)).toISOString();
    tour.dateTimeReference = date;
    this.tourService.createTour(tour, () => this.refetch());
  }

  ngOnInit(): void {
    this.subscribeToToursData();
  }

  refetchQuery() {
    if(!this._location || !this._date){
      return;
    }
    if(this.tourplanerToursByResourceQuery){
      this.tourplanerToursByResourceQuery?.refetch({
        sortProperties: 'id', 
        sortDirection: "asc",
        locationId: this._location?.id,
        states: this.statesModel ? this.statesModel.map((state: any) => state.id) : undefined,
        fromDateTime: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        toDateTime: new Date(moment(this._date).add(5, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      });
    } else if(this.tourplanerToursByDriverQuery){
      this.tourplanerToursByDriverQuery?.refetch({
        sortProperties: 'id', 
        sortDirection: "asc",
        locationId: this._location?.id,
        states: this.statesModel ? this.statesModel.map((state: any) => state.id) : undefined,
        fromDateTime: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        toDateTime: new Date(moment(this._date).add(5, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      });
    } else {
      this.subscribeToToursData();
    }
  }

  subscribeToToursData() {
    if(this.sortModel.id === 'driver'){
      this.subscribeToToursByDriverData();
    } else {
      this.subscribeToToursByResourceData();
    }
  }

  subscribeToToursByResourceData() {
    if(!this._location || !this._date){
      return;
    }
    this.tourplanerToursByResourceQuery = this.apollo.watchQuery<fromGraphQl.TourPlannerToursByResourceQueryResponse>({
      pollInterval: environment.pollingIntervall,
      fetchPolicy: 'no-cache',
      query: fromGraphQl.TOUR_PLANNER_TOURS_BY_RESOURCE_QUERY,
      variables: {
        sortProperties: 'id',
        sortDirection: "asc",
        locationId: this._location?.id,
        states: this.statesModel ? this.statesModel.map((state: any) => state.id) : undefined,
        fromDateTime: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        toDateTime: new Date(moment(this._date).add(5, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      }
    });
    this.tourplanerToursByResourceQuerySubscription = this.tourplanerToursByResourceQuery.valueChanges.subscribe(
      {
        next: (data) => {
          if (data.networkStatus === 7) {
            this.tourDays = ResourceTourDay.createResourceTourDays(data.data.dailyTours);
            this.changeDetectorRef.detectChanges();
          }
        },
        error: (error) => {
          this.tourplanerToursByResourceQuery = undefined;
          this.tourplanerToursByResourceQuerySubscription?.unsubscribe();
        }
      }
    );
  }

  subscribeToToursByDriverData() {
    if(!this._location || !this._date){
      return;
    }
    this.tourplanerToursByDriverQuery = this.apollo.watchQuery<fromGraphQl.TourPlannerToursByDriverQueryResponse>({
      pollInterval: environment.pollingIntervall,
      fetchPolicy: 'no-cache',
      query: fromGraphQl.TOUR_PLANNER_TOURS_BY_DRIVER_QUERY,
      variables: {
        sortProperties: 'id',
        sortDirection: "asc",
        locationId: this._location?.id,
        states: this.statesModel ? this.statesModel.map((state: any) => state.id) : undefined,
        fromDateTime: new Date(moment(this._date).toDate().setHours(0, 0, 0, 0)).toISOString(),
        toDateTime: new Date(moment(this._date).add(5, 'days').toDate().setHours(0, 0, 0, 0)).toISOString(),
      }
    });
    this.tourplanerToursByDriverQuerySubscription = this.tourplanerToursByDriverQuery.valueChanges.subscribe(
      {
        next: (data) => {
          if (data.networkStatus === 7) {
            this.tourDays = DriverTourDay.createDriverTourDays(data.data.dailyTours);
            this.changeDetectorRef.detectChanges();
          }
        },
        error: (error) => {
          this.tourplanerToursByDriverQuery = undefined;
          this.tourplanerToursByDriverQuerySubscription?.unsubscribe();
        }
      }
    );
  }

  startPolling() {
    this.tourplanerToursByDriverQuery?.startPolling(environment.pollingIntervall);
    this.tourplanerToursByResourceQuery?.startPolling(environment.pollingIntervall);
  }

  stopPolling() {
    this.tourplanerToursByDriverQuery?.stopPolling();
    this.tourplanerToursByResourceQuery?.stopPolling();
  }

  refetch() {
    this.refetchQuery();
  }


  ngOnDestroy(): void {
    this.tourplanerToursByDriverQuerySubscription?.unsubscribe();
    this.tourplanerToursByResourceQuerySubscription?.unsubscribe
    this.isDraggingSubscription?.unsubscribe();
    if(this.xScrollCleanup) {
      this.xScrollCleanup();
    }
    if(this.yScrollCleanup) {
      this.yScrollCleanup();
    }
  }

  isReduced(day: TourDay): boolean {
    let dayFormatted = this.datePipe.transform(day?.getDate(), 'yyyy-MM-dd');
    return sessionStorage.getItem(dayFormatted) !== null;
  }

  reduce(day: TourDay): void {
    let dayFormatted = this.datePipe.transform(day?.getDate(), 'yyyy-MM-dd');
    sessionStorage.setItem(dayFormatted, 'reduced');
  }

  expand(day: TourDay): void {
    let dayFormatted = this.datePipe.transform(day?.getDate(), 'yyyy-MM-dd');
    sessionStorage.removeItem(dayFormatted);
  }
  
}




