import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ArticleDataSource } from './articleDataSource';
import { Apollo } from 'apollo-angular';
import { TableLazyLoadEvent } from 'primeng/table';
import { ExpandService } from '@nexato/nx-core-module';

interface DynmaicTableComponentState{
  displayedColumns: string[],
}

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss']
})
export class ArticlesListComponent implements  OnDestroy{
  public users: any[];
  public groups: any[];
  public dataSource: ArticleDataSource;
  @ViewChild('drawerRight') drawerRight: any;

  public text = '';
  private sortProperty = 'id';
  private sortDirection = 'asc';
  public pageNumber = 0;
  public pageSize = 15;
  public loading = false;

  public sortField = 'id';
  public sortOrder = 1;

  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
    { label: 'Nummer aufsteigend', fieldName: 'number', direction: 1 },
    { label: 'Nummer absteigend', fieldName: 'number', direction: -1 },
    { label: 'Name aufsteigend', fieldName: 'name', direction: 1 },
    { label: 'Name absteigend', fieldName: 'name', direction: -1}
  ];

  @Input() withMainHeadline = true;

  constructor(
    private apollo: Apollo,
    public expandService: ExpandService,
    private cdRef:ChangeDetectorRef,
  ) {
    this.setTableDataSource();
  }
  ngOnInit() {
    this.expandService.setExpandState(false);
  }

  setTableDataSource(): void{
    this.dataSource = new ArticleDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadarticles({
      number: 0,
      size: 15,
      sortProperty: 'name',
      sortDirection: 'asc',
    });
  }

  openArticle(customer: any): void {
    // this.router.navigate(['./customers/customer/' + customer.id]);
  }
  createArticle(): void {

  }

  refetchArticles(): void {
    this.dataSource.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
    });
  }
    loadData(event: TableLazyLoadEvent) {
      console.log(event);
      this.loading = true;
      // this.text = event?.globalFilter?.toString();

      // sorting
      if(event?.sortField !== undefined && typeof event?.sortField  == 'string') {
        this.sortProperty = event.sortField;
      }
      if(event?.sortOrder !== undefined) {
        this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
      }

      // filtering

       // pagination
    this.pageNumber = event?.first !== undefined ? event.first/event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchArticles();
  }


  ngOnDestroy(): void {
    this.dataSource.disconnect();
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  sort(event) {
    console.log('<event', event);
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }
}
