import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TaskAssignment } from 'src/app/rent-module/shared/entities/taskAssignment/taskAssignment';

@Component({
  selector: 'app-task-assignment-strategy',
  templateUrl: './task-assignment-strategy.component.html',
  styleUrls: ['./task-assignment-strategy.component.scss']
})
export class TaskAssignmentStrategyComponent {

  constructor(public datePipe: DatePipe) {
  }

  public taskAssignment: TaskAssignment;
  public toolTip: string;
  @Input() set assignment(assignment: TaskAssignment) {
    this.taskAssignment = new TaskAssignment(assignment);
    let dateTime = this.taskAssignment?.dueDateTimePeriod?.getDatePeriod(this.datePipe);
    this.toolTip = `${this.taskAssignment?.name}
      ${dateTime} Uhr`
      ;
  };
}
