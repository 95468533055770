import { Component, Input } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-pictureInput-control-value-view, [app-pictureInput-control-value-view]',
  templateUrl: './pictureInput-control-value-view.component.html',
  styleUrls: ['./pictureInput-control-value-view.component.scss']
})
export class PictureInputControlValueViewComponent {

  public archived = false;
  public fileApi = environment.fileApi;
  public settings = {
    counter: true,
    zoomFromOrigin: false,
    plugins: [lgZoom],
  };

  _controlTemplate: ControlTemplate;
  @Input() set controlTemplate(controlTemplate: ControlTemplate) {
    if(controlTemplate){
      this._controlTemplate = controlTemplate;
      if(controlTemplate?.settings?.value?.file?.ownerState === 'ARCHIVED') {
        this.archived = true;
      }
    }
  }

  constructor(
  ) {
  }

}
