import {gql} from 'apollo-angular';


// export const ASSIGN_VEHICLE_TO_TOUR_MUTATION = gql`
// mutation assignVehicleToTour($tourId: Int, $vehicleId: Int) {
//   assignVehicleToTour(tourId: $tourId, vehicleId: $vehicleId) {
//     id
//     vehicle{
//       id
//     }
//   }
// }
// `;

// export const REMOVE_VEHICLE_FROM_TOUR_MUTATION = gql`
// mutation removeVehicleFromTour($tourId: Int) {
//   removeVehicleFromTour(tourId: $tourId) {
//     id
//     vehicle{
//       id
//     }
//   }
// }
// `;


export const ADD_TASK_TO_TOUR_AT_POSITION_MUTATION = gql`
mutation addTaskToTour($tourId: UUID, $taskId: UUID,  $position: Int) {
  addTaskToTour(tourId: $tourId, taskId: $taskId, position: $position) {
    id
  }
}
`;

export const REMOVE_TASK_FROM_TOUR_MUTATION = gql`
mutation removeTaskFromTour($tourId: UUID, $taskId: UUID) {
  removeTaskFromTour(tourId: $tourId, taskId: $taskId) {
    id
  }
}
`;

// export const DELETE_TASK_MUTATION = gql`
// mutation deleteTask($taskId: ID) {
//   deleteTask(taskId: $taskId)
// }
// `;

export const CREATE_TOUR_MUTATION = gql`
mutation createTour($input: TourInput) {
  createTour(input: $input) {
    id
  }
}
`;

export const DELETE_TOUR_MUTATION = gql`
mutation deleteTour($tourId: UUID) {
  deleteTour(tourId: $tourId)
}
`;

// export const ASSIGN_DRIVER_TO_TOUR_MUTATION = gql`
// mutation assignDriverToTour($tourId: Int, $driverId: String) {
//   assignDriverToTour(tourId: $tourId, driverId: $driverId){
//     id
//     driver{
//       id
//     }
//   }
// }
// `;

// export const REMOVE_DRIVER_FROM_TOUR_MUTATION = gql`
// mutation removeDriverFromTour($tourId: Int) {
//   removeDriverFromTour(tourId: $tourId){
//     id
//   }
// }
// `;

// export const SET_START_ADDRESS_FOR_TOUR_MUTATION = gql`
// mutation setStartAddress($tourId: Int, $addressId: Int) {
//   setStartAddress(tourId: $tourId, addressId: $addressId){
//     id
//   }
// }
// `;

// export const REMOVE_START_ADDRESS_FROM_TOUR_MUTATION = gql`
// mutation removeStartAddress($tourId: Int) {
//   removeStartAddress(tourId: $tourId){
//     id
//   }
// }
// `;

// export const SET_END_ADDRESS_FOR_TOUR_MUTATION = gql`
// mutation setEndAddress($tourId: Int, $addressId: Int) {
//   setEndAddress(tourId: $tourId, addressId: $addressId){
//     id
//   }
// }
// `;

// export const REMOVE_END_ADDRESS_FROM_TOUR_MUTATION = gql`
// mutation removeEndAddress($tourId: Int) {
//   removeEndAddress(tourId: $tourId){
//     id
//   }
// }
// `;

// export const CHANGE_ORDER_OF_TASKS_IN_TOUR_MUTATION = gql`
// mutation changeOrder($tourId: Int, $taskId: Int, $newPosition: Int) {
//   changeOrder(tourId: $tourId, taskId: $taskId, newPosition: $newPosition){
//     id
//   }
// }
// `;

export const UPDATE_TOUR_MUTATION = gql`
mutation updateTour($tourInput: TourInput) {
  updateTour(input: $tourInput){
    id
  }
}
`;
