import { DateTimePeriodInput } from "../dateTimePeriod/dateTimePeriod";

export class TaskAssignmentInput {
    taskId: string;
    assigneeId: string;
    assignmentType: string;
    dueDateTimePeriod: DateTimePeriodInput;
    fromDateTime: string;
    toDateTime: string;

    constructor(payload?: any) {
      if (payload) {
        this.taskId = payload.taskId;
        this.assigneeId = payload.assigneeId;
        this.assignmentType = payload.assignmentType;
        this.dueDateTimePeriod = new DateTimePeriodInput(payload.dueDateTimePeriod);
        this.fromDateTime = payload?.fromDateTime;
        this.toDateTime = payload?.toDateTime;
      }
    }
  }

  export enum  TaskAssignmentStrategy {
	LocationTaskAssignmentStrategy, TourTaskAssignmentStrategy
}
