<nx-headline-box
  class="mt-[1.25rem]"
  label="Positionen">
</nx-headline-box>

<div class="mb-2">

  <!-- line items-->
  <div
    class="mb-2 flex flex-row"
    *ngFor="let item of orderLineItems; let i = index">
    <div class="bg-[#EAEEEF] px-5 border-b border-gray text-lg font-medium leading-4 flex flex-col pt-[1.188em] w-[62px]">
      {{i + 1}}
    </div>
    <div class="flex-grow">
      <!-- line item -->
      <nx-default-box class="bg-white border-b border-gray"
        [label]="item.text">
          {{item?.startDateTime | date:'dd.MM.YYYY HH:mm'}}&nbsp; - &nbsp; {{item?.endDateTime| date:'dd.MM.YYYY HH:mm'}}
      </nx-default-box>

      <!-- resource assignments -->
      <div
        *ngFor="let resourceAssignment of item?.resourceAssignments; let isFirst = first"
        class="assignments"
        [ngClass]="{'mt-2': !isFirst}">
        <!-- resource assignment -->
        <nx-default-box class="border-b border-l border-gray bg-[#EAEEEF]"
          icon="icon-item"
          [label]="resourceAssignment?.resource?.text">
        <ng-container chips>
          <ng-template nxTemplate="tag">
            <p-tag [value]="resourceAssignment?.free === true ? 'freigemeldet' : 'nicht freigemeldet'" severity="success"></p-tag>
          </ng-template>
        </ng-container>
        <ng-container label>
          <a [routerLink]="['/resources/resource/', resourceAssignment?.resource?.id ]" class="jump ">
            {{resourceAssignment?.resource?.text}}
            <i [className]="'icon icon-jump-to'"></i>
          </a>
        </ng-container>
          {{resourceAssignment?.startDateTime | date:'dd.MM.YYYY HH:mm'}} - {{resourceAssignment?.endDateTime | date:'dd.MM.YYYY HH:mm'}}
        </nx-default-box>

        <!-- handling -->
        <div class="flex flex-row">

          <!-- out handling -->
          <nx-default-box class="bg-white basis-1/2 border-b border-r border-gray"
            [label]="this.getHandlingType(resourceAssignment?.effectiveSchedulingInformation?.outHandlingType.toString(), 'out') + ' (Übergabe)'">
            {{resourceAssignment?.effectiveSchedulingInformation?.outDateTimePeriod?.getDatePeriod(datePipe)}}
          </nx-default-box>

          <!-- in handling -->
          <nx-default-box class="bg-white basis-1/2 border-b border-gray"
            [label]="this.getHandlingType(resourceAssignment?.effectiveSchedulingInformation?.inHandlingType.toString(), 'in') + ' (Rücknahme)'">
            {{ resourceAssignment?.effectiveSchedulingInformation?.inDateTimePeriod?.getDatePeriod(datePipe)}}
          </nx-default-box>
        </div>

        <!-- address -->
        <div class="flex flex-row">

          <!-- out handling -->
          <nx-default-box class="bg-white basis-1/2 border-b border-r border-gray"
            [label]="'Adresse'">
            <ng-template nxTemplate="tag">
              <app-address-indicator
                label
                class="mt-[0.438rem] ml-2"
                size="small"
                [grade]="resourceAssignment?.effectiveSchedulingInformation?.inAddress?.geocodingResultGrade?.toString()">
              </app-address-indicator>
            </ng-template>
            {{resourceAssignment?.effectiveSchedulingInformation?.outAddress?.getAddressString()}}
          </nx-default-box>

          <!-- in handling -->
          <nx-default-box class="bg-white basis-1/2 border-b border-gray "
            [label]="'Adresse'">
            <ng-template nxTemplate="tag">
              <app-address-indicator
                label
                class="mt-[0.438rem] ml-2"
                size="small"
                [grade]="resourceAssignment?.effectiveSchedulingInformation?.outAddress?.geocodingResultGrade?.toString()">
              </app-address-indicator>
            </ng-template>
            {{resourceAssignment?.effectiveSchedulingInformation?.inAddress?.getAddressString()}}
          </nx-default-box>
        </div>

        <!-- contacts -->
        <div class="flex flex-row">

          <!-- out handling -->
          <nx-default-box class="bg-white basis-1/2 border-b border-r border-gray"
            [label]="'Kontaktperson(en)'">
            {{getName(resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts)}}
          </nx-default-box>

          <!-- in handling -->
          <nx-default-box class="bg-white basis-1/2 border-b border-gray"
            [label]="'Kontaktperson(en)'">
            {{getName(resourceAssignment?.effectiveSchedulingInformation?.onSiteContacts)}}
          </nx-default-box>
        </div>

      </div>
    </div>
  </div>
</div>

