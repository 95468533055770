import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Optional, Output, Renderer2 } from '@angular/core';
import {
  attachClosestEdge,
  extractClosestEdge
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { TaskRowComponent } from 'src/app/shared-module/components/task-row-component/task-row.component';
import { DragPreviewService } from './drag-preview.service';
import { NxDragDrop } from './evetns';
import { NxDropList } from './nx-drop-list.directive';

@Directive({
  selector: '[nxDragItem]',
  host: {}
})
export class NxDragItem<T = any, O = T> implements OnInit, OnDestroy {
  @Input() nxIsDropDisabled: boolean = false;
  @Input() nxDragItemDropListIndex: number
  @Input() nxDragItemData: T;
  @Input() nxDragItemDropListId: string;
  @Input() currentDropId: string;
  @Input() nxDragItemIsDropTarget: boolean = false;
  @Input() nxIsDragDisabled: boolean = false;
  @Output() nxDragStarted = new EventEmitter<any>();
  @Output() nxDragging = new EventEmitter<any>();
  @Output() nxDragEnded = new EventEmitter<any>();
  @Output() nxDragDropped = new EventEmitter<NxDragDrop<T, O>>();

  private cleanup: (() => void) | null = null;
  private dropIndicator: HTMLElement | null = null;
  private closestEdge: string | null = null;
  private previewElement?: HTMLElement;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private dragPreviewService: DragPreviewService,
    private ngZone: NgZone,
    @Optional() private parent?: NxDropList,
  ) {}

  ngOnInit() {
    const handlers = [];
    handlers.push(this.makeDraggable());
    if(this.nxDragItemIsDropTarget){
      handlers.push(this.makeDropTarget());
    }

    this.cleanup = combine(...handlers);
  }

  private makeDropTarget() {
    return dropTargetForElements({
      element: this.elementRef.nativeElement,
      canDrop: () => !this.nxIsDropDisabled,
      getData: (args) => {
        return attachClosestEdge(this.getDataObject(), {
          element : this.elementRef.nativeElement,
          input: args.input,
          allowedEdges: ['top', 'bottom'],
        });
      },
      onDrag: ( args ) => {
        // Handle drag over styling
        // this.handleDragOver(container as HTMLElement);
        // if the element is the source, then we do not want to 
        // add the drop indicator
        const isSource = args.source.element === this.elementRef.nativeElement;
        if(isSource){
          return;
        }
        let closestEdge = extractClosestEdge(args.self?.data);
        if(this.closestEdge !== closestEdge){
          this.closestEdge = closestEdge;
          this.removeDropIndicator();
          this.addDropIndicator();
        } else {
          this.addDropIndicator();
        }
      },
      onDragLeave: args => {
        this.removeDropIndicator();
      },
      onDrop: (args) => {
        let closestEdge = extractClosestEdge(args.self?.data);
        this.removeDropIndicator();
        let previousIndex = args.source?.data?.index !== undefined ? args.source?.data?.index as number : undefined;
        let previousContainer = args.source?.data?.container ? args.source?.data?.container as NxDropList : undefined;
        let container = this.parent;
        // we must use different calculations for the new index:
        // (1) task gets added to the container (added to tour)
        // (2) task gets moved within the container (moved within the tour)
        let addedToContainer = previousContainer !== container;
        let newIndex = this.nxDragItemDropListIndex;
        // if the drop happpens on the bottom edge, we want to add it after the current index
        if (closestEdge === 'bottom' && addedToContainer) {
          newIndex = this.nxDragItemDropListIndex + 1;
        }
        if (closestEdge === 'top' && !addedToContainer && previousIndex + 1 == this.nxDragItemDropListIndex) {
          newIndex = this.nxDragItemDropListIndex - 1;
        }

        // detect, if the task was "not" moved, meaning: it was dropped on the same position in the same container
        // correct the index to "same" index
        if(!addedToContainer){
          if(closestEdge === 'bottom' && previousIndex - 1 == this.nxDragItemDropListIndex){
            newIndex = this.nxDragItemDropListIndex + 1;
          }
        }
        let item = args.source?.data?.data ? args.source?.data?.data as NxDragItem<T> : undefined;
        let event: NxDragDrop<T, O> = {
          previousIndex: previousIndex,
          previousContainer: previousContainer,
          newIndex: newIndex,
          newContainer: container,
          item: item,
        }
        this.nxDragEnded.emit(event);
        this.nxDragDropped.emit(event);
      }
    })
  }

  private makeDraggable() {
    return draggable({
      element: this.elementRef.nativeElement,
      canDrag: () => !this.nxIsDragDisabled,
      getInitialData: () => {
        return this.getDataObject();
      },
      onDragStart: args => {
        // add style to the element
        this.elementRef.nativeElement.classList.add('dragActive');
        this.nxDragStarted.emit(args);
        this.handleDragStart();
      },
      onDrag: args => {
        this.nxDragging.emit(args);
      },
      onDrop: args => {
        // this.handleDragEnd.bind(this);
        // remove style from the element
        this.elementRef.nativeElement.classList.remove('dragActive');
        // console.log('dropped', args);
        // console.log('dragEnd', args);
        this.nxDragEnded.emit(args);
        // this.nxDragDropped.emit(args);
        this.cleanupPreview();
      },
      
      onGenerateDragPreview: ({ nativeSetDragImage }) => {
        // Run in NgZone to ensure proper change detection
        this.ngZone.run(() => {
          this.dragPreviewService.createPreview(
            TaskRowComponent,
            { 
              task: this.nxDragItemData,
            },
            nativeSetDragImage
          );
        });
      }
   });
  }

  private createDragPreview(sourceElement: HTMLElement): HTMLElement {
    console.log(sourceElement);
    const preview = this.renderer.createElement('div');
    
    // Copy content from source element
    preview.innerHTML = sourceElement.innerHTML;
    
    // Add styling
    this.renderer.addClass(preview, 'drag-preview');
    
    // Optional: Add custom attributes or data
    this.renderer.setAttribute(preview, 'data-preview', 'true');
    
    // Append to body
    this.renderer.appendChild(document.body, preview);
    console.log(preview);
    return preview;
  }

  private updatePreviewPosition(args: any) {
    if (!this.previewElement) return;

    const { location } = args;
    
    // Optional: Add custom positioning logic
    this.renderer.setStyle(
      this.previewElement,
      'transform',
      `translate(${location.x + 20}px, ${location.y + 20}px)`
    );
  }

  private cleanupPreview() {
    if (this.previewElement?.parentElement) {
      this.renderer.removeChild(
        this.previewElement.parentElement,
        this.previewElement
      );
      this.previewElement = undefined;
    }
  }

  ngOnDestroy() {
    if (this.cleanup) {
      this.cleanup();
    }
  }

  getData(): T {
    return this.nxDragItemData;
  }

  private getDataObject(){
    return {
      data: this,
      index: this.nxDragItemDropListIndex,
      container: this.parent
    }
  }

  private addDropIndicator() {
    if (!this.dropIndicator) {
      this.dropIndicator = this.renderer.createElement('div');
      this.dropIndicator.classList.add('dropIndicator');
      this.dropIndicator.classList.add(this.closestEdge);

      const styles = {
        'background-color': '#FE5F00',
        'border': '1px solid #FE5F00',
        'position': 'absolute',
        'width': '100%',
        'pointerEvents': 'none',
        'height': '3px',
        'z-index': '10'
      };



      if(this.closestEdge === 'top'){
        styles['top'] = '-2px';
      } else {
        styles['bottom'] = '-2px';
      }

      Object.entries(styles).forEach(([property, value]) => {
        this.renderer.setStyle(
          this.dropIndicator,
          property,
          value
        );
      });

      const styleElement = this.renderer.createElement('style');
      const dropIndicatorId = `drop-indicator-${Math.random().toString(36).substr(2, 9)}`;
      this.renderer.setAttribute(this.dropIndicator, 'id', dropIndicatorId);

      const beforeStyles = `
        #${dropIndicatorId}::before {
          content: "";
          width: 11px;
          height: 11px;
          left: 8px;
          box-sizing: border-box;
          position: absolute;
          border: 3px solid #FE5F00;
          border-radius: 50%;
          top: -5px;
          background: white;
        }
      `;

      // Add the styles to the style element
      this.renderer.appendChild(styleElement, this.renderer.createText(beforeStyles));

      // Add the style element to the document head
      this.renderer.appendChild(document.head, styleElement);

      this.renderer.appendChild(this.elementRef.nativeElement, this.dropIndicator);
    }
  }

  private removeDropIndicator() {
    if (this.dropIndicator) {
      this.renderer.removeChild(this.elementRef.nativeElement, this.dropIndicator);
      this.dropIndicator = null;
    }
  }

  private handleDragStart() {
    // Create the preview element dynamically
    this.previewElement = this.renderer.createElement('div');
    this.previewElement.classList.add('custom-drag-preview');
    this.previewElement.innerText = 'Dragging Custom Preview!';
    document.body.appendChild(this.previewElement);

    // Position the preview initially
    this.positionPreviewElement(0,0);
  }

  private handleDragMove(x,y) {
    // Update the preview position as the mouse moves
    if (this.previewElement) {
      this.positionPreviewElement(x,y);
    }
  }

  private handleDragEnd() {
    // Remove the preview element after dragging is complete
    if (this.previewElement) {
      this.previewElement.remove();
      this.previewElement = null;
    }
  }

  private positionPreviewElement(x,y) {
    if (this.previewElement) {
      this.previewElement.style.left = `${x + 10}px`;
      this.previewElement.style.top = `${y + 10}px`;
    }
  }
}