<div [formGroup]="addressForm" class="form flex flex-col">
  <nx-default-box class="bg-white border-b border-gray" *ngIf="showImportedAddress"
    [label]="'Adresse (importiert)'">
   {{address?.externalAddress}}
  </nx-default-box>
  <app-address-autocomplete (addressSelected)="addressSelected($event)"></app-address-autocomplete>

<div class="white flex flex-row gap-4 px-5">
  <div class="basis-1/3 flex flex-col">
    <h2 class="">Ausgewählte Adresse:</h2>
    <ng-container *ngIf="this.addressForm?.errors && !this.addressForm?.pristine">
      <div class="error">{{showError(this.addressForm.errors)}}</div>
    </ng-container>
    <nx-textfield-input *ngIf="showNameInput"
    formControlName="name"
    placeholder="Addressname"
    label="Addressname"
    [rows]="3"/>
    <nx-textfield-input *ngIf="showNoteInput"
    formControlName="note"
    placeholder="Adresszusatz"
    label="Adresszusatz"
    [rows]="3"/>
    <div class="flex flex-row gap-5" *ngIf="!addressForm?.get('coordinatesOnly')?.value">
      <nx-edit-text-input class="basis-2/3"
      formControlName="street"
      placeholder="Straße"
      label="Straße"/>
      <nx-edit-text-input class="basis-1/3"
      formControlName="houseNumber"
      placeholder="Nummer"
      label="Nummer"/>
    </div>
    <div class="flex flex-row gap-5" *ngIf="addressForm?.get('coordinatesOnly')?.value">
      <nx-edit-text-input class="basis-2/3"
      formControlName="lat"
      placeholder="Lat"
      label="Breitengrad"/>
      <nx-edit-text-input class="basis-1/3"
      formControlName="lng"
      placeholder="Lng"
      label="Längengrad"/>
    </div>
    <div class="flex flex-row gap-5">
      <nx-edit-text-input class="basis-2/3"
      formControlName="city"
      placeholder="Stadt"
      label="Stadt"/>
      <nx-edit-text-input class="basis-1/3"
      formControlName="postalCode"
      placeholder="PLZ"
      label="Postleitzahl"/>
    </div>

    <div class="flex basis-full gap-5">
      <nx-edit-text-input
      formControlName="country"
      placeholder="Land"
      label="Land"/>
    </div>
    <mat-slide-toggle *ngIf="allowCoordinatesOnly" formControlName="coordinatesOnly">nur Koordinaten</mat-slide-toggle>

  </div>
  <div
    #mapContainer class="basis-2/3 here-map">
  </div>
</div>
