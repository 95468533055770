import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-checkboxInput-control-value-view, [app-checkboxInput-control-value-view]',
  templateUrl: './checkboxInput-control-value-view.component.html',
  styleUrls: ['./checkboxInput-control-value-view.component.scss']
})
export class CheckboxInputControlValueViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

  checked() {
    return this.controlTemplate?.settings?.value?.checked;
  }

  showText(): boolean {
    return this.controlTemplate?.settings?.value?.text;
  }
}
