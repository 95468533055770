import { Injectable } from '@angular/core';
import { ApolloService } from '@nexato/nx-core-module';
import { Observable, of, switchMap, take } from 'rxjs';
import { AddressInput } from 'src/app/shared-module/shared/entities/address/address';
import { LocationEntity, LocationInput } from '../../../../shared-module/shared/entities/location/location';
import * as fromGraphQl from './graphql';
/**
 * The models service.
 */
@Injectable()
export class LocationService {
  constructor(private apolloService: ApolloService) {}

  /**
   * updates a Location
   * @param locationInput Location Input
   * @param callback method
   */
  public updateLocation(locationInput: LocationInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_LOCATION_MUTATION,
      {
        locationInput,
      },
      callback
    );
  }

  /**
   * creates a Location
   * @param locationInput locationInput input
   * @param callback method
   */
  public createLocation(locationInput: LocationInput, callback: any): void {
    // console.log('Input', locationInput);
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_LOCATION_MUTATION,
      {
        locationInput,
      },
      callback
    );
  }

  /**
   * deletes a location
   * @param locationId licencePlate Id
   * @param callback method
   */
  public deleteLocation(locationId: string, callback: any): void {

    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_LOCATION_MUTATION,
      {
        locationId,
      },
      callback
    );
  }

  /**
   * we need to join the location and its address since they are edited in different forms:
   * - either we have an updated address and must add the address to the input
   * - or we have an updated location and must add the address to the location
   * This is, because the backend expects the address to be part of the location input.
   * 
   * @param locationInput 
   * @param location 
   */
  public completeLocationInput(locationInput: LocationInput, location: LocationEntity){
    if(location.address){
      locationInput.address = new AddressInput(location.address);
      console.log('locationInput', locationInput);
    }
    return locationInput;
  }
  public getLocations(): Observable<LocationEntity[]> {
    return this.apolloService.query<fromGraphQl.GetLocationsResponse>(fromGraphQl.GET_LOCATIONS_QUERY)
      .pipe(
        take(1),
        switchMap( (result) => {
          return of( LocationEntity.createLocations(result.data.getLocations));
        })
      );
  }

}
