import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-protocol-control-value-view, [app-protocol-control-value-view]',
  templateUrl: './protocol-control-value-view.component.html',
  styleUrls: ['./protocol-control-value-view.component.scss']
})
export class ProtocolControlValueViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

}
