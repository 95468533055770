import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from "@angular/core";
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';

@Injectable({
    providedIn: 'root'
  })
  export class DragPreviewService {
    private previewContainer: HTMLElement;
    private componentRef: ComponentRef<any> | null = null;
  
    constructor(
      private componentFactoryResolver: ComponentFactoryResolver,
      private appRef: ApplicationRef,
      private injector: Injector
    ) {
      // Create a single container for all previews
      this.previewContainer = document.createElement('div');
      this.previewContainer.style.cssText = `
        position: fixed;
        left: -9999px;
        top: -9999px;
        pointer-events: none;
        opacity: 0;
        z-index: -1;
      `;
      document.body.appendChild(this.previewContainer);
    }
  
    createPreview<T>(
        component: Type<T>,
        props: any,
        nativeSetDragImage: any
      ) {
        setCustomNativeDragPreview({
          render: ({ container }) => {
            // 1. Create component
            const componentRef = this.componentFactoryResolver
              .resolveComponentFactory(component)
              .create(this.injector);
    
            // 2. Set input properties
            Object.assign(componentRef.instance, props);
    
            // 3. Attach to the application
            this.appRef.attachView(componentRef.hostView);
    
            // 4. Get DOM element
            const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
              .rootNodes[0] as HTMLElement;
    
            // 5. Append to the container
            container.appendChild(domElem);

            // add a class to the preview element
            domElem.classList.add('dragPreview');
    
            // 6. Return cleanup function
            return () => {
            //   this.appRef.detachView(componentRef.hostView);
            //   componentRef.destroy();
            };
          },
          nativeSetDragImage,
          // Optional: provide custom offset
          getOffset: ({ container }) => ({
            x: -10,
            y: -20
          })
        });
      }
  
    destroyPreview() {
      if (this.componentRef) {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
        this.componentRef = null;
      }
      
      // Clear container
      while (this.previewContainer.firstChild) {
        this.previewContainer.removeChild(this.previewContainer.firstChild);
      }
    }
  
    ngOnDestroy() {
      this.destroyPreview();
      if (this.previewContainer?.parentNode) {
        this.previewContainer.parentNode.removeChild(this.previewContainer);
      }
    }
  }