<nx-pageTitle-box
  class="mb-3"
  [label]="'Allgemeine Einstellungen'"
  [icon]="'icon-settings'"
  [iconToolTip]="'Allgemeine Einstellungen'"
  [bcRootLvlLabel]="'Einstellungen'"
  [bcRootLvlPath]="'settings'">
    <ng-template nxTemplate="end">

      <nx-default-button
        *ngIf="!form?.enabled"
        class="default-button ml-1"
        (click)="editSettings()"
        [tooltip]="'Einstellungen Bearbeiten'"
        icon="icon-edit">
      </nx-default-button>

      <nx-default-button
        *ngIf="form?.enabled"
        class="default-button reject"
        (click)="abortEditSettings()"
        label="Abbrechen">
      </nx-default-button>

      <nx-default-button
        *ngIf="form?.enabled"
        [style]="'dark'"
        class="confirm  ml-1"
        (click)="updateSettings()"
        label="Speichern">
      </nx-default-button>

    </ng-template>
  </nx-pageTitle-box>
<app-settings-form
  [form]="form"
  [settings]="settings">
</app-settings-form>

