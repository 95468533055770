import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService): any {
  return async () => {

    keycloak.keycloakEvents$.subscribe({
      next(event) {
        // console.log(KeycloakEventType[event.type]);
        // We´re subscribing here to the case, when the refresh
        // token is expires or there´s any other problem with the
        // token. I had expected, that this case will be handled by
        // the library itself, but it seems, that it´s not the case.
        // So we´re doing it here manually. What happen is, that
        // the regular refresh of the access token fails, because
        // there´s a problem with the refresh token. This DOES NOT
        // MEAN that the session on keycloak side is expired. It jsut
        // means, that the refresh token is expired. So we´re doing 
        // a login here, that will end in an redirect to the login screen:
        // - if the keycloak session (SSO session) is expired, the user
        //   will see the login screen
        // - if the keycloak session (SSO session) is still valid, the
        //   user will be redirected back to the application
        // This (undesired) behavior should only happen in rare cases,
        // because the refresh token is valid for 30 days.
        if(event.type == KeycloakEventType.OnAuthRefreshError) {
          keycloak.login( {
            redirectUri: window.location.origin
          });
        }
      }
    });
      await keycloak.init({
        config: {
          url: environment.keycloakUrl,
          realm: environment.keycloakRealm,
          clientId: 'frontend-web',
        },
        initOptions: {
          // enableLogging: true,
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri:
            // this slient sso check will NOT check the keycloak session
            // it checks only the local session and will logout the user,
            // in other browser windows, if the user logs out in one window
            window.location.origin + '/assets/silent-check-sso.html'
        }
      });
  }
}
