import {gql} from 'apollo-angular';

export const UPDATE_CONTACTPERSON_MUTATION = gql`
mutation updateContactPerson($input: ContactPersonInput) {
  updateContactPerson(input: $input) {
    id
  }
}
`;

export const CREATE_CONTACTPERSON_MUTATION = gql`
mutation createContactPerson($input: ContactPersonInput) {
  createContactPerson(input: $input) {
    id
    name
  }
}
`;

export const DELETE_CONTACTPERSON_MUTATION = gql`
mutation deleteContactPerson($id: UUID) {
  deleteContactPerson(id: $id)
}
`;
