<ng-container *ngIf="withMainHeadline" class="mx-auto pt-[22px] px-[1.25rem] h-[calc(100%-100px)]">
  <nx-pageTitle-box class="mb-3" [label]="'Artikel'" [icon]="'icon-article'" [iconToolTip]="'Artikel'">
  </nx-pageTitle-box>
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-container *ngIf="!withMainHeadline">
  <ng-container *ngTemplateOutlet="table"></ng-container>
</ng-container>

<ng-template #table>
  <div class="bodyWrapper bg-white  h-[calc(100%-100px)]">
    <div class="flex flex-row bg-[#EAEEEF] h-12 rounded-t-md">
      <div class="basis-1/2 flex flex-row items-center ml-3 font-medium">
        Artikelliste
      </div>
      <div class="basis-1/2 flex flex-row justify-end items-center">
        <!-- <input pInputText type="text" class="p-inputtext p-component p-element mr-3" (input)="dtArticle.filterGlobal($event.target?.['value'], 'contains')" placeholder="Artikel suchen" /> -->
      </div>
    </div>
    <p-table #dtArticle (onLazyLoad)="loadData($event)" [value]="dataSource?.data?.slice()" [scrollable]="true"
      [paginator]="true" [rows]="15" [rowsPerPageOptions]="[15, 20, 50]" [totalRecords]="dataSource?.totalElements"
      [loading]="loading" scrollHeight="flex" [sortField]="sortField" [sortOrder]="sortOrder"
      [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} von {totalRecords}"
      [resizableColumns]="true">
      <ng-template pTemplate="header">
        <tr>
          <th colspan="5">
            <div class="flex flex-row">
              <div class="basis-2/3 flex flex-row gap-2">
              </div>
              <div class="basis-1/3 flex flex-row justify-end">
                <p-dropdown [styleClass]="'sort'" appendTo="body" [options]="sortOptions" optionLabel="label"
                  [showClear]="false" (onChange)="sort($event)"></p-dropdown>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th>Nummer</th>
          <th>Name</th>
        </tr>
        <!-- <tr>
            <th><p-columnFilter type="text" [showMenu]="false" placeholder="Suche..." field="number"></p-columnFilter></th>
            <th><p-columnFilter type="text" [showMenu]="false" placeholder="Suche..." field="name"></p-columnFilter></th>
          </tr> -->
      </ng-template>
      <ng-template pTemplate="body" let-article>
        <tr>
          <td>{{article?.number}}</td>
          <td>{{article.name}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="8" class="emptyMessage">Keine Artikel vorhanden.</td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
    </ng-template>
  </div>
