import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModuleModule } from '@nexato/nx-core-module';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TagModule } from 'primeng/tag';
import { AppRoutingModule } from '../app-routing.module';
import { FrontendMaterialModule } from '../material-module/material.module';
import { SharedModule } from '../shared-module/shared.module';
import { OffsetSelectorComponent } from './components/offset-selector/offset-selector.component';
import { TaskInfoDialogComponent } from './components/task-info-dialog/task-info-dialog.component';
import { TourSettingsDialogComponent } from './components/tour-settings-dialog/tour-settings-dialog.component';
import { TourStateSelectorComponent } from './components/tour-state-selector/tour-state-selector.component';
import { ToursDetailPlanningMapComponent } from './components/tours-detail-planning-map/tours-detail-planning-map.component';
import { ToursPlannerTasksListComponent } from './components/tours-planner-tasks-list/tours-planner-tasks-list.component';
import { ToursPlannerToursListComponent } from './components/tours-planner-tours-list/tours-planner-tours-list.component';
import { ToursDetailPageComponent } from './containers/tours-detail-page/tours-detail-page.component';
import { ToursPlannerPageComponent } from './containers/tours-planner-page/tours-planner-page.component';
import { AddressService } from './shared/services/address/address.service';
import { TaskService } from './shared/services/task/task.service';
import { TourService } from './shared/services/tour/tour.service';

// Import locale data for 'de'
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { RentModule } from '../rent-module/rent.module';
import { ToursPlannerToursMultiComponent } from './components/tours-planner-tours-multi/tours-planner-tours-multi.component';
import { ToursPlannerToursComponent } from './components/tours-planner-tours/tours-planner-tours.component';
import { DragDropMonitorService } from './shared/dragDrop/drag-drop-monitor.service';
import { DragPreviewService } from './shared/dragDrop/drag-preview.service';
import { NxDragDropPanel } from './shared/dragDrop/nx-drag-drop-panel.directive';
import { NxDragItem } from './shared/dragDrop/nx-drag-item.directive';
import { NxDropList } from './shared/dragDrop/nx-drop-list.directive';
import { TourPlannerService } from './shared/services/tour/tour-planner.service';

// Register the locale data
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    ToursPlannerPageComponent,
    TourStateSelectorComponent,
    OffsetSelectorComponent,
    ToursPlannerTasksListComponent,
    ToursPlannerToursListComponent,
    TourSettingsDialogComponent,
    TaskInfoDialogComponent,
    ToursDetailPageComponent,
    ToursDetailPlanningMapComponent,
    ToursPlannerToursComponent,
    ToursPlannerToursMultiComponent,
    NxDropList,
    NxDragItem,
    NxDragDropPanel
  ],
  imports: [
    CommonModule,
    FrontendMaterialModule,
    AppRoutingModule,
    CoreModuleModule,
    SharedModule,
    ReactiveFormsModule,
    DragDropModule,
    DropdownModule,
    TableModule,
    FormsModule,
    OverlayPanelModule,
    OrderListModule,
    CheckboxModule,
    TagModule,
    CalendarModule,
    PanelModule,
    SplitterModule,
    RentModule,
    PaginatorModule
  ],
  providers: [
    TourService,
    TaskService,
    AddressService,
    { provide: LOCALE_ID, useValue: 'de' },
    TourPlannerService,
    DragDropMonitorService,
    DragPreviewService
  ]
})
export class TourPlanningModule { }
