import { Address, AddressInput } from "../../../../shared-module/shared/entities/address/address";
import { ContactPerson, ContactPersonInput } from "../contactPerson/contactPerson";

export class Contact {
  id: string | undefined;
  externalId: string | undefined;
  number: string | undefined;
  type: ContactType;
  firstName: string | undefined;
  lastName: string | undefined;
  companyName: string | undefined;
  companyNameExtension: string | undefined;
  phoneNumber: string | undefined;
  address: Address | undefined;
  email: string | undefined;
  isDeletable: boolean;
  contactPersons: ContactPerson[] | undefined;
  customAttributes: any;
  name: string;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.number = payload.number;
      this.type = payload.type;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.companyName = payload.companyName;
      this.companyNameExtension = payload.companyNameExtension;
      this.phoneNumber = payload.phoneNumber;
      this.isDeletable = payload.isDeletable;
      this.email = payload.email;
      this.contactPersons = payload.contactPersons;
      this.customAttributes = payload?.customAttributes;
      this.name = payload?.name;
    }
  }
  static getContactTypes(): { id: string; name: string, label: string }[] {
    const contactTypes: { id: string; name: string, label: string }[] = [];
    contactTypes.push(
      {
        id: 'COMPANY',
        name: 'Firma',
        label: 'Firma',

      },
      {
        id: 'INDIVIDUAL',
        name: 'Privatperson',
        label: 'Privatperson',
      }
    );
    return contactTypes;
  }
    /**
   * creates contacts from a result
   * @param result Array of any
   * @returns contacts
   */
     static createContacts(result: Array<any>): Contact[] {
      const contacts = new Array<Contact>();
      if (result) {
        result.map((contact) => {
          contacts.push(new Contact(contact));
        });
      }
      return contacts;
    }
    public getShortName(): string {
      if(this.type?.toString() == 'INDIVIDUAL'){
          return this.lastName? this.lastName: '';
      }
      if(this.type?.toString() == 'COMPANY'){
        return this.companyName? this.companyName: '';
      }
      return undefined;
    }

    public getFullName(): string {
      if (this.type?.toString() == 'INDIVIDUAL') {
        if (this.firstName && this.lastName) {
          return this.lastName + ', ' + this.firstName;
        } else if (this.firstName) {
          return this.firstName;
        } else if (this.lastName) {
          return this.lastName;
        } else {
          return undefined;
        }
      }
      if(this.type?.toString() == 'COMPANY'){
        // if (this.companyNameExtension && this.companyName){
        //   return this.companyName + this.companyNameExtension;
        // }
        return this.companyName? this.companyName: undefined;
      }
      return undefined;
    }

    static getContactIcon(type: string): string {
      switch (type) {
        case 'COMPANY':
          return 'icon icon-company';
        case 'INDIVIDUAL':
          return 'icon icon-person';
        default:
          return '';
      }
    }

    static getContactType(type: string): string {
      switch (type) {
        case 'COMPANY':
          return 'Firma';
        case 'INDIVIDUAL':
          return 'Privatperson';
        default:
          return '';
      }
    }

}

export class ContactInput {
  id: string | undefined;
  externalId: string | undefined;
  number: string | undefined;
  companyName: string | undefined;
  companyNameExtension: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  address: AddressInput | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
  type: ContactType;
  contactPersons: ContactPersonInput[] | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.number = payload.number;
      this.type = payload.type;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.companyName = payload.companyName;
      this.companyNameExtension = payload.companyNameExtension;
      this.phoneNumber = payload.phoneNumber;
      this.address = payload.address;
      this.contactPersons = payload.contactPersons;
      this.email = payload.email;
    }
  }
}

export enum ContactType {
	INDIVIDUAL, COMPANY, EMPLOYEE
}


