<nx-pageTitle-box class="mb-3" [label]="'Niederlassungen'" [icon]="'icon-branches'" [iconToolTip]="'Niederlassungen'"
  [bcRootLvlLabel]="'Einstellungen'"
  [bcRootLvlPath]="'settings'">
</nx-pageTitle-box>

<nx-table-header
  label="Niederlassungsliste">
  <ng-template nxTemplate="end">
    <nx-default-button
      *ngIf="rolesService.hasRole('nexcore_location_create')"
      [style]="'light'"
      (click)="createLocation()"
      [label]="'Niederlassung erstellen'"
      [icon]="'icon-plus'">
    </nx-default-button>
  </ng-template>
</nx-table-header>
  
<p-table
  [lazy]="true"
  (onLazyLoad)="loadData($event)"
  [value]="dataSource?.data"
  [scrollable]="true"
  [paginator]="true"
  [rows]="15"
  [rowsPerPageOptions]="[15, 20, 50]"
  [totalRecords]="dataSource?.totalElements"
  [loading]="loading"
  [sortField]="sortProperty" 
  [sortOrder]="translateSortDirectionToSortOrder(sortDirection)"
  scrollHeight="flex"
  [showCurrentPageReport]="true"
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [resizableColumns]="true">
  <ng-template pTemplate="caption">
    <div class="flex flex-row">
      <div class="basis-2/3 flex flex-row gap-2">
        <!-- no filter -->
      </div>
      <div class="basis-1/3 flex flex-row justify-end">
        <p-dropdown
          placeholder="Sortierung"
          [styleClass]="'sort'"
          appendTo="body"
          [ngModel] = "sortModel"
          [options]="sortOptions"
          optionLabel="label"
          [showClear]="false"
          (onChange)="sort($event)">
        </p-dropdown>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
    </tr>
    </ng-template>
    <ng-template pTemplate="body" let-location>
        <tr class="clickable" (click)="editLocation(location)">
            <td>{{location.name}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="1" class="emptyMessage">Keine Niederlassungen gefunden.</td>
      </tr>
    </ng-template>
  </p-table>

