import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import moment from 'moment';
import { TooltipOptions } from 'primeng/api';
import { TableLazyLoadEvent } from 'primeng/table';
import { Tour } from 'src/app/tour-planning/shared/entities/tour';
import { TourDataSource } from './tourDataSource';

@Component({
  selector: 'app-dashboard-tour-list',
  templateUrl: './dashboard-tour-list.component.html',
  styleUrls: ['./dashboard-tour-list.component.scss']
})
export class DashboardTourListComponent implements OnDestroy, OnChanges{
  public states: string[] = [];
  public type: string;
  public dataSource: TourDataSource;
  public location: string;
  public date = new Date();
  public initialDate = new Date();
  private sortProperty = 'name';
  private sortDirection = 'asc';
  pageNumber = 0;
  pageSize = 10;
  public loading = false;

  public sortField = 'name';
  public sortOrder = 1;

  tooltipOptions: TooltipOptions = {
    hideDelay: 150,
    showDelay: 300,
    tooltipEvent: 'hover',
    tooltipPosition: 'bottom'
  };

  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
    { label: 'Tour aufsteigend', fieldName: 'name', direction: 1 },
    { label: 'Tour absteigend', fieldName: 'name', direction: -1 },
    { label: 'Fahrzeug aufsteigend', fieldName: 'resource.name', direction: 1 },
    { label: 'Fahrzeug absteigend', fieldName: 'resource.name', direction: -1}
  ];

  @Input() set selectedLocation(location: string){
    this.location = location;
  }
  @Input() set filter(filter: any) {
    // console.log('type', this.type);
    this.type = filter?.type;
    this.location = filter?.location;
    if (this.type === 'planned') {
      this.states = ['NEW'];
    } else if (this.type === 'running') {
      this.states = ['RUNNING'];
    } else if (this.type === 'finished'){
      this.states = ['FINISHED'];
    }
    this.setTableDataSource();
  }

  @Input() set setDate(date: any) {
    this.date = date;
  }

  @Input() overall: number = 100;
  @Input() progress: number = 20;
  progressPercentage: number = 45;

  constructor(
    private router: Router,
    private apollo: Apollo,
    private cdRef:ChangeDetectorRef,
    // public dialog: MatDialog,
    private loadingService: LoadingService,
    //public rolesService: RolesService
  ) {
   // this.setTableDataSource();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLocation) {
      this.location = changes.selectedLocation.currentValue
      this.refetchTours();
    }
    if (changes.setDate) {
      this.date = changes.setDate.currentValue;
      this.refetchTours();
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }



  setTableDataSource(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource = new TourDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.dataSource.loadTours({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      states: this.states,
      locationIds: this.location,
      fromDate,
      toDate
    });
  }

  refetchTours(): void {
    let fromDate = new Date(moment(this.date).toDate().setHours(0, 0, 0, 0)).toISOString();
    let toDate = new Date(moment(this.date).add(1, 'days').toDate().setHours(0, 0, 0, 0)).toISOString();
    this.dataSource.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      states: this.states,
      locationIds: this.location,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  isEllipsisActive(e: { offsetWidth: number; scrollWidth: number; }) {
    return !(e.offsetWidth < e.scrollWidth);
  }

  getIcon(type: string): string {
    switch (type) {
      case 'planned':
        return 'icon-tour-scheduled';
      case 'running':
        return 'icon-tour-started';
      case 'finished':
        return 'icon-tour-finished';
    }
  }

  getHeadline(type: string): string {
    switch (type) {
      case 'planned':
        return 'Geplante Touren';
      case 'running':
        return 'Aktive Touren';
      case 'finished':
        return 'Abgeschlossene Touren';
    }
  }

  loadData(event: TableLazyLoadEvent) {
    console.log(event);
    this.loading = true;
   // this.text = event?.globalFilter?.toString();

    // sorting
    if(event?.sortField !== undefined && typeof event?.sortField  == 'string') {
      this.sortProperty = event.sortField;
    }
    if(event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }


    // pagination
    this.pageNumber = event?.first !== undefined ? event.first/event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 15;

    this.refetchTours();
  }

  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }

  getDonePercentage(tour: any): number {
    // calculate the percentage tasks in state COMPLETED
    let completed = tour?.tasks?.filter((task: any) => task?.state === 'COMPLETED')?.length;
    // get the total number of tasks
    let total = tour?.tasks?.length;
    return Math.round((completed / total) * 100);
  }

  getProgressToolTip(tour: Tour){
    if(!tour || !tour?.tasks){
      return undefined;
    }
    let completed = tour?.tasks?.filter((task: any) => task?.state === 'COMPLETED')?.length;
    let total = tour?.tasks?.length;
    return `${completed} von ${total} erledigt`;
  }

  onPageChange(event) {
    this.pageNumber = event.page;
    this.pageSize = event.rows;
    this.refetchTours();
  }

  getDriverName(tour: Tour): string {
    if(tour && tour?.driver){
    return tour?.driver?.firstName + ' ' + tour?.driver?.lastName;
    }
    return '-';
  }
}
