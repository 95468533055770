<nx-headline-box 
    icon="icon-item" 
    [label]="_resourceAssignment?.text" 
    class="mt-[1.25rem] border-b border-[#E0E0E0]">
</nx-headline-box>

<nx-empty-box
    *ngIf="!anyProtocolAvailable()"
    [label]="'Keine Protokolle vorhanden.'">
</nx-empty-box>

<ng-container *ngIf="anyProtocolAvailable()">

    <!-- out and in -->
    <p-accordion [activeIndex]="0" *ngIf="inOrOutProtocolAvailable()">
        <p-accordionTab>

            <!-- icon -->
            <ng-template pTemplate="icon" let-selected="$implicit">
                <div class="pl-4 text-[2rem] h-[3rem] bg-[#EBEEEF]">
                    <ng-container *ngIf="selected">
                        <i [className]="'icon-open-down'"></i>
                    </ng-container>
                    <ng-container *ngIf="!selected">
                        <i [className]="'icon-next'"></i>
                    </ng-container>
                </div>
            </ng-template>

            <!-- header -->
            <ng-template pTemplate="header">
                <ng-container>
                    <div class="flex flex-row flex-grow h-[3remrem] bg-[#EBEEEF]">
                        <div class="flex flex-row basis-1/2 border-r border-[#E0E0E0] px-5 py-3">
                            <div>
                                {{resourceOut?.formControlTemplate?.settings?.label ?
                                resourceOut?.formControlTemplate?.settings?.label : '-'}}
                            </div>
                            <div class="mt-[-0.125rem] ml-2">
                                <p-tag 
                                    *ngIf="resourceOut?.state" 
                                    [value]="getProtocolStateLabel(resourceOut)"
                                    [severity]="getProtocolStateSeverity(resourceOut)">
                                </p-tag>
                            </div>
                        </div>
                        <div class="flex flex-row basis-1/2 px-5 py-3">
                            <div>
                                {{resourceIn?.formControlTemplate?.settings?.label ?
                                resourceIn?.formControlTemplate?.settings?.label : '-'}}
                            </div>
                            <div class="mt-[-0.125rem] ml-2">
                                <p-tag
                                    *ngIf="resourceIn?.state" 
                                    [value]="getProtocolStateLabel(resourceIn)"
                                    [severity]="getProtocolStateSeverity(resourceIn)">
                                </p-tag>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>

            <!-- body -->
            <div class="pl-12 bg-white border-[#E0E0E0] border-b">
                <app-protocol-side-by-side-view
                    [leftProtocol]="resourceOut"
                    [rightProtocol]="resourceIn">
                </app-protocol-side-by-side-view>
            </div>
        </p-accordionTab>
    </p-accordion>

    <!-- refuel -->
    <p-accordion [activeIndex]="0" *ngIf="refuelProtocolAvailable()">
        <p-accordionTab>
            <!-- icon -->
            <ng-template pTemplate="icon" let-selected="$implicit">
                <div class="pl-4 text-[2rem] h-[3rem] bg-[#EBEEEF]">
                    <ng-container *ngIf="selected">
                        <i [className]="'icon-open-down'"></i>
                    </ng-container>
                    <ng-container *ngIf="!selected">
                        <i [className]="'icon-next'"></i>
                    </ng-container>
                </div>
            </ng-template>

            <!-- header -->
            <ng-template pTemplate="header">
                <ng-container>
                    <div class="flex flex-row flex-grow h-[3remrem] bg-[#EBEEEF]">
                        <div class="flex flex-row px-5 py-3">
                            <div>
                                {{refuel?.formControlTemplate?.settings?.label ?
                                refuel?.formControlTemplate?.settings?.label : '-'}}
                            </div>
                            <div class="mt-[-0.125rem] ml-2">
                                <p-tag 
                                    *ngIf="refuel?.state" 
                                    [value]="getProtocolStateLabel(refuel)"
                                    [severity]="getProtocolStateSeverity(refuel)">
                                </p-tag>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-template>

            <!-- body -->
            <div class="pl-12 bg-white border-[#E0E0E0] border-b">
                <app-protocol-view
                    *ngIf="refuel"
                    [protocol]="refuel">
                </app-protocol-view>
            </div>
        </p-accordionTab>
    </p-accordion> 

</ng-container>
<!-- 
        <div>
            <div class="font-medium py-1 pl-6 border-solid border-b border-gray-300">Nachtanken</div>
            <div class="border-solid border-b border-gray-300">PROTOKOLL</div>
        </div> -->
<!-- <ng-container *ngIf="outCheck">
            outCheck in single view
        </ng-container>" -->
<!-- <div *ngIf="resourceOut || resourceIn"
            class="protocol resourceInOut sideBySideView basis-2/3 border-gray-300 border-x border-solid"
            app-protocol-side-by-side-view
            [leftProtocol]="resourceOut"
            [rightProtocol]="resourceIn">
        </div> -->

<!-- <div class="protocol refuel singleView basis-1/3 border-gray-300 border-x border-solid"
            *ngIf="refuel"
            app-protocol-view
            [protocol]="refuel">
        </div> -->
<!-- <ng-container *ngIf="inCheck">
            inCheck in single view
        </ng-container>" -->
<!-- <div class="py-2" *ngIf="!refuel && !(resourceOut || resourceIn)">
            Keine Protokolle vorhanden.
        </div> -->
<!-- </div> -->
<!-- </div> -->