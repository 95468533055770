import { Page } from '@nexato/nx-core-module';
import { gql } from 'apollo-angular';

export const TOUR_PLANNER_TASK_QUERY = gql`
query GetTasksForPlanningQuery(
  $sortProperty: String,
  $sortDirection: String,
  $locationIds: [UUID],
  $text: String, $preview: Int
  $fromDateTime: String,
  $toDateTime: String,
  $taskAssignmentInput: TaskAssignmentInput,
  $pageNumber: Int, $pageSize: Int,
  $types: [String]
)
{
  tasksForPlanning: getTasksPaged(
    taskFilter: {
      locationIds: $locationIds,
      states : [NEW, UNASSIGNED],
      text: $text,
      fromDateTime: $fromDateTime,
      toDateTime: $toDateTime,
      handlingType: DELIVERY,
      preview: $preview,
      types: $types,
      sortRequest: { 
        sortProperties: [$sortProperty],
        sortDirection: $sortDirection
      },
      pagination: {
        number: $pageNumber,
        size: $pageSize,
        sortProperty: $sortProperty,
        sortDirection: $sortDirection
      },
    }
  ){
    totalPages
    totalElements
    number
    size
    content{
      ... taskExcerpt
    }
  }
}

fragment taskExcerpt on Task{
  id
  type
  dueDateTimePeriod {
    modifier
    dateTime
    fromDateTime
    toDateTime
  }
  state
  description
  color
  order{
    id
    number
    type
    note
    customer {
      type
      companyName
      companyNameExtension
      firstName
      lastName
    }
    location {
      id
      name
    }
    comments {
      text
    }
    fileCount
    commentCount
  }
  address {
    id
    country
    city
    postalCode
    street
    houseNumber
    name
    lat
    lng
    text
    type
    note
    geocodingResultGrade
    ref
  }
  isAssignable(taskAssignmentInput: $taskAssignmentInput)
}
`;


export interface TourPlannerTasksQueryResponse {
  tasksForPlanning:Page<Task>;
}


