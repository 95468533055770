
declare var ENV: string;
declare var BACKEND_URI: string;
declare var KEYCLOAK_REALM: string;
declare var APM_SERVER_URL: string;
declare var APM_ACTIVE: boolean;
declare var require: any
declare var FILE_API: string;
declare var KEYCLOAK_URL: string;
declare var API_URI: string;
declare var DOCUMENTATION_URI: string;
declare var GENERAL_HERE_API_KEY: string;
declare var STRIPE_ENABLED: string;
declare var HELP_CENTER: string;



export const environment = {
  production: true,
  version: require('../../package.json').version,
  name: require('../../package.json').name,
  env: ENV,
  keycloakRealm: KEYCLOAK_REALM,
  backendUri: BACKEND_URI,
  pollingIntervall: 10000,
  apmServerUrl: APM_SERVER_URL,
  apmActive: APM_ACTIVE,
  fileApi: FILE_API,
  keycloakUrl: KEYCLOAK_URL,
  toolTipDelay: 500,
  apiUri: API_URI,
  documentationUri: DOCUMENTATION_URI,
  generalHereApiKey: GENERAL_HERE_API_KEY,
  stripeEnabled: STRIPE_ENABLED,
  helpCenter: HELP_CENTER
};

