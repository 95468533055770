import { Address, AddressInput } from "../../../../shared-module/shared/entities/address/address";
import { ContactPerson, ContactPersonInput } from "../contactPerson/contactPerson";
import { DateTimePeriod, DateTimePeriodInput } from "../dateTimePeriod/dateTimePeriod";
import { LocationEntity } from "../../../../shared-module/shared/entities/location/location";

export class  SchedulingInformation{
  id: string | undefined;
  handlingType: HandlingType | undefined;
  inHandlingType: HandlingType | undefined;
  outHandlingType: HandlingType | undefined;
  dateTimePeriod: DateTimePeriod | undefined;
  outDateTimePeriod: DateTimePeriod | undefined;
  inDateTimePeriod: DateTimePeriod | undefined;
  outAddress: Address | undefined;
  inAddress: Address | undefined;
  outLocation: LocationEntity | undefined;
  inLocation: LocationEntity | undefined;
  address: Address | undefined;
  onSiteContacts: ContactPerson[] | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.handlingType = payload.handlingType;
      this.inHandlingType = payload.inHandlingType;
      this.outHandlingType = payload.outHandlingType;
      if (payload.dateTimePeriod) {
        this.dateTimePeriod = new DateTimePeriod(payload.dateTimePeriod);
      }
      if (payload.outDateTimePeriod) {
        this.outDateTimePeriod = new DateTimePeriod(payload.outDateTimePeriod);
      }
      if (payload.inDateTimePeriod) {
        this.inDateTimePeriod = new DateTimePeriod(payload.inDateTimePeriod);
      }
      if (payload?.outAddress) {
        this.outAddress = new Address(payload.outAddress);
     }
      if (payload?.inAddress) {
         this.inAddress = new Address(payload.inAddress);
      }
      if (payload?.onSiteContacts) {
        this.onSiteContacts = ContactPerson.createContactPersons(payload.onSiteContacts);
      }
      this.inLocation = payload.inLocation;
      this.outLocation = payload.outLocation;
    }
  }
}

export class SchedulingInformationInput {
  id: string | undefined;
  handlingType: HandlingType | undefined;
  inHandlingType: HandlingType | undefined;
  dateTimePeriod: DateTimePeriodInput | undefined;
  outDateTimePeriod: DateTimePeriodInput | undefined;
  inDateTimePeriod: DateTimePeriodInput | undefined;
  outAddress: AddressInput | undefined;
  inAddress: AddressInput | undefined;
  address: AddressInput | undefined;
  onSiteContacts: ContactPersonInput[] | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.handlingType = payload.handlingType;
      this.inHandlingType = payload.inHandlingType;
      this.dateTimePeriod = payload.dateTimePeriod;
      this.outDateTimePeriod = payload.outDateTimePeriod;
      this.inDateTimePeriod = payload.inDateTimePeriod;
      this.outAddress = payload.outAddress;
      this.inAddress = payload.inAddress;
      this.address = payload.address;
      this.onSiteContacts = payload.onSiteContacts;
    }
  }
}

export enum HandlingType {
	DELIVERY, PICKUP
}


