
export class AutoCompleteResult {
  autoCompleteResultSource: AutoCompleteResultSource | undefined;
  label: string;
  payload: any;

  constructor(autoCompleteResultSource: AutoCompleteResultSource, label: string, payload: any) {
    this.autoCompleteResultSource = autoCompleteResultSource;
    this.label = label;
    this.payload = payload;
  }

}

export class AutoCompleteResultGroup {

  label: string;
  results: AutoCompleteResult[];

  constructor(label: string, results: AutoCompleteResult[]) {
    this.label = label;
    this.results = results;
  }

}

export enum AutoCompleteResultSource {
  HERE, GOOGLE
}
