    <ng-container *ngIf="_controlTemplate?.settings?.value?.file?.fullPath && fileApi; else noValue">
        <lightgallery 
            *ngIf="!archived"
            [settings]="settings" 
            class="flex justify-start w-full">
            <a
                [attr.class]="'mr-2'"
                data-lg-size="480-475-480, 800-791-800, 1406-1390" class="gallery-item"
                [attr.data-src]="fileApi + '/view/' + _controlTemplate?.settings?.value?.file?.fullPath + '?width=1406'"
                >
                <img class="img-responsive"
                    src="{{fileApi + '/view/' + _controlTemplate?.settings?.value?.file?.fullPath}}?width=69&height=48"/>
            </a>
        </lightgallery>
        <div class="py-2 border-b border-solid border-gray-300 last:border-b-0 italic font-light" *ngIf="archived">
            Archiviert
        </div>
    </ng-container>
    <ng-template #noValue>
        -
    </ng-template>    