<ng-container [ngSwitch]="taskAssignment.typeLabel" *ngIf="taskAssignment">
  <!-- Tour -->
  <span *ngSwitchCase="'Tour'" class="flex items-center">
    <a [routerLink]="['/tour-planner/detail/', taskAssignment?.assigneeId]" class="jump">{{taskAssignment?.name}}
      <!-- <i [className]="'icon-jump-to'"></i>--></a> 
    <!-- <i [className]="'icon icon-info'" *ngIf="taskAssignment" [matTooltip]="toolTip"></i> -->
  </span>

  <span *ngSwitchCase="'Niederlassung'" class="flex items-center">
    {{taskAssignment?.name}}
    <!-- <i [className]="'icon icon-info'" *ngIf="taskAssignment" [matTooltip]="toolTip"></i> -->
  </span>
</ng-container>
