<nx-pageTitle-box
  class="mb-3"
  [label]="'Disposition'"
  [icon]="'icon-taskplanner'"
  [iconToolTip]="'Disposition'">
  <ng-template nxTemplate="end">
    <app-date-selector
      [stateKey]="'taskAssignment'"
      [storageType]="'session'"
      class="date-selector"
      className="white"
      [displayLabel]=false
      (date)="dateChanged($event)">
    </app-date-selector>
    <app-location-selector
      class="ml-2"
      [stateKey]="'taskAssignment'"
      [storageType]="'local'"
      (locations)="locationsChanged($event)">
    </app-location-selector>
  </ng-template>
</nx-pageTitle-box>
<p-tabView
  (onChange)="onTabChange($event)"
  [activeIndex]="activeIndex"
  [scrollable]="true">

  <p-tabPanel header="Offen">
    <!-- <ng-template pTemplate="content"> -->
      <app-task-assignment-list-unassigned
        [date]="selectedDate"
        [locations]="selectedLocations">
      </app-task-assignment-list-unassigned>
    <!-- </ng-template> -->
  </p-tabPanel>

  <p-tabPanel header="Zugewiesen">
    <!-- <ng-template pTemplate="content"> -->
      <app-task-assignment-list-assigned
        [date]="selectedDate"
        [locations]="selectedLocations">
      </app-task-assignment-list-assigned>
    <!-- </ng-template> -->
  </p-tabPanel>

  <p-tabPanel header="Abgeschlossen">
    <!-- <ng-template pTemplate="content"> -->
      <app-task-assignment-list-completed
        [date]="selectedDate"
        [locations]="selectedLocations">
      </app-task-assignment-list-completed>
    <!-- </ng-template> -->
  </p-tabPanel>

</p-tabView>
