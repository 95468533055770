import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-fuelInput-control-value-view, [app-fuelInput-control-value-view]',
  templateUrl: './fuelInput-control-value-view.component.html',
  styleUrls: ['./fuelInput-control-value-view.component.scss']
})
export class FuelInputControlValueViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

  showLevelVisible(): boolean {
    return this.controlTemplate?.settings?.showLevelVisibleInput;
  }

  getLevelVisibleValue(): any {
    return this.controlTemplate?.settings?.value?.levelVisible;
  }

  showLevelInput(): boolean {
    return this.controlTemplate?.settings?.showLevelInput;
  }


  getLevelAsPercentage(): any {
    if(this.controlTemplate?.settings?.value?.level !== undefined && !isNaN(this.controlTemplate?.settings?.value?.level)) {
      return this.controlTemplate?.settings?.value?.level * 100 + " %"
    }
    return "-";
  }

  showRefueledInput(): boolean {
    return this.controlTemplate?.settings?.showRefueledInput;
  }

  showPictureInput(): boolean {
    return this.controlTemplate?.settings?.showPictureInput && this.controlTemplate?.settings?.value?.picture;
  }

  refuelTaskShowRefueled(): boolean {
    return this.controlTemplate?.settings?.refuelTaskShowRefueled !== undefined && this.controlTemplate?.settings?.refuelTaskShowRefueled === true;
  }

  showRefueledSum(): boolean {
    return this.controlTemplate?.settings?.showRefueledSum !== undefined && this.controlTemplate?.settings?.showRefueledSum === true;
  }
}
