import { Contact } from "../contact/contact";

export class ContactPerson {
  id: string | undefined;
  externalId: string | undefined;
  name: string | undefined;
  salutation: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  phoneNumber: string | undefined;
  mobileNumber: string | undefined;
  contact: Contact;
  isDeletable: boolean;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.salutation = payload.salutation;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.email = payload.email;
      this.phoneNumber = payload.phoneNumber;
      this.mobileNumber = payload.mobileNumber;
      this.contact = payload.contact;
      this.isDeletable = payload.isDeletable;
    }
  }
    /**
   * creates contacts from a result
   * @param result Array of any
   * @returns contacts
   */
     static createContactPersons(result: Array<any>): ContactPerson[] {
      const contacts = new Array<ContactPerson>();
      if (result) {
        result.map((contact) => {
          contacts.push(new ContactPerson(contact));
        });
      }
      return contacts;
    }
  public getFullName(): string {
    if (this.firstName && this.lastName) {
      return this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      return this.firstName;
    } else if (this.lastName) {
      return this.lastName;
    }else if (this.name) {
      return this.name;
    }
    else {
      return undefined;
    }
  }
}

export class ContactPersonInput {
  id: string | undefined;
  externalId: string | undefined;
  salutation: string | undefined;
  name: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  mobileNumber: string | undefined;
  contact: string | undefined;
  email: string | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.salutation = payload.salutation;
      this.firstName = payload.firstName;
      this.lastName = payload.lastName;
      this.phoneNumber = payload.phoneNumber;
      this.mobileNumber = payload.mobileNumber;
      this.contact = payload.contact;
      this.email = payload.email;
    }
  }
}


