
export class Article {
  id: string | undefined;
  externalId: string | undefined;
  name: string;
	number: string;
	isDeletable: Boolean


  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.number = payload.number;
      this.isDeletable = payload.isDeletable;
    }
  }

  /**
   * creates models from a result
   * @param result Array of any
   * @returns Bookigs
   */
  static createArticles(result: Array<any>): Article[] {
    const articles = new Array<Article>();
    if (result) {
      result.map((article) => {
        articles.push(new Article(article));
      });
    }
    return articles;
  }
}

export class ArticleInput {
  id: string;
  externalId: string;
  number: string;
  name: string;

  constructor(payload?: any) {
    if (payload) {
      this.id = payload.id;
      this.externalId = payload.externalId;
      this.name = payload.name;
      this.number = payload.number;
    }
  }
}
