import { Injectable } from '@angular/core';

@Injectable()
export class FormService {

  constructor(
  ) {
  }

  getSetting(controlTemplate: any, settingName: string): any {
    if(controlTemplate != null
      && controlTemplate.settings != null
      && controlTemplate.settings[settingName]){
        return controlTemplate.settings[settingName]
      }
    return undefined;
  }

  getValue(controlTemplate: any, valueName: string): any {
   //  console.log('test');
    if(controlTemplate != null
      && controlTemplate.settings != null
      && controlTemplate.settings.value != null
      && controlTemplate.settings.value[valueName]){
        return controlTemplate.settings.value[valueName]
      }
    return undefined;
  }

}
