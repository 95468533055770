
import { Tour, TourDayEntity } from './tour';

// a driver is just a user with the role "DRIVER - should we check this
// during initalization?"
export class Driver implements TourDayEntity{

  name: string | undefined;
  tours: Tour[] | undefined;

  constructor(payload?: any) {
    if (payload) {
      this.name = payload.name;
      if (payload.tours) {
        this.tours = Tour.createTours(payload.tours);
      }
    }
  }

  /**
 * creates Drivers from a result
 * @param result array of any
 * @returns Array of Driver
 */
  static createDrivers(result: Array<any>): Driver[] {
    const drivers = new Array<Driver>();
    if (result) {
      result.map((driver) => {
        drivers.push(new Driver(driver));
      });
    }
    return drivers;
  }

  getName(): string {
   return this.name;
  }
  getTours(): Tour[] {
    return this.tours;
  }
}
