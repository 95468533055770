import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogEventType, ConfirmDialogService, ConfirmDialogSeverity, Container, EditDialogService, LoadingService, RolesService, SettingsService, ToastService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AddressDialogComponent } from 'src/app/shared-module/components/address-dialog/address-dialog.component';
import { AddressInput } from 'src/app/shared-module/shared/entities/address/address';
import { environment } from 'src/environments/environment';
import { LocationEntity, LocationInput, LocationType } from '../../../shared-module/shared/entities/location/location';
import { LocationService } from '../../shared/services/location/location.service';
import { LocationDialogComponent } from '../location-dialog/location-dialog.component';
import * as fromGraphQl from './graphql';

interface Setting {
  key: string;
  value: any;
}

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent
  extends Container<fromGraphQl.LocationQueryResponse>
  implements OnInit, OnDestroy {

  dialogRef: DynamicDialogRef | undefined;

  public location: LocationEntity;
  public id: string;
  public routeSubscription: Subscription;
  public types = [
    { id: 'COMPANY', name: 'Firma' },
    { id: 'DEPOT', name: 'Lager' },
    { id: 'TOURBASE', name: 'Tourbasis' }
  ];
  public settings: any;
  public settingsForm : FormGroup;
  public settingsToUpdate: Setting[];
  public updatedSettingsCounter: number;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    loadingService: LoadingService,
    apollo: Apollo,
    public dialogService: EditDialogService,
    public settingsService: SettingsService,
    private confirmDialogService: ConfirmDialogService,
    public locationService: LocationService,
    private toastService: ToastService,
    public rolesService: RolesService
  ) {
    super(apollo, loadingService, fromGraphQl.API_KEY_QUERY, environment);
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.params
      .pipe(
        filter((params) => params['id'] !== undefined),
        take(1)
      )
      .subscribe((params) => {
        this.id = params['id'];
        this.subscribeToQuery({
          id: this.id,
        });
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    super.ngOnDestroy();
  }

  handleSubscriptionResult(data: fromGraphQl.LocationQueryResponse): void {
    this.loadingService.setLoading(false);
    if (data) {
      this.queryRef.stopPolling();
      if (data.location) {
        this.location = new LocationEntity(data.location);
        this.settings = data.settings;
        this.createSettingsFormGroup();
      }
    }
  }

  createSettingsFormGroup(): void {
    console.log("form");
    this.settingsForm = this.settingsService.createSettingsFormGroup(this.settings);
    this.settingsForm.disable();
  }

  getTypeString(locationTypes: LocationType[]): string {
    return locationTypes?.map((type) => {
      return this.types?.find((t) => t.id === type?.toString())?.name;
    }).join(', ');
  }

  editLocation(): void {
    this.dialogRef = this.dialogService.open(LocationDialogComponent, {
      header: 'Niederlassung bearbeiten',
      data: this.location
    });
    this.dialogRef.onClose.subscribe((result) => {
      // result is true or false, indiciating if it should refetch
      if(result){
        this.queryRef.refetch();
      }
    });
  }

  editLocationAddress(): void {
    console.log('edit address');
    this.dialogRef = this.dialogService.open(AddressDialogComponent, {
      width: "1070px",
      header: 'Adresse bearbeiten',
      data: {
        address: this.location?.address,
        showImportedAddress: false,
        allowCoordinatesOnly: false,
        showNameInput: false,
        showNoteInput: false,
      }
    });
    this.dialogRef.onClose.subscribe((result: any) => {
      if (result){
        let locationInput = new LocationInput(this.location);
        if (result.addressInput){
          locationInput.address = new AddressInput(result.addressInput);
          this.locationService.updateLocation(locationInput, (result: any) => {
            this.queryRef.refetch();
          });
        }
      }
    });
  }

  editSettings(): void {
    console.log('edit settings');
    this.settingsForm.enable();
  }

  updateSettings(): void {
    this.loadingService.setLoading(true);
    this.settingsForm.disable();
    this.settingsToUpdate = [];
    Object.keys(this.settingsForm.controls).forEach((name) => {
      const currentControl = this.settingsForm.controls[name];
      if (currentControl.dirty) {
        this.settingsToUpdate.push({
          key: name,
          value: currentControl?.value
        });
      }
    });
    this.updatedSettingsCounter = this.settingsToUpdate.length;
    this.settingsToUpdate.forEach((setting) => {
      let settingInput:any = {};
      settingInput = {
        "key": setting.key,
        "value": setting.value
      };
      this.settingsService.updateSettings(
        settingInput,
        this.returnCallback()
      );
    });
  }

  returnCallback(): void {
    this.updatedSettingsCounter--;
    console.log(this.updatedSettingsCounter);
    if(this.updatedSettingsCounter === 0){
      setTimeout(() => {
        this.loadingService.setLoading(false);
        this.queryRef.refetch();
      }, 300);
    }
  }

  abortEditSettings(): void {
    this.settingsForm.disable();
  }

  deleteLocation() {
    const message = 'Möchtest Du die Niederlassung ' + this.location.name + ' wirklich löschen?';
    this.dialogRef = this.confirmDialogService.open({
      message: message,
      severity: ConfirmDialogSeverity.WARN,
      header: 'Niederlassung löschen'
    });
    this.dialogRef.onClose.subscribe( (result : ConfirmDialogEventType) => {
      if(result === ConfirmDialogEventType.CONFIRM){
        this.loadingService.setLoading(true);
        this.locationService.deleteLocation(this.location.id, () => {
          this.router.navigate(['./settings/locations']);
          this.loadingService.setLoading(false);
          this.toastService.addToastWithMessage('Niederlassung gelöscht.');
        });
      };
    })
      // do nothing
  };
}
