import { Component, Input } from '@angular/core';
import { ResourceAssignment } from 'src/app/rent-module/shared/entities/resourceAssignment/resourceAssignment';
import { Order } from '../../shared/entities/order/order';

@Component({
  selector: 'app-order-rent-protocols-view, [app-order-rent-protocols-view]',
  templateUrl: './order-rent-protocols-view.component.html',
  styleUrls: ['./order-rent-protocols-view.component.scss']
})
export class OrderRentProtocolsViewComponent {
  
  public resourceAssignments: ResourceAssignment[] = [];
  
  _order: Order;

  @Input() set order(order: Order) {
    if (order) {
      this._order = order;
      this._order.lineItems?.forEach(lineItem => {
        lineItem?.resourceAssignments?.forEach(resourceAssignment => {
          this.resourceAssignments.push(resourceAssignment);
        });
      })
    }

  }

}
