import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from '../../shared/entities/notification/notification';
import { NotificationService } from '../../shared/services/notification.service';


@Component({
  selector: "app-notification-center",
  templateUrl: "notification-center.html",
  styleUrls: ["./notification-center.scss"]
})
export class NotificationCenterComponent {

  public allNotifications$ : Observable<Notification[]>;
  public unreadNotifications$ : Observable<Notification[]>;

  public activeSection: 'unread' | 'all' = 'unread';


  constructor(
    private notificationService: NotificationService
  ) {
    this.allNotifications$ =  notificationService.allNotifications$;
    this.unreadNotifications$ = notificationService.unreadNotifications$;
  }

  markAllAsRead(notifications: Notification[]) {
    this.notificationService.markNotificationsAsRead(notifications.map(notification => notification.id)); 
  }

}
