import { Component, Input } from '@angular/core';
import { Notification } from '../../shared/entities/notification/notification';
import { NotificationService } from '../../shared/services/notification.service';


@Component({
  selector: "app-notification",
  templateUrl: "notification.html",
  styleUrls: ["./notification.scss"]
})
export class NotificationComponent {

  @Input() notification: Notification;

  constructor(
    private notificationService: NotificationService
  ) {
  }

  onDetailsClicked(notification: Notification) {
    this.notificationService.markNotificationAsRead(notification.id);
    if(notification.type === 'link') {
      window.open(notification.settings.url, '_blank');
    }
  }

}
