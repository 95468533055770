import { Component } from '@angular/core';
import { RolesService } from '@nexato/nx-core-module';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-settings-overview',
  templateUrl: './settings-overview.component.html',
  styleUrls: ['./settings-overview.component.scss'],
})
export class SettingsOverviewComponent
{
  tenantName: string = '';
  constructor(
    protected keycloakAngular: KeycloakService,
    public rolesService: RolesService,
  ) {

  }

  ngOnInit(){
    let key = 'tenant_id';
    this.tenantName = this.keycloakAngular.getKeycloakInstance()
      ? (this.keycloakAngular.getKeycloakInstance()?.idTokenParsed as any)[key]
      : '';

    console.log('Tenant name', this.tenantName);
  }

  public settingsOverviewBoxes = [
    {
      icon: 'icon-settings',
      label: 'Allgemein',
      description: 'nexcore Grundeinstellungen.',
      path: 'settings/general',
      role: 'framework_settings_manage'
    },
    {
      icon: 'icon-company',
      label: 'Unternehmen',
      description: 'Allgemeine und steuerlich relevante Daten zum Unternehmen.',
      path: 'company',
      role: 'framework_tenants_manage_own'
    },
    {
      icon: 'icon-branches',
      label: 'Niederlassungen',
      description: 'Erstellen und verwalten von Niederlassungen.',
      path: 'settings/locations',
      role: 'nexcore_location_view'
    },
    {
      icon: 'icon-person',
      label: 'Nutzer',
      description: 'Teammitglieder und Zugriffsrechte.',
      path: 'settings/users',
      role: 'framework_users_manage'
    },
    {
      icon: 'icon-mobile-device',
      label: 'Geräte',
      description: 'Übersicht aktiver mobiler Geräte und Statusinformationen..',
      path: 'settings/mobileDevices',
      role: 'framework_mobileDevice_view'
    },
    {
      icon: 'icon-group',
      label: 'Gruppen',
      description: 'Gruppen, Rollen und Zugriffsrechte.',
      path: 'settings/groups',
      role: 'framework_groups_manage'
    },
    {
      icon: 'icon-invoice',
      label: 'Lizenzen & Rechnungsstellung',
      description: 'Lizenzen, Rechnungsempfang, Zahlungsweise und Rechnungen.',
      path: 'settings/billing',
      role: 'framework_licence_view'
    },
    {
      icon: 'icon-documents',
      label: 'Formulare',
      description: 'Individuelle Formulare für Aufgaben.',
      path: 'settings/forms',
      role: 'framework_forms_manage'
    },
    {
      icon: 'icon-templates',
      label: 'Vorlagen',
      description: 'Dokumenten und E-Mail Vorlagen.',
      path: 'settings/templates',
      role: 'framework_templates_manage'
    },
    {
      icon: 'icon-api',
      label: 'Developer',
      description: 'REST-API bzw. Schnittstellen-Einstellungen, Events und System-Logs.',
      path: 'settings/developer',
      role: 'framework_developer_view'
    },
    {
      icon: 'icon-tenant',
      label: 'Tenants',
      description: 'Verwaltung von Tenants (nexato intern).',
      path: 'settings/tenants',
      role: 'framework_tenants_manage'
    },

  ];

  public getSettingsOverviewBoxes() {
    return this.settingsOverviewBoxes.filter(item => this.rolesService.hasRole(item.role));
  }


}
