import { ContentChildren, Directive, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import {
  attachClosestEdge
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Panel } from 'primeng/panel';
import { NxDragItem } from './nx-drag-item.directive';

@Directive({
  selector: '[nxDropList]'
})
export class NxDropList<T = any> implements OnInit, OnDestroy {

  @Input() nxDropListData: T;
  @Input() nxIsDropDisabled: boolean = false;
  @Input() nxPanel: Panel;
  @Output() nxDropped = new EventEmitter<any>();
  @Output() nxDropListEntered = new EventEmitter<any>();
  @Output() nxDropListLeft = new EventEmitter<any>();

  private cleanup: (() => void) | null = null;

  @ContentChildren(forwardRef(() => NxDragItem)) nxDragItems: QueryList<NxDragItem<T>>;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    let self = this;
    const el = this.elementRef.nativeElement;
    this.cleanup = dropTargetForElements({
      element: this.elementRef.nativeElement,
      canDrop: () => !this.nxIsDropDisabled,
      getData: ({ input }) => {
        let data = {
          data: this.nxDropListData,
          sourceContainer: self
        }
        return attachClosestEdge(data, {
          element : this.elementRef.nativeElement,
          input,
          allowedEdges: ['top', 'bottom'],
        });
      },
      onDragStart: args => {
      },
      onDrag: args => {},
      onDragEnter: args => {
        if(this.nxPanel && this.nxPanel.collapsed){
          this.nxPanel.animating = true;
          this.nxPanel.expand();
        }
        // console.log('onDragEnter', args);
        // console.log(this.nxDragItems);
        this.nxDropListEntered.emit(args);
        this.elementRef.nativeElement.classList.add('dropActive');
      },
      onDragLeave: args => {
        // console.log('onDragLeave', args);
        this.nxDropListLeft.emit(args);
        this.elementRef.nativeElement.classList.remove('dropActive');
      },
      onDrop: args => {
        this.elementRef.nativeElement.classList.remove('dropActive');
        // only fire if the drop target is the first in the bubbled up list
        // if not, a it was dropped on a child element
        if(args.location.current.dropTargets[0]?.element === this.elementRef.nativeElement){
          let previousIndex = args.source?.data?.index !== undefined ? args.source?.data?.index as number : undefined;
          let previousContainer = args.source?.data?.container ? args.source?.data?.container as NxDropList : undefined;
          let currentIndex = undefined; // just dropped IN the list, not an a specific index
          let container = this;
          let item = args.source?.data?.data;
          
          let event = {
            previousIndex: previousIndex,
            previousContainer: previousContainer,
            newIndex: currentIndex,
            newContainer: container,
            item: item,
          }
          // console.log(event);
          this.nxDropped.emit(event);
          
          this.elementRef.nativeElement.classList.remove('drop-active');  
          return;
        } else {
          return;
        }
        
      },

    });
  }

  ngOnDestroy() {
    if (this.cleanup) {
      this.cleanup();
    }
  }
}