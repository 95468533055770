import { Apollo } from 'apollo-angular';
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';
import { NXDataSourcePrime } from '@nexato/nx-core-module';


export class OrderTaskListViewDataSource extends NXDataSourcePrime<any> {
  constructor(apollo: Apollo, ) {
    super(apollo, fromGraphQl.TASK_LIST_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.TaskListQueryResponse): void {
    super.setSubject(
      data.tasks ? Task.createTasks(data?.tasks?.content) : [],
      data.tasks ? data.tasks.totalElements : 0
    );
  }

  loadTaskList(variables: any): void {
    this.subscribeToQuery(variables);
  }
}
