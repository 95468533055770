import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ComponentState, ExpandService, StateVar } from '@nexato/nx-core-module';
import { LocationEntity } from 'src/app/shared-module/shared/entities/location/location';


interface DashboardComponentState{
  selectedLocationDashboard: string,
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit{

  @ViewChild('drawerRight') drawerRight: any;
  public date = new Date();
  initialDate = new Date();
  selectedLocation: LocationEntity;
  public componentStateCount = 0;

  componentState: ComponentState<DashboardComponent>;
  selectedLocationDashboard: StateVar<string>;
  constructor(
    private cdRef:ChangeDetectorRef,
    public expandService: ExpandService
  ) {
  }

  ngOnInit() {
    this.expandService.setExpandState(false);
  }

  selectLocation(location: LocationEntity[]){
    if (location && location.length > 0) {
      this.selectedLocation = location[0];
      this.cdRef.detectChanges();
    }
  }

  dateChanged($event: any){
    this.date = $event;
  }

}
