import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CommandMenuItem, ConfirmDialogEventType, ConfirmDialogService, ConfirmDialogSeverity, EditDialogService, LoadingService, RolesService, ToastService } from '@nexato/nx-core-module';
import { Apollo } from 'apollo-angular';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent } from 'primeng/table';
import { ContactPersonDialogComponent } from '../../components/contact-person-dialog/contact-person-dialog.component';
import { Contact } from '../../shared/entities/contact/contact';
import { ContactPerson } from '../../shared/entities/contactPerson/contactPerson';
import { ContactPersonService } from '../../shared/services/contactPerson/contactPerson.service';
import { ContactPersonDataSource } from './contactPersonDataSource';

@Component({
  selector: 'app-contact-person-list',
  templateUrl: './contact-person-list.component.html',
  styleUrls: ['./contact-person-list.component.scss'],
})
export class ContactPersonListComponent implements OnDestroy {
  public contactId: string;
  public displayedColumns: String[];
  private sortProperty = 'id';
  private sortDirection = 'asc';
  public pageNumber = 0;
  public pageSize = 15;
  public loading = false;
  public sortField = 'id';
  public sortOrder = 1;
  @Input() withMainHeadline = true;
  dialogRef: DynamicDialogRef | undefined;

  public sortOptions = [
    { label: 'Keine Sortierung', fieldName: 'id', direction: 1},
    { label: 'Telefon aufsteigend', fieldName: 'phoneNumber', direction: 1 },
    { label: 'Telefon absteigend', fieldName: 'phoneNumber', direction: -1 },
    { label: 'Mobilfunk aufsteigend', fieldName: 'mobileNumber', direction: 1 },
    { label: 'Mobilfunk absteigend', fieldName: 'mobileNumber', direction: -1},
    { label: 'E-Mail aufsteigend', fieldName: 'email', direction: 1 },
    { label: 'E-Mail absteigend', fieldName: 'email', direction: -1}
  ];


  @Input() set contact(contactId: string) {
    this.contactId = contactId;
    if (this.contactId){
      if (this.rolesService?.hasRole('nexcore_contactPerson_update') || this.rolesService?.hasRole('nexcore_contactPerson_delete')) {
        this.displayedColumns = [
          'name', 'phoneNumber', 'mobileNumber', 'email', 'button'];
      }
      else {
        this.displayedColumns = [
          'name', 'phoneNumber', 'mobileNumber', 'email'];
      }
      this.setTableDataSource();
    }
  }
  public text = '';
  public dataSource: ContactPersonDataSource;
  constructor(
    private apollo: Apollo,
    private router: Router,
    public loadingService: LoadingService,
    private contactPersonService: ContactPersonService,
    public rolesService: RolesService,
    private cdRef:ChangeDetectorRef,
    public dialogService: EditDialogService,
    private toastService: ToastService,
    private confirmDialogService: ConfirmDialogService
  ) {
  }


  setTableDataSource(): void {
    this.dataSource = new ContactPersonDataSource(this.apollo);
    this.dataSource.loading.subscribe((loading) => {
      this.loading = loading;
    });
    const text = this.text ? this.text : '';
    this.dataSource.loadContactPersonList({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      text,
      contactId: this.contactId,
      isOneTimeContact: false
    });
  }

  refetchloadContactPersonList(): void {
    const text = this.text ? this.text : '';
    this.dataSource?.refetchQuery({
      number: this.pageNumber,
      size: this.pageSize,
      sortProperty: this.sortProperty,
      sortDirection: this.sortDirection,
      text,
      contactId: this.contactId
    });
  }

  openContact(id: string): void {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>this.router.navigate(['./customers/customer/' + id]));
  }

  getName(linkedContact: Contact): string {
    if (linkedContact?.firstName && linkedContact?.lastName) {
      return linkedContact.lastName + ', ' + linkedContact.firstName;
    } else if (linkedContact?.firstName || linkedContact?.lastName) {
      return linkedContact?.firstName ? linkedContact?.firstName : linkedContact?.lastName;
    } else if (linkedContact.name){
      return linkedContact.name;
    } else {
      return '-'
    }
  }

  ngOnDestroy(): void {
    this.dataSource?.disconnect();
  }

  editContactPerson(contactperson: ContactPerson) {
    this.dialogRef = this.dialogService.open(ContactPersonDialogComponent, {
      header: 'Kontaktperson bearbeiten',
      data: {
        contactperson: contactperson,
        contactId: this.contactId
      },
    });
    this.dialogRef.onClose.subscribe((result) => {
      console.log('result', result);
      // result is true or false, indiciating if it should refetch
      if (result){
        this.refetchloadContactPersonList();
      }
      this.loadingService.setLoading(false);
    });


  }
  createContactPerson() {
    this.dialogRef = this.dialogService.open(ContactPersonDialogComponent, {
      header: 'Kontaktperson anlegen',
      data: {
        contactId: this.contactId
      },
    });
    this.dialogRef.onClose.subscribe((result) => {
      // result is true or false, indiciating if it should refetch
      if (result){
        this.toastService.addToastWithMessage('Kontaktperson erfolgreich angelegt.');
        this.refetchloadContactPersonList();
      }
      this.loadingService.setLoading(false);
    });

  }

  confirmDialog(contactperson: ContactPerson) {
    const message = 'Möchtest Du die Kontaktperson ' + contactperson.getFullName() + ' wirklich löschen?';
    this.dialogRef = this.confirmDialogService.open({
      message: message,
      severity: ConfirmDialogSeverity.WARN,
      header: 'Kontaktperson löschen'
    });
    this.dialogRef.onClose.subscribe( (result : ConfirmDialogEventType) => {
      if(result === ConfirmDialogEventType.CONFIRM){
        this.loadingService.setLoading(true);
        this.deleteContactPerson(contactperson.id);
      };
    })
      // do nothing
  };
  deleteContactPerson(id: string){
    this.contactPersonService.deleteContactPerson(
      id,
      this.returnCallback(this.router)
    );
  }

  returnCallback(router: Router): void {
    setTimeout(() => {
      this.loadingService.setLoading(false);
      this.toastService.addToastWithMessage('Kontaktperson gelöscht.');
      this.refetchloadContactPersonList();
    }, 500);
  }

  loadData(event: TableLazyLoadEvent) {
    this.loading = true;

    // sorting
    if (event?.sortField !== undefined && typeof event?.sortField == 'string') {
      this.sortProperty = event.sortField;
    }
    if (event?.sortOrder !== undefined) {
      this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
    }

    //filtering
    this.text = event?.globalFilter?.toString();

    // pagination
    this.pageNumber = event?.first !== undefined ? event.first / event.rows : 0;
    this.pageSize = event?.rows !== undefined ? event.rows : 6;

    this.refetchloadContactPersonList();
  }


  sort(event) {
    if(event?.value?.direction && event?.value?.fieldName){
      this.sortField = event.value.fieldName;
      this.sortOrder = event.value.direction;
    } else {
      this.sortField = 'id';
      this.sortOrder = 1;
    }
    this.cdRef.detectChanges();
  }

  getCommandMenuItems(): CommandMenuItem[] {
    let items: CommandMenuItem[] = [];

    if(this.rolesService?.hasRole('nexcore_contactPerson_update')){
      items.push({
        label: 'Kontaktperson bearbeiten',
        command: 'editContactPerson'
      });
    }

    if(this.rolesService?.hasRole('nexcore_contactPerson_delete')){
      items.push({
        label: 'Kontaktperson löschen',
        command: 'deleteContactPerson'
      });
    }

    return items;
  }

  executeCommand($event){
    if($event.command === 'editContactPerson'){
      this.editContactPerson($event.data);
    }
    if($event.command === 'deleteContactPerson'){
      this.confirmDialog($event.data);
    }
  }
}
