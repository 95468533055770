import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import * as fromGraphQl from './graphql';
import { NXDataSourcePrime } from '@nexato/nx-core-module';

export class ArticleDataSource extends NXDataSourcePrime<any> {
  constructor(apollo: Apollo) {
    super(apollo, fromGraphQl.ARTICLES_QUERY, environment);
  }
  handleSubscriptionResult(data: fromGraphQl.ArticlesQueryResponse): void {
    super.setSubject(
      data.articles?.content ? data.articles?.content : [],
      data.articles?.totalElements ? data.articles.totalElements : 0
    );

  }

  loadarticles(variables: any): void {
    this.querySubscription?.unsubscribe();
    this.subscribeToQuery(variables);
  }
}
