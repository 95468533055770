import gql from 'graphql-tag';

export const SETTINGS_QUERY = gql`
query getSettings
{
  terms: getSettingCategories{
    key
    label
    description
    settingDescriptions {
      key
      label
      description
      type
      defaultValue
      currentValue
      settings
    }
  },
}
`;

export interface SettingsQueryResponse {
  terms: any;
}

