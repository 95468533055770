import { gql } from 'apollo-angular';
import { Contact } from '../../shared/entities/contact/contact';

export const CUSTOMER_QUERY = gql`
query getCustomerQuery(
  $customerId: UUID, $entity: String
)
{
  customer: getContact(
    id: $customerId
  )
  {
    id
    companyName
    type
    companyNameExtension
    firstName
    lastName
    number
    email
    address {
      street
      postalCode
      city
      houseNumber
      country
    }
    customAttributes {
      id
      name
      value
      customAttributeDefinition {
        id
      }
      customAttributeSection {
        id
      }
    }
  }

customAttributeSections: getCustomAttributeSections(filter: {
  entity: $entity
}
  ) {
    id
    label
    index
    customAttributeDefinitions {
      id
      type
      label
      index
      key
    }
  }
}
`;

export interface CustomerQueryResponse {
  customer: Contact;
  loading: boolean;
  customAttributeSections: any;
}
