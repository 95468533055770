import { Injectable } from '@angular/core';
import { ApolloService } from '@nexato/nx-core-module';
import { map, Observable } from 'rxjs';
import { ResourceInput } from '../../entities/resource/resource';
import * as fromGraphQl from './graphql';

/**
 * sites service.
 */
@Injectable()
export class ResourceService {
  constructor(private apolloService: ApolloService) {}

  /**
   * updates a resource
   * @param resourceInput resource
   * @param callback method
   */
  public updateResource(resourceInput: ResourceInput, callback: any): void {
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_RESOURCE_MUTATION,
      {
        resourceInput,
      },
      callback
    );
  }

  /**
   * creates a resource
   * @param resourceInput resource
   * @param callback method
   */
  // public createResource(resourceInput: ResourceInput, callback: any): void {
  //   // upload resource
  //   // console.log("upload resource",  resourceInput);
  //   try {
  //     this.apolloService.mutateAndExecute(
  //       fromGraphQl.CREATE_RESOURCE_MUTATION,
  //       {
  //         resourceInput,
  //       },
  //       callback
  //     );
  //   }
  //   catch (error) {
  //     console.log('Calztch in serrvice', error);
  //     callback(error);
  //   }

   public async  createResource(resourceInput: ResourceInput, callback: any){
      try {
        const response = await this.apolloService.mutateAndExecute(
          fromGraphQl.CREATE_RESOURCE_MUTATION,
          { resourceInput },
          callback
        );
        // Handle success
        callback(response);
      } catch (error) {
        // This should catch the ApolloError
        callback(error);
      }
    }

    /**
   * checks if an email address is already taken
   * @param email email address
   */
    public checkNumberNotTaken(number: string): Observable<boolean> {
      return this.apolloService.apollo
        .query<fromGraphQl.NumberNotTakenQueryResponse>({
          query: fromGraphQl.NUMBER_NOT_TAKEN_QUERY,
          variables: {
            resourceNumber: number,
          },
        })
        .pipe(map((result : any) => {
          return result.data.resourceExists;
        }));
    }

}
