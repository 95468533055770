import { Injectable } from "@angular/core";
import { QueryRef } from 'apollo-angular';

import { ApolloService } from '@nexato/nx-core-module';
import { Tour, TourInput } from '../../entities/tour';
import * as fromGraphQl from './graphql';

@Injectable()
export class TourService {

  constructor(
    private apolloService: ApolloService
  ) {
  }

  /**
   * Creates a tour for the given date.
   * @param tourDate tourDate
   * @param refetchQuery query to refetch after the update
   */
  public createTour(tour: TourInput, callback: any) {
    this.apolloService.mutateAndExecute(
      fromGraphQl.CREATE_TOUR_MUTATION,
      {
        input: tour
      },
      callback
    );
  }

  /**
   * Deletes a tour.
   * @param tour the tour to delete
   * @param refetchQuery query to refetch after the update
   */
  public deleteTour(tour: Tour, refetchQuery: QueryRef<any>) {
    this.apolloService.mutate(
      fromGraphQl.DELETE_TOUR_MUTATION,
      {
        tourId: tour.id
      },
      refetchQuery
    );
  }

  public deleteTourAndExecute(tour: Tour, callback: any) {
    this.apolloService.mutateAndExecute(
      fromGraphQl.DELETE_TOUR_MUTATION,
      {
        tourId: tour.id
      },
      callback
    );
  }

  /**
   * Sets a vehicle on a tour.
   * @param tour the tour on which the vehicle should be set
   * @param vehicle the vehicle that should be set
   */
  // public setVehicle(tour: Tour, vehicle: Vehicle, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.ASSIGN_VEHICLE_TO_TOUR_MUTATION,
  //     {
  //       tourId: tour.id,
  //       vehicleId: vehicle.id,
  //     },
  //     refetchQuery
  //   );
  // }

  /**
   * Removes a vehicle from a tour.
   * @param tour the tour
   */
  // public removeVehicle(tour: Tour, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.REMOVE_VEHICLE_FROM_TOUR_MUTATION,
  //     {
  //       tourId: tour.id
  //     },
  //     refetchQuery
  //   );
  // }


    /**
   * Adds a task to a tour.
   * @param tour the tour
   * @param task the task to add
   * @param refetchQuery query to refetch after the update
   */
  public addTaskToTourAtPosition(tourId: any, taskId: any, position: number, callback: (arg: any) => void) {
    this.apolloService.mutateAndExecute(
      fromGraphQl.ADD_TASK_TO_TOUR_AT_POSITION_MUTATION,
      {
        tourId: tourId,
        taskId: taskId,
        position: position
      },
      callback
    );
  }

  /**
   * Removes a task from a tour.
   * @param tour the tour
   * @param task the task to remove
   * @param refetchQuery query to refetch after the update
   */
  public removeTaskFromTour(tourId: any, taskId: any, refetchQuery: QueryRef<any>) {
    this.apolloService.mutate(
      fromGraphQl.REMOVE_TASK_FROM_TOUR_MUTATION,
      {
        tourId: tourId,
        taskId: taskId
      },
      refetchQuery
    );
  }

  public removeTaskFromTourAndExecute(tourId: any, taskId: any, callback) {
    this.apolloService.mutateAndExecute(
      fromGraphQl.REMOVE_TASK_FROM_TOUR_MUTATION,
      {
        tourId: tourId,
        taskId: taskId
      },
      callback
    );
  }

  /**
   * Deletes a task.
   * @param task Task to delete
   */
  // public deleteTask(task: Task, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.DELETE_TASK_MUTATION,
  //     {
  //       taskId: task.id
  //     },
  //     refetchQuery
  //   );
  // }

  /**
   * Assigns a driver to a tour.
   * @param tour the tour
   * @param driver the driver to assign
   */
  // public assignDriverToTour(tour: Tour, driver: Driver, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.ASSIGN_DRIVER_TO_TOUR_MUTATION,
  //     {
  //       tourId: tour.id,
  //       driverId: driver.id
  //     },
  //     refetchQuery
  //   );
  // }

  // public removeDriverFromTour(tour: Tour, refetchQuery: QueryRef<any>) {
  //   this.apolloService.mutate(
  //     fromGraphQl.REMOVE_DRIVER_FROM_TOUR_MUTATION,
  //     {
  //       tourId: tour.id,
  //     },
  //     refetchQuery
  //   );
  // }

  // public setStartAddress(tour: Tour, address: Address, refetchQuery: QueryRef<any>){
  //   this.apolloService.mutate(
  //     fromGraphQl.SET_START_ADDRESS_FOR_TOUR_MUTATION,
  //     {
  //       tourId: tour.id,
  //       addressId: address.id
  //     },
  //     refetchQuery
  //   );
  // }

  // public removeStartAddress(tour: Tour, refetchQuery: QueryRef<any>){
  //   this.apolloService.mutate(
  //     fromGraphQl.REMOVE_START_ADDRESS_FROM_TOUR_MUTATION,
  //     {
  //       tourId: tour.id
  //     },
  //     refetchQuery
  //   );
  // }

  // public setEndAddress(tour: Tour, address: Address, refetchQuery: QueryRef<any>){
  //   this.apolloService.mutate(
  //     fromGraphQl.SET_END_ADDRESS_FOR_TOUR_MUTATION,
  //     {
  //       tourId: tour.id,
  //       addressId: address.id
  //     },
  //     refetchQuery
  //   );
  // }

  // public removeEndAddress(tour: Tour, refetchQuery: QueryRef<any>){
  //   this.apolloService.mutate(
  //     fromGraphQl.REMOVE_END_ADDRESS_FROM_TOUR_MUTATION,
  //     {
  //       tourId: tour.id
  //     },
  //     refetchQuery
  //   );
  // }

  // public changeOrder(tourId: any, taskId: any, newPosition: number, refetchQuery: QueryRef<any>){
  //   this.apolloService.mutate(
  //     fromGraphQl.CHANGE_ORDER_OF_TASKS_IN_TOUR_MUTATION,
  //     {
  //       tourId: tourId,
  //       taskId: taskId,
  //       newPosition: newPosition
  //     },
  //     refetchQuery
  //   );
  // }

  public updateTour(tour: Tour, refetchQuery: QueryRef<any>){
    this.apolloService.mutate(
      fromGraphQl.UPDATE_TOUR_MUTATION,
      {
        tourInput: this.convertTourToTourInput(tour)
      },
      refetchQuery
    );
  }
  public updateTourInput(tour: TourInput, refetchQuery: QueryRef<any>){
    this.apolloService.mutate(
      fromGraphQl.UPDATE_TOUR_MUTATION,
      {
        tourInput: tour
      },
      refetchQuery
    );
  }

  public updateTourInputAndExecute(tour: TourInput, callback){
    this.apolloService.mutateAndExecute(
      fromGraphQl.UPDATE_TOUR_MUTATION,
      {
        tourInput: tour
      },
      callback
    );
  }

  public convertTourToTourInput(tour: Tour): TourInput {
    let tourInput = new TourInput();
    tourInput.scheduledDepartureDateTime = tour.scheduledDepartureDateTime;
    tourInput.scheduledArrivalDateTime = tour.scheduledArrivalDateTime;
    tourInput.id = tour.id;
    tourInput.locationId = tour.location?.id;
    tourInput.note = tour.note;
    tourInput.assignee = tour.driver?.id;
    tourInput.resource = tour?.resource?.id;
    tourInput.departureAddressId = tour?.departureAddress?.id;
    tourInput.arrivalAddressId = tour?.arrivalAddress?.id;
    return tourInput;
  }
}
