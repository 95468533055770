<div class="flex flex-row basis-full tasks" [class.completed]="task?.state === 'COMPLETED'" *ngIf="task?.id">
  
  <!-- start -->
  <div class="flex flex-col basis-12">
    <div class="flex flex-row h-full">
      <div [ngStyle]="{ 'background-color': task?.getBackgroundColor() }" class="w-[5px] h-full"></div>
      <div class="pl-1 pt-1"><i [className]="task?.getTypeIcon()" [matTooltip]="task?.getType()"></i></div>
    </div>
  </div>

  <!-- middle/end -->
  <div class="flex justify-items-start flex-col basis-full px-2 py-1">
    <div class="flex justify-items-start flex-row">
      <div class="flex justify-items-start flex-col mb-[0.125rem]">
        <div class="flex flex-row row items-center leading-[2rem]">
          <span class="time noWrap">
            <span class="deliveryTime" [ngClass]="task?.state">
              {{tasksService.getDeliveryTime(task?.dueDateTimePeriod)}}
            </span>
          </span>
          <span class="dueDate pl-1">{{tasksService.getDeliveryDateFull(task?.dueDateTimePeriod)}}</span>
          <span class="noWrap" #number
            matTooltipShowDelay="250" matTooltip="{{getAddressToolTip(task?.address)}}">
          &nbsp;/&nbsp;{{getAddressString(task)}}
        </span>
          <app-address-indicator
            class="ml-1"
            size="small"
            [grade]="task?.address?.geocodingResultGrade?.toString()">
          </app-address-indicator>
        </div>
        <div class="flex flex-row row">
          <span class="text-[#848488]">
          {{task?.description? task?.description: '-'}}
          </span>
        </div>
      </div>
      <div class="orderInfo flex justify-end w-full flex-row pt-1">
        <!-- files START -->
        <ng-template #filesOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <app-order-files [order]="task.order?.id" [style]="'overlay'"/>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          (click)="openOverlay(filesOverlayContent, filesTriggerButton)"
          #filesTriggerButton
          [tooltip]="'Anhänge anzeigen'"
          [style]="'light'"
          [badgeValue]="task?.order?.fileCount"
          class="mr-2"
          [icon]="'icon-file-attachment'">
        </nx-default-button>
        <!-- files END -->
        <!-- comments START -->
        <ng-template #commentsOverlayContent>
          <div class="overlay-box">
            <div class="overlay-arrow"></div>
            <div>
              <nx-headline-box
                [label]="'Auftrag'"></nx-headline-box>
              <nx-default-box
                class="mb-2"
                [label]="'Hinweis'">{{task?.order?.note}}</nx-default-box>
              <app-order-comments-list [order]="task?.order?.id" [style]="'overlay'" />
            </div>
          </div>
        </ng-template>
        <!--  -->
        <nx-default-button
          #commentsTriggerButton
          (click)="openOverlay(commentsOverlayContent, commentsTriggerButton)"
          [tooltip]="'Hinweis/Kommentare anzeigen'"
          [style]="'light'"
          class="mr-2"
          [badgeValue]="task.calculateCommentsAndNoteBadgeCount()"
          [icon]="'icon-annotation'">
        </nx-default-button>
        <!-- comments END -->
        <nx-default-button
          *ngIf="showCommandMenu"
          [style]="'light'"
          [data]="task"
          [commandMenuItems]="commandMenuItems"
          (onCommand)="executeCommand($event)"
          [icon]="'icon-options'">
        </nx-default-button>
      </div>
    </div>
    <div class="flex flex-row row">
      <div class="flex flex-row">
        <div #dueDate
          [matTooltipDisabled]="isEllipsisActive(dueDate)"
          matTooltipPosition="right"
          matTooltipShowDelay="250"
          matTooltip="{{getContactName(task?.order?.customer)}}">
          {{getContactName(task?.order?.customer)? getContactName(task?.order?.customer) : ''}}
        </div>
        <span class="order flex items-center">&nbsp;/&nbsp;{{task?.order?.number}}</span>
        <i [className]="'icon icon-jump-to'" [newTabNavigation]="['./orders/order', task?.order?.id]"></i>
      </div>
      <div class="flex justify-end flex-grow">
        <div class="pr-2">
          <p-tag *ngIf="task?.state === 'COMPLETED'" [value]="'abgeschlossen'" severity="success"></p-tag>
        </div>
      </div>
    </div>
    
    <!-- end -->
  </div>




</div>
