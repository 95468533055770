import { Component, Input } from '@angular/core';
import { Order } from '../../shared/entities/order/order';

@Component({
  selector: 'app-order-service-details',
  templateUrl: './order-service-details.component.html',
  styleUrls: ['./order-service-details.component.scss']
})
export class OrderServiceDetailsComponent {
  @Input() order: Order;
  public Order = Order;
  @Input() customSections: any;


  CheckSectionInAttributes(id: string) {
    if (this.order?.customAttributes) {
      for (let attribute of this.order?.customAttributes) {
        if (attribute?.customAttributeSection.id === id) {
          return true;
        }
      }
    }
    return false;
  }
  CheckDefintionInAttributes(id: string) {
    if (this.order?.customAttributes) {
      for (let attribute of this.order?.customAttributes) {
        if (attribute?.customAttributeDefinition?.id === id) {
          return true;
        }
      }
    }
    return false;
  }
  getValue(id: string) {
    for (let attribute of this.order?.customAttributes){
      if (attribute?.customAttributeDefinition?.id === id){
        return attribute?.value;
      }
    }
    return '-';
  }

}
