import { Injectable } from "@angular/core";

import { moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { ConfirmDialogEventType, ConfirmDialogService, ConfirmDialogSeverity, RolesService, ToastService } from "@nexato/nx-core-module";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Task } from 'src/app/rent-module/shared/entities/task/task';
import { ToursPlannerTasksListComponent } from "src/app/tour-planning/components/tours-planner-tasks-list/tours-planner-tasks-list.component";
import { ToursPlannerToursListComponent } from "src/app/tour-planning/components/tours-planner-tours-list/tours-planner-tours-list.component";
import { ToursPlannerToursMultiComponent } from "src/app/tour-planning/components/tours-planner-tours-multi/tours-planner-tours-multi.component";
import { ToursPlannerToursComponent } from "src/app/tour-planning/components/tours-planner-tours/tours-planner-tours.component";
import { NxDragDrop } from "../../dragDrop/evetns";
import { Tour } from "../../entities/tour";
import { TourService } from "./tour.service";


export interface DragDropabble {
  startPolling(): void;
  stopPolling(): void;
  refetch(): void;
}

@Injectable()
/**
 * Service to coordinate and communicate between the tour planner components.
 */
export class TourPlannerService {

  dialogRef: DynamicDialogRef | undefined;

  // paricipating components
  // task list
  private toursPlannerTasksListComponent: ToursPlannerTasksListComponent;
  // tours
  private toursPlannerToursListComponent: ToursPlannerToursListComponent
  // tour list
  private toursPlannerToursComponent: ToursPlannerToursComponent;
  private toursPlannerToursMultiComponent: ToursPlannerToursMultiComponent;
  
  private isTourComponentExpanded = new BehaviorSubject<boolean>(false);
  public isTourComponentExpanded$ = this.isTourComponentExpanded.asObservable();

  private isDragging = new BehaviorSubject<boolean>(false);
  public isDragging$ = this.isDragging.asObservable();

  private currentDragSource = new BehaviorSubject<'TOURS' | 'TASKS' | undefined>(null);
  public currentDragSource$ = this.currentDragSource.asObservable();

  constructor(
    private tourService: TourService,
    private roleService: RolesService,
    private confirmDialogService: ConfirmDialogService,
    private toastService: ToastService
  ) {
  }

  setToursPlannerTasksListComponent(toursPlannerTasksListComponent: ToursPlannerTasksListComponent){
    this.toursPlannerTasksListComponent = toursPlannerTasksListComponent;
  }

  setToursPlannerToursListComponent(toursPlannerToursListComponent: ToursPlannerToursListComponent){
    this.toursPlannerToursListComponent = toursPlannerToursListComponent;
  }

  setToursPlannerToursComponent(toursPlannerToursComponent: ToursPlannerToursComponent){
    this.toursPlannerToursComponent = toursPlannerToursComponent;
  }

  setToursPlannerToursMultiComponent(toursPlannerToursMultiComponent: ToursPlannerToursMultiComponent){
    this.toursPlannerToursMultiComponent = toursPlannerToursMultiComponent;
  }

  expandTourComponent(){
    this.isTourComponentExpanded.next(true);
  }

  reduceTourComponent(){
    this.isTourComponentExpanded.next(false);
  }

  dragStarted(event: any){
    this.isDragging.next(true);
    if(event.source.data?.['container']?.['nxDropListData']){
      this.currentDragSource.next('TOURS');
    }
    this.toursPlannerTasksListComponent?.stopPolling();
    this.toursPlannerToursComponent?.stopPolling();
    this.toursPlannerToursMultiComponent?.stopPolling();
  }

  dragReleased(event: any){
    //console.log("Stop dragging task", event)
    this.isDragging.next(false);
    this.currentDragSource.next(undefined);
    this.toursPlannerTasksListComponent?.startPolling();
    this.toursPlannerToursComponent?.startPolling();
    this.toursPlannerToursMultiComponent?.startPolling();
  }

  dragDropped(event: NxDragDrop<Task, Tour>) {
    // console.log("Dropped task", event)  
    let tour = event?.newContainer?.nxDropListData;
    let tourIndex = event?.newIndex;
    let previousTour = event?.previousContainer?.nxDropListData;
    let previousTourIndex = event?.previousIndex;
    let task = event?.item?.nxDragItemData;
    // log all the data
    // console.log("Dropped task", task, "from tour", previousTour, "to tour", tour, "at index", tourIndex, "from index", previousTourIndex);

    // if we have a tour and a previous tour, we have to move the task from the previous tour to the new tour
    if(task && tour){
      if(previousTour){
        if(tour.id === previousTour.id){ 
          if(previousTourIndex === tourIndex){
            // same position in same tour, nothing to do
            return;
          }
          moveItemInArray(tour.tasks, previousTourIndex, tourIndex);
        } else {
          let actualTourIndex = tourIndex !== undefined ? tourIndex : tour.tasks.length;
          transferArrayItem(previousTour.tasks, tour.tasks, previousTourIndex, actualTourIndex);  
        }
      } else {
        // add task to tour.tasks at position tourIndex
        tour.tasks.splice(tourIndex, 0, task);
      }
      this.tourService
        .addTaskToTourAtPosition(tour.id, task.id, tourIndex, () => {
          // console.log("Task added to tour", tour.id, "at position", tourIndex);
          this.toursPlannerToursComponent?.refetch();
          this.toursPlannerToursMultiComponent?.refetch();
          if(!previousTour){
            this.toursPlannerTasksListComponent?.refetch();
          }
      });
    }
    if(task && !tour && previousTour){
      // remove task from previous tour
      previousTour.tasks = previousTour.tasks.filter(t => t.id !== task.id);
      this.tourService
        .removeTaskFromTourAndExecute(previousTour.id, task.id, () => {
          console.log("Task removed from tour", previousTour.id);
          this.toursPlannerTasksListComponent?.refetch();
          this.toursPlannerToursComponent?.refetch();
          this.toursPlannerToursMultiComponent?.refetch();
      });
    }
  }

  // this method needs to be merged with the method above
  dragDroppedWithCallback(event: NxDragDrop<Task, Tour>, callback) {
    // console.log("Dropped task", event)  
    let tour = event?.newContainer?.nxDropListData;
    let tourIndex = event?.newIndex;
    let previousTour = event?.previousContainer?.nxDropListData;
    let previousTourIndex = event?.previousIndex;
    let task = event?.item?.nxDragItemData;
    // log all the data
    // console.log("Dropped task", task, "from tour", previousTour, "to tour", tour, "at index", tourIndex, "from index", previousTourIndex);

    // if we have a tour and a previous tour, we have to move the task from the previous tour to the new tour
    if(task && tour){
      if(previousTour){
        if(tour.id === previousTour.id){ 
          if(previousTourIndex === tourIndex){
            // same position in same tour, nothing to do
            return;
          }
          moveItemInArray(tour.tasks, previousTourIndex, tourIndex);
        } else {
          transferArrayItem(previousTour.tasks, tour.tasks, previousTourIndex, tourIndex);  
        }
      } else {
        // add task to tour.tasks at position tourIndex
        tour.tasks.splice(tourIndex, 0, task);
      }
      this.tourService
        .addTaskToTourAtPosition(tour.id, task.id, tourIndex, () => {
          // console.log("Task added to tour", tour.id, "at position", tourIndex);
          callback();
      });
    }
    if(task && !tour && previousTour){
      // remove task from previous tour
      previousTour.tasks = previousTour.tasks.filter(t => t.id !== task.id);
      this.tourService
        .removeTaskFromTourAndExecute(previousTour.id, task.id, () => {
          console.log("Task removed from tour", previousTour.id);
          callback();
      });
    }
  }

  public isDragDisabled(task: Task): boolean {
    // dragging is disabled if the user hast no rights to update the tour
    if (!this.roleService?.hasRole('nexcore_tour_update')) {
      return true;
    }
    // COMPLETED tasks are never draggable
    if(task.state === 'COMPLETED'){
      return true;
    }
    // if a task is not assignable, it is not draggable
    if(task.isAssignable !== undefined && !task.isAssignable){
      return true;
    }
    // fallback false
    return false;
  }

  public isDropDisabled(tour: Tour): boolean {
    // dragging is disabled if the user hast no rights to update the tour
    if (!this.roleService?.hasRole('nexcore_tour_update')) {
      return true;
    }
    // if a tour is not assignable, it is not droppable
    if(tour?.state === "FINISHED" || tour?.state === "ARCHIVED"){
      return true;
    }
    // fallback false
    return false;
  }

  public deleteTour(tour: Tour){
    const message = 'Möchtest Du die Tour ' + tour.name + ' wirklich löschen?';
    this.dialogRef = this.confirmDialogService.open({
      message: message,
      severity: ConfirmDialogSeverity.WARN,
      header: 'Tour löschen'
    });
    this.dialogRef.onClose.subscribe( (result : ConfirmDialogEventType) => {
      if(result === ConfirmDialogEventType.CONFIRM){
        this.tourService.deleteTourAndExecute(tour, () => {
          this.toastService.addToastWithMessage('Tour gelöscht.');
          this.toursPlannerTasksListComponent?.refetch();
          this.toursPlannerToursComponent?.refetch();
          this.toursPlannerToursMultiComponent?.refetch();
        });
      };
    })
  }
}
