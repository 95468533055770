import { Component, Input } from '@angular/core';
import { ControlTemplate } from 'src/app/rent-module/components/form/entities/controlTemplate';


@Component({
  selector: 'app-picturesInput-control-label-view, [app-picturesInput-control-label-view]',
  templateUrl: './picturesInput-control-label-view.component.html',
  styleUrls: ['./picturesInput-control-label-view.component.scss']
})
export class PicturesInputControlLabelViewComponent {

  @Input() controlTemplate: ControlTemplate;

  constructor(
  ) {
  }

}
