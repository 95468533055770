<div>
  <nx-table-header
  [label]="getHeadline(displayType)">
  </nx-table-header>
  <div
    *ngFor="let task of dataSource?.data; last as isLast; first as isFirst; let i = index"
    task-row-wide
    class="child flex flex-row task items-center basis-full relative border-b bg-white border-gray cursor-pointer"
    [ngClass]="{'border-b' : !isLast}"
    [task]=task
    [columns]="defaultColumns"
    [showIconMenu]="false">
  </div>

</div>
<nx-empty-box
  *ngIf="!dataSource?.data || dataSource?.data?.length === 0"
  [label]="'Keine Aufgaben gefunden'">
</nx-empty-box>
<p-paginator 
  (onPageChange)="onPageChange($event)" 
  [first]="pageNumber * pageSize"
  [rows]="pageSize"
  [totalRecords]="dataSource?.totalElements"
  [showCurrentPageReport]="true" 
  currentPageReportTemplate="{first} - {last} von {totalRecords}"
  [rowsPerPageOptions]="[5, 10, 15]">
</p-paginator>
  

